import React, { useState } from 'react';

// Icons
import Icons from '../../components/icons';
import walletIcon from "../../img/icons/paymentmethod/wallet.png";
import mt5 from "../../img/icons/paymentmethod/mt5.webp";
import { ClassNames } from '@emotion/react';

export default function AccountDropItem({ label, img, heading, options = [], onChange, selected = null, className = null,pageAccessName='' }) {
    
    const [isCommonDropActive, setIsCommonDropActive] = useState(false);
//    console.log(options);
   
    const handleCommonLeave = () => {
        setIsCommonDropActive(false);
    };

    const handleCommonToggle = () => {
        setIsCommonDropActive(!isCommonDropActive);
    }

    // const handleCommonClose = () => {
    //     onChange(accountId)
    //     setIsCommonDropActive(false);
    // };
    const handleAccountClick = (accountId) => {
        onChange(accountId)
        setIsCommonDropActive(false);
    };
    

    return (
        <>

            {( label && <div className="sgr-outer-label">{label}</div> )}
            <div className={`common-drop-item ${className} ${isCommonDropActive ? 'active' : ''}`} onMouseLeave={handleCommonLeave}>
                <div className="selected-item si-48h" onClick={handleCommonToggle}>
                    <img className="si-img" src={selected && selected.account === 'mt5'? mt5 : walletIcon }  alt="" />
                    <div className="si-data-bx">
                        <div className="si-d-heading">{heading}</div>
                    </div>
                    <div className="si-down-icon"><Icons.DownSvg /></div>
                </div>
                <div className="common-drop-bx">
                    <div className="common-drop-inner">
                        {options && options.length > 0 && options.map((option, index) => {
                                let program = option.program ? option.program.name : 'auto';

                            return (
                            
                            <div key={index} className={`selecte-option-item ${selected && selected.id == option.id ? 'selected' : ''}`}  onClick={()=>handleAccountClick(option.id)}>
                                <img className="soi-img" src={option.account === 'mt5'? mt5 : walletIcon} alt={option.heading} />
                                <div className="soi-data-bx">
                                    {(pageAccessName != '') && 
                                        <div className="soi-d-heading">{option.account === 'mt5'? option.account_number+" ( "+option.balance+" USD )" : 'IB Wallet'+"("+program+")"+" ( "+option.balance+" USD )"}</div>
                                    }
                                    {(pageAccessName == '') && 
                                        <div className="soi-d-heading">{option.account === 'mt5'? option.account_number : 'IB Wallet'}</div>
                                    }
                                </div>
                            </div>
                        )})}
                    </div>
                </div>
            </div>
        </>
    );
}
