import React, { useState, useEffect, useRef, useCallback, memo } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { useAuth, getLoggedInUserData, getToken } from '../../context/AuthContext';
import useApi from '../../utility/apiCall';
import API_URL from '../../config/config';
import urlsAccess from '../../config/credentialAll';
import FlashMessage from '../../components/FlashMessage';
import Popover from 'react-bootstrap/Popover'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import axios from 'axios';




// Icons & Images Common File
import * as Img from '../../components/Img';
import Icons from '../../components/icons';
import PanelCounter from './PanelCounter';
import CommonSwitche from '../../components/CommonInput/CommonSwitche';
// import { Icons } from '@mui/material';
import EmptyBox from '../../components/EmptyBox';
// import Modal from '../../components/Modal';
import { Modal } from "react-bootstrap";


// Audio & Menu Context File
import { useMenu } from '../../context/MenuContext';



export default function TradingViewWidget({ account_id = null }) {
	const { apiCall } = useApi();
	const { state, dispatch } = useAuth();
	const token = state.token || '';
	const [user, setUser] = useState('')
	const [userLoggedIn, setUserLoggedIn] = useState(false);
	const [currentBidPrice, setCurrentBidPrice] = useState(null);
	const [currentAskPrice, setCurrentAskPrice] = useState(null);
	const [symbolTitle, setSymbolTitle] = useState('');
	const [symbolId,setSymbolId] = useState('');
	
	const [socketData, setSocketData] = useState({});
	const [previousValues, setPreviousValues] = useState({});
	const isInitialMount = useRef(true);
	// --- ---
	const { isActive, toggleMenu } = useMenu();

	const [isActiveone, setIsActiveone] = useState(true);
	const WEB_SOCKET_HOST = urlsAccess.WEB_SOCKET_HOST;

	const [show, setShow] = useState(false);
	const [selectedSymbol, setSelectedSymbol] = useState('');
	const [selectedSymbolID, setSelectedSymbolID] = useState('');
	const [selectedLots, setSelectedLots] = useState('0');
	const [selectedOpenPrice, setSelectedOpenPrice] = useState('-');
	const [selectedClosePrice, setSelectedClosePrice] = useState('-');
	const [selectedSide, setSelectedSide] = useState('-');
	const [selectedTp, setSelectedTp] = useState('');
	const [selectedSl, setSelectedSl] = useState('');
	const [selectedTradeID, setSelectedTradeID] = useState('');


	// --- ---

	// --- --- ----
	const container = useRef();
	useEffect(
		() => {
			// setIsActiveone(false);
			// if (container.current.children.length == 1) {
				container.current.innerHTML= '';
				// container.current.child= '';
				const script = document.createElement("script");
				script.src = "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
				script.type = "text/javascript";
				script.async = true;
				script.innerHTML = JSON.stringify({
					autosize: true,
					symbol: symbolTitle,
					timezone: "Etc/UTC",
					theme: "dark",
					style: "1",
					locale: "in",
					withdateranges: true,
					range: "YTD",
					hide_side_toolbar: false,
					"hide_top_toolbar": true,
					details: false,
					calendar: false,
					show_popup_button: false,
					popup_width: "1000",
					popup_height: "650",
					support_host: "https://www.tradingview.com"
				  });
				container.current.appendChild(script);
				setSide('');
				setTp('');
				setSl('');
				setPending('');
				// setSide(null);
			// }
		},
		[symbolTitle]
	);
	// --- --- ----

	// --- --- ---


	// --- ---
	const navigate = useNavigate();
	// --- ---

	// ---
	const [isDropActive, setIsDropActive] = useState(false);

	const handleMouseEnter = () => {
		setIsDropActive(true);
	};

	const handleMouseLeave = () => {
		setIsDropActive(false);
	};

	const handleItemClick2 = () => {
		setIsDropActive(true);
	}

	const handleItemClick = () => {
		setIsDropActive(false);
	};
	// ---

	const handleLogout = () => {
		dispatch({ type: 'LOGOUT' });
		navigate('/');
	}

	// --- ---
	useEffect(() => {
		const fetchData = async () => {
			const loggedInUser = getLoggedInUserData();
			const isUserLoggedIn = !!loggedInUser;

			if (isUserLoggedIn) {
				if (loggedInUser.id && token) {
					try {
						// const symbolList = await getWatchListSymbol();
						// const getTopSelectedsymbolList = await getTopSelectedSymbol();
						// Assuming getUserWalletGroupWise returns the data needed for wallets and walletGroups
					} catch (error) {
						console.error('Failed to fetch wallet group data:', error);
					}
				}
			}
		};

		if (isInitialMount.current) {
			isInitialMount.current = false;
			fetchData();
			getWalletDetails();
			getTopSelectedSymbol();
		}
	}, [token]);
	const [showPending, setShowPending] = useState(false);
	const [showTakeProfit, setShowTakeProfit] = useState(false);
	const [showStopLoss, setShowStopLoss] = useState(false);

	const [lots, setLots] = useState(0.01);
	const [side, setSide] = useState(null);
	const [sl, setSl] = useState(null);
	const [pending, setPending] = useState(null);
	const [tp, setTp] = useState(null);
	const [position, setPosition] = useState('open');
	const [trades, setTrades] = useState(null);
	const [account, setAccount] = useState(null);
	const [closeTrades, setCloseTrades] = useState(null);
	const [watchListSymbol, setWatchListSymbol] = useState(null);
	const [watchModalListSymbol, setWatchModalListSymbol] = useState(null);
	const [firstwatchModalListSymbol, setFirstWatchModalListSymbol] = useState(null);
	const [firstWatchListSymbol, setFirstWatchListSymbol] = useState(null);
	const [topSelectedSymbol, setTopSelectedSymbol] = useState(null);


	useEffect(() => {
		const pendingData = (showPending && socketData[symbolTitle]) ? (socketData[symbolTitle].ask * 0.95).toFixed(5):pending;

		if(showPending && (side == null || side == '')){
			setSide('buy');
			setPending(pendingData);
		}	
	}, [showPending]);

	useEffect(() => {
		const tpData = ((tp == '' || tp == null) && showTakeProfit && socketData[symbolTitle]) ? (socketData[symbolTitle].ask * 1.01).toFixed(5):tp;

		if(showTakeProfit && (side == null || side == '')){
			if(showPending){
				setTp((showTakeProfit && pending) ? (pending * 1.01).toFixed(5):tp);
			}else {
				setSide('buy');
				setTp(tpData);
			}
		}	
	}, [showTakeProfit]);

	useEffect(() => {
		const slData = ((sl == '' || sl == null) && showStopLoss && socketData[symbolTitle]) ? (socketData[symbolTitle].bid * 0.99).toFixed(5):sl;
		
		if(showStopLoss && (side == null || side == '')){
			if(showPending){
				setSl((showStopLoss && pending) ? (pending * 0.99).toFixed(5):sl)
			} else {
				setSide('buy');
				setSl(slData);
			}
		}	
	}, [showStopLoss]);
	
	useEffect(() => {
		const slData = ((sl == '' || sl == null) && showStopLoss && socketData[symbolTitle]) ? (socketData[symbolTitle].bid * 0.99).toFixed(5):sl;
		const tpData = ((tp == '' || tp == null) && showTakeProfit && socketData[symbolTitle]) ? (socketData[symbolTitle].ask * 1.01).toFixed(5):tp;
		const pendingData = ((pending == '' || pending == null) && showPending && socketData[symbolTitle]) ? (socketData[symbolTitle].ask * 0.95).toFixed(5):pending;
		if(showPending){
			setSl((showStopLoss && pending) ? (pending * 0.99).toFixed(5):sl)
			setTp((showTakeProfit && pending) ? (pending * 1.01).toFixed(5):tp)
		} else {
			setSl(slData);
			setTp(tpData);
		}
		setPending(pendingData);
	}, [socketData]);
	
	useEffect(() => {
		setShowPending(false);
		setShowTakeProfit(false);
		setShowStopLoss(false);
	}, [symbolTitle]);


	// ---
	// ---

	const [width, setWidth] = useState(450);
	const sidebarRef = useRef(null);
	const isResizing = useRef(false);

	const handleMouseDown = () => {
		isResizing.current = true;
	};

	const handleMouseMove = (e) => {
		if (isResizing.current) {
			setWidth(e.clientX);
		}
	};

	const handleMouseUp = () => {
		isResizing.current = false;
	};

	//---dropdown

	const [isOpen, setIsOpen] = useState(false);
	const [selectedValue, setSelectedValue] = useState('All');

	const toggleDropdown = () => {
		setIsOpen(!isOpen);
	};

	const handleOptionClick = (value) => {
		setSelectedValue(value);
		setIsOpen(false);
	};

	const options = [
		'Favorites',
		// 'Popular',
		// 'Top movers',
		// 'Majors',
		// 'Metals',
		// 'Crypto',
		// 'Indices',
		// 'Stocks',
		// 'Energy',
		// 'Exotic',
		// 'Minors',
		'All'
	];

	const [isOpen1, setIsOpen1] = useState(false);
	const [selectedValue1, setSelectedValue1] = useState('All');

	const toggleDropdown1 = () => {
		setIsOpen1(!isOpen1);
	};

	const handleOptionClick1 = (value) => {
		setSelectedValue1(value);
		setIsOpen1(false);
	};

	const options1 = [
		'Favorites',
		// 'Popular',
		// 'Top movers',
		// 'Majors',
		// 'Metals',
		// 'Crypto',
		// 'Indices',
		// 'Stocks',
		// 'Energy',
		// 'Exotic',
		// 'Minors',
		'All'
	];




	// const [isOpenone, setIsOpenone] = useState(false);
	// const [selectedValueone, setSelectedValueone] = useState('');

	// const toggleDropdownone = () => {
	// 	setIsOpenone(!isOpenone);
	// };

	// const handleOptionClickone = (value) => {
	// 	setSelectedValueone(value);
	// 	setIsOpenone(false);
	// };

	// const optionone = [

	// ];


	const [activeTab, setActiveTab] = useState('tab1');
	const [badgeCounts, setBadgeCounts] = useState({
		tab1: 5,
		tab2: 10,
		tab3: 0,
	});

	
	
	const [activeTab01, setActiveTab01] = useState('tab001');
	const handleTabClick = (tab) => {
		setActiveTab(tab);
		// setActiveTab01(tab001);
		// setSymbolTitle(tab);
	};
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const handleTabClick01 = (tab001,id) => {
		// setIsMenuOpen(!isMenuOpen);
		if(tab001 != "tab006"){
			setActiveTab01(tab001);
			setSymbolTitle(tab001);
			setSymbolId(id);
		}else {
			setIsMenuOpen(!isMenuOpen);
		}
	};

	const closeDropdown2 = () => {
		setIsMenuOpen(false);
	};

	// const handleTabClick01 = (tab001,id) => {
	// 	setActiveTab01(tab001);
	// 	if(tab001 != "tab006"){
	// 		setSymbolTitle(tab001);
	// 		setSymbolId(id);
	// 	}
	// };


	const [isSidebarOpen, setIsSidebarOpen] = useState(true);

	const toggleSidebar = () => {
		setIsSidebarOpen(!isSidebarOpen);
	};

	// popup modal

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isModalOpen01, setIsModalOpen01] = useState(false);
	const [isModalOpen02, setIsModalOpen02] = useState(false);
	const [isModalOpen03, setIsModalOpen03] = useState(false);

	const openModal = (side) => {
		setSide(side);
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	const openModal01 = () => {
		setIsModalOpen01(true);
	};

	const closeModal01 = () => {
		setIsModalOpen01(false);
	};


	const openModal02 = () => {
		setIsModalOpen02(true);
	};

	const closeModal02 = () => {
		setIsModalOpen02(false);
	};
	const openModal03 = () => {
		setIsModalOpen03(true);
	};

	const closeModal03 = () => {
		setIsModalOpen03(false);
	};


	// ---
	const [isTradingvalue, setIsTradingvalue] = useState(false);

	const handleTradingvalueClose = () => {
		setIsTradingvalue(false);
	};

	const handleTradingvalueShow = () => {
		setIsTradingvalue(true);
		getWalletDetails();
	};
	// ---


	// const [isActive, setIsActive] = useState(false);

	// const toggleMenu = () => {
	//   setIsActive(!isActive);
	// };


	useEffect(() => {
		if (isActive) {
			document.body.classList.add('blur');
		} else {
			document.body.classList.remove('blur');
		}
	}, [isActive]);
	//API CODE
	const [isLoading, setIsLoading] = useState(false);
	const [errors, setErrors] = useState({});
	const [showSendBtn, setShowSendBtn] = useState(false);
	const [requestSubmitSuccess, setRequestSubmitSuccsess] = useState(false);
	const [requestSubmitError, setRequestSubmitError] = useState(false);
	const [requestSubmitErrorMsg, setRequestSubmitErrorMsg] = useState(false);
	const [requestSubmitSuccessMsg, setRequestSubmitSuccessMsg] = useState(false);
	const [showModel, setShowModel] = useState(false);

	
	//const [userWalletId, setUserWalletId] = useState(null);
	useEffect(() => {
		// Define the function to be called periodically
		const callFunction = () => {
			getTradingHistory();
			getCloseTradingHistory();
		};
		// Set up the interval to call the function every second (1000ms)
		const intervalId = setInterval(callFunction, 10000);
		// Cleanup interval on component unmount
		return () => {
			clearInterval(intervalId);
		};
	}, [token]);

	const handleLotChange = (newValue) => {
		console.log(newValue);
		setLots(newValue);
	};

	// Function to get the value before the decimal point
	const getBeforeDecimal = (value) => {
		if (!value || isNaN(value)) return '';
		return value.toString().split('.')[0];
	  };
	
	  // Function to get the first 2 digits after the decimal point
	  const getFirstTwoAfterDecimal = (value) => {
		if (!value || isNaN(value)) return '';
		const parts = value.toString().split('.');
		return parts[1] ? parts[1].slice(0, 2) : '';
	  };
	
	  // Function to get the 3rd digit after the decimal point
	  const getThirdAfterDecimal = (value) => {
		if (!value || isNaN(value)) return '';
		const parts = value.toString().split('.');
		return parts[1] ? (parts[1][2] || '') : '';
	  };

	const handleSideClick = (side) => {
		setSide(side);
	}
	const handleCancelClick = () => {
		setSide(null);
	}
	const handlePendingChange = (p) => {
		setPending(p);
	}
	const handleSlChange = (stopLoss) => {
		const slData = (showStopLoss && socketData[symbolTitle]) ? (socketData[symbolTitle].bid * 0.99).toFixed(5):sl;
		
		setSl(stopLoss);
		// validatePrices(stopLoss,slData)
	}
	const handleTpChange = (takeProfit) => {
		const tpData = (showTakeProfit && socketData[symbolTitle]) ? (socketData[symbolTitle].ask * 1.01).toFixed(5):tp;
		console.log('tpDatatpDatatpDatatpData', takeProfit);
		setTp(takeProfit);
		// validatePrices((socketData[symbolTitle].ask * 1.01).toFixed(5),takeProfit)
	}

	

	const getTradingHistory = async () => {
		// Logic to get user wallet group-wise data
		try {
			//setIsLoading(true);
			const response = await apiCall(API_URL + 'get-mt5-trading-history', {
				user_id: state.userData.id,
				token: token,
				nopaginate: 1,
				position: position,
				user_wallet_id: account_id != null ? account_id : account.id
				//transfer_type:transferTypeFilter,
				// transaction_status:statusFilter
			});
			if (response.data.success == '0') {
				setTrades(response.data.data);
			}
			//setIsLoading(false);
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	};
	const getWalletDetails = async () => {
		// Logic to get user wallet group-wise data
		try {
		  //setIsLoading(true);
		  const response = await apiCall(API_URL +'get-wallet-details', {
			  user_id: state.userData.id,
			  token: token,
			  wallet_id:account_id
			  //transfer_type:transferTypeFilter,	
			 // transaction_status:statusFilter
		  });
		  if (response.data.success == '0') {
			setAccount(response.data.data);	
		  }else {
			setShowModel(true)
		  }
		  //setIsLoading(false);
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	};
	
	
	const handleRemoveTopSymbol = async(symbol_name,symbol_id) => {
		// Logic to get user wallet group-wise data
		try {
			//setIsLoading(true);
			const response = await apiCall(API_URL +'remove-symbol-on-top', {
				user_id: state.userData.id,
				token: token,
				symbol_id:symbol_id
			});
			if (response.data.success == '0') {
				const updatedSymbols = topSelectedSymbol.filter(symbol => symbol.symbol.name != symbol_name);
				// Set the active tab to the last symbol if any symbols remain
				if (updatedSymbols.length > 0) {
					const lastSymbol = updatedSymbols[updatedSymbols.length - 1];
					// console.log("fsfsdfds",lastSymbol.symbol.name);
					setActiveTab01(lastSymbol.symbol.name);
					setSymbolTitle(lastSymbol.symbol.name);
					setSymbolId(lastSymbol.symbol.id);
			}
			// setActiveTab01("tab001");
			getTopSelectedSymbol();
			// setAccount(response.data.data);	
			}else {
			setShowModel(true)
			}
			//setIsLoading(false);
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	}

	useEffect(()=>{
		getTopSelectedSymbol();
	},handleRemoveTopSymbol)

	const addTopSymbol = async (symbol_name,symbol_id) => {
		// Logic to get user wallet group-wise data
		try {
		  //setIsLoading(true);
		  const response = await apiCall(API_URL +'add-symbol-on-top', {
			  user_id: state.userData.id,
			  token: token,
			  symbol_id:symbol_id
		  });
		  if (response.data.success == '0') {
			setActiveTab01(symbol_name);
			setSymbolTitle(symbol_name);
			setSymbolId(symbol_id);
			getTopSelectedSymbol();
			if(isMenuOpen){
				setIsMenuOpen(false);
			}

			if(isModalOpen03){
				setIsModalOpen03(false);
			}
			// handleTabClick01(symbol_name,symbol_id);
			// setAccount(response.data.data);	
		  }else {
			setShowModel(true)
		  }
		  //setIsLoading(false);
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	};

	const [isFavActive, setIsFavActive] = useState(false);

	// useEffect(() => {
	// 	if (isActive) {
	// 		getWatchListSymbol();
	// 	}
	// }, [isActive]);
	const addFavSymbol = async (symbol_id) => {
		// Logic to get user wallet group-wise data
		try {
		  //setIsLoading(true);
		  const response = await apiCall(API_URL +'add-remove-fav-symbol', {
			  user_id: state.userData.id,
			  token: token,
			  symbol_id:symbol_id
		  });
		  if (response.data.success == '0') {
			getWatchListSymbol();
			setIsFavActive(!isActive);
			// setAccount(response.data.data);	
		  }else {
			setShowModel(true)
		  }
		  //setIsLoading(false);
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	};

	const getWatchListSymbol = async () => {
		try {
		//   setIsLoading(true);
		  const response = await apiCall(API_URL +'watchlist-symbol', {
			user_id: state.userData.id,
			filterSelect : isMenuOpen ? selectedValue1 : selectedValue,
			token: token,
		  });
		  if (response.data.success == '0') {
			// console.log(response.data.);
			if(!topSelectedSymbol){
				addTopSymbol(response.data.data[0].name,response.data.data[0].id);
			}
			if(!isMenuOpen){
				setWatchListSymbol(response.data.data);
			}
			
			
			if(selectedValue == 'All'){
				setFirstWatchListSymbol(response.data.data);
			}
			
			
			if(selectedValue1 == 'All'){
				setFirstWatchModalListSymbol(response.data.data);
			}
			
			setWatchModalListSymbol(response.data.data);
		  }
		  //setIsLoading(false);
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	};

	const getTopSelectedSymbol = async () => {
		try {
		  //setIsLoading(true);
		  const response = await apiCall(API_URL +'top-selected-symbol', {
			user_id: state.userData.id,
			token: token,
		  });
		  if (response.data.success == '0') {
			console.log('top some selected symbols',response.data.data);
			setTopSelectedSymbol(response.data.data);
			console.log("activeTab01", activeTab01);
			if(response.data.data && response.data.data.length > 0 && activeTab01 == "tab001"){
				setActiveTab01(response.data.data[0].symbol.name)
				setSymbolTitle(response.data.data[0].symbol.name)
				setSymbolId(response.data.data[0].symbol_id)
			}
		  }
		  //setIsLoading(false);
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	};


	const getCloseTradingHistory = async () => {
		// Logic to get user wallet group-wise data
		try {
			// setIsLoading(true);
			const response = await apiCall(API_URL + 'get-mt5-trading-history', {
				user_id: state.userData.id,
				token: token,
				nopaginate: 1,
				position: 'close',
				user_wallet_id: account_id != null ? account_id : account.id
				//transfer_type:transferTypeFilter,
				// transaction_status:statusFilter
			});
			if (response.data.success == '0') {
				setCloseTrades(response.data.data);
			}
			//setIsLoading(false);
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	};

	const validateFields = () => {
		const newErrors = {};
		if (!lots) {
			newErrors.lots = 'Lots is required';
		} else if (isNaN(lots) || lots <= 0) {
			newErrors.lots = 'Lots must be a positive number';
		}
		return newErrors;
	};
	const handleSubmit = async () => {
		const valErrors = validateFields();
		if (Object.keys(valErrors).length === 0) {
			// Create a FormData object
			const formData = new FormData();
			formData.append('user_id', state.userData?.id);
			formData.append('token', state?.token);
			formData.append('user_wallet_id', account_id);
			formData.append('lots', lots);
			formData.append('symbol_id', symbolId);
			formData.append('side', side);
			formData.append('price', socketData[symbolTitle] ? (side == "sell" ? socketData[symbolTitle].bid : socketData[symbolTitle].ask) : '');
			formData.append('tp', showTakeProfit ? tp : 0);
			formData.append('sl', showStopLoss ? sl : 0);
			formData.append('pending', showPending ? pending : 0);

			try {
				// Send FormData using Axios
				// setIsLoading(true);
				setRequestSubmitSuccsess(false)
				setRequestSubmitError(false)
				apiCall(API_URL + 'send-open-trade', formData, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				})
					.then(response => {
						if (response.data.success == '0') {
							// setLots('0.01');
							setSide(null);
							// setPending('');
							// setSl('');
							// setTp('');
							setRequestSubmitSuccsess(true)
							setRequestSubmitSuccessMsg(response.data.message)
						} else {
							setRequestSubmitError(true)
							setRequestSubmitErrorMsg(response.data.data.error)
						}
						// setIsLoading(false);
					})
					.catch(error => {
						setIsLoading(false);
						setRequestSubmitError(true)
						setRequestSubmitErrorMsg(error.response.data.message)
						//console.error('Error uploading profile picture:', error);
					});
			} catch (error) {
				//console.error('Error uploading files:', error);
			}
		} else {
			// Set errors to state
			setErrors(valErrors);
		}
	};

	const closeTrade = async (tradeId) => {
		const formData = new FormData();
		formData.append('user_id', state.userData?.id);
		formData.append('token', state?.token);
		formData.append('user_wallet_id', account_id);
		formData.append('trade_id', selectedLots != '0' ? selectedTradeID:tradeId);
		formData.append('lots', selectedLots);

		try {
			// Send FormData using Axios
			// setIsLoading(true);
			setRequestSubmitSuccsess(false)
			setRequestSubmitError(false)
			apiCall(API_URL + 'close-trade', formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
				.then(response => {
					if (response.data.success == '0') {
						// setLots('0.01');
						setSide(null);
						setRequestSubmitSuccsess(true)
						setRequestSubmitSuccessMsg(response.data.message)
					} else {
						setRequestSubmitError(true)
						setRequestSubmitErrorMsg(response.data.data.error)
					}
					// setIsLoading(false);
				})
				.catch(error => {
					// setIsLoading(false);
					setRequestSubmitError(true)
					setRequestSubmitErrorMsg(error.response.data.message)
					//console.error('Error uploading profile picture:', error);
				});
		} catch (error) {
			//console.error('Error uploading files:', error);
		}
	};
	
	


	// const popover = (
	// 	<Popover id="popover-basic" className="custom-popover">

	// 		<Popover.Body>
	// 			<PanelCounter
	// 				isShow={true}
	// 				className="mt-0"
	// 				placeholder="Not set"
	// 				Label="lots"
	// 				dropdownItems={["Volume in lots"]}
	// 				// dropdownItems={["Volume in lots", "Volume in currency", "Volume in units"]}
	// 				isDrop={true}
	// 				inputValue={lots}
	// 				error={false}
	// 				errorMeg="lots is required"
	// 				onValueChange={handleLotChange}
	// 			/>
	// 			<div className='buy-sell-confirmation mt-2'>
	// 				<div className="bs-confirmation-btn buy-confirmation">Update</div>
	// 			</div>
	// 		</Popover.Body>
	// 	</Popover>
	// );

	

	const handleClose = () => setShow(false);
	const handleShow = (tradeData,type) => {
		console.log('tradeData', tradeData);
		setSelectedSymbol(tradeData.symbol.name);
		setSelectedSymbolID(tradeData.symbol_id);
		setSelectedLots(tradeData.lots)
		setSelectedTp(tradeData.take_profit)
		setSelectedSl(tradeData.stop)
		setSelectedOpenPrice(tradeData.open_price)
		setSelectedClosePrice(tradeData.close_price)
		setSelectedTradeID(tradeData.id)
		setSelectedSide(tradeData.side)
		if(type == 'sl'){
			const slData = (socketData[tradeData.symbol.name]) && selectedTp != '0' ? (socketData[tradeData.symbol.name].bid * 0.99).toFixed(5):selectedTp;
			setSelectedTp('');
			setSelectedSl(slData);
			// set
		} else if(type == 'tp'){
			const tpData = (socketData[tradeData.symbol.name]) && selectedSl != '0' ? (socketData[tradeData.symbol.name].ask * 1.01).toFixed(5):selectedSl;
			setSelectedSl('');
			setSelectedTp(tpData);
		}
		setShow(true)
	};
	const handleSelectedTpChange = (value) => {
		setSelectedTp(value);
	}
	const handleSelectedSlChange = (value) => {
		setSelectedSl(value);
	}
	const handleSelectedLotChange = (value) => {
		console.log('value', value);
		
		setSelectedLots(value);
	}
	const updatePosition = async () => {
		const formData = new FormData();
		formData.append('user_id', state.userData?.id);
		formData.append('token', state?.token);
		formData.append('user_wallet_id', account_id);
		formData.append('trade_id', selectedTradeID);
		formData.append('lots', selectedLots);
		formData.append('side', selectedSide);
		formData.append('symbol_id', selectedSymbolID);
		formData.append('price', socketData[symbolTitle] ? (side == "sell" ? socketData[symbolTitle].bid : socketData[symbolTitle].ask) : '');
		formData.append('tp', selectedTp);
		formData.append('sl', selectedSl);
		// alert('dasd');
		try {
			// Send FormData using Axios
			// setIsLoading(true);
			setRequestSubmitSuccsess(false)
			setRequestSubmitError(false)
			apiCall(API_URL + 'update-position', formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
				.then(response => {
					if (response.data.success == '0') {
						// setLots('0.01');
						// setSide(null);
						setRequestSubmitSuccsess(true)
						setRequestSubmitSuccessMsg(response.data.message)
						setShow(false);
					} else {
						setRequestSubmitError(true)
						setRequestSubmitErrorMsg(response.data.data.error)
					}
					// setIsLoading(false);
				})
				.catch(error => {
					// setIsLoading(false);
					setRequestSubmitError(true)
					setRequestSubmitErrorMsg(error.response.data.message)
					//console.error('Error uploading profile picture:', error);
				});
		} catch (error) {
			//console.error('Error uploading files:', error);
		}
	};
	
	/**tab***/

	const [activeTabone, setActiveTabone] = useState('tabone');

	const handleTabClickone = (tab) => {
		setActiveTabone(tab);
	};

	/**Loader***/

	const [loading, setLoading] = useState(true);
	const [data, setData] = useState(null);


	/***toggle table***/


	const [isContentVisible, setContentVisible] = useState(true);

	const toggleContentVisibility = () => {
	setContentVisible(!isContentVisible);
	};

    const MAX_RETRIES = 5;
let ws; // Declare ws outside useEffect to persist it across renders

useEffect(() => {
    let retries = 0;

    const connectWebSocket = () => {
        //ws = new WebSocket('ws://164.132.247.26:7504/ws');
		//ws = new WebSocket('wss://164.132.247.26:7505/ws');
		ws = new WebSocket('wss://ticker.winprofx.com:7505/ws');
		//ws = new WebSocket('wss://ticker.eonefx.com:6916/ws');

        ws.onopen = () => {
            setIsActiveone(false);
            console.log('Connected to the WebSocket');
            retries = 0; // Reset retries on successful connection
        };

        ws.onmessage = (event) => {
            const data = JSON.parse(event.data);
            if (data.symbol === symbolTitle) {
                setCurrentAskPrice(data.ask);
                setCurrentBidPrice(data.bid);
            }

            setSocketData((prevData) => {
                const prevAsk = prevData[data.symbol]?.ask ?? data.ask;
                const prevBid = prevData[data.symbol]?.bid ?? data.bid;

                setPreviousValues((prevValues) => ({
                    ...prevValues,
                    [data.symbol]: {
                        ask: prevAsk,
                        bid: prevBid,
                    },
                }));

                return {
                    ...prevData,
                    [data.symbol]: data,
                };
            });
        };

        ws.onerror = (error) => {
            setIsActiveone(true);
            console.error('WebSocket error:', error);
        };

        ws.onclose = () => {
            setIsActiveone(true);
            console.log('WebSocket connection closed');

            if (retries < MAX_RETRIES) {
                retries++;
                console.log(`Retrying WebSocket connection, attempt ${retries}`);
                setTimeout(connectWebSocket, 1000 * retries); // Exponential backoff
            }
        };
    };

    if (!ws || ws.readyState === WebSocket.CLOSED) {
        connectWebSocket(); // Only connect if ws is not already open
    }

    // Cleanup on unmount
    return () => {
        if (ws) ws.close();
    };
}, []); // Dependency array, only re-run effect if `token` changes

	
	
	useEffect(() => {
		getWatchListSymbol();
	}, [selectedValue1,selectedValue]);
	
	useEffect(() => {
		if(isMenuOpen){
			getWatchListSymbol();
		}
	}, [isMenuOpen]);	

	const [searchTerm, setSearchTerm] = useState('');
	
	
	const [searchModalTerm, setSearchModalTerm] = useState('');

    const filterArray = (searchValue) => {
        const filteredArray = firstWatchListSymbol.filter(item =>
            item.name.toLowerCase().includes(searchValue.toLowerCase())
        );
		if(searchValue != ''){
			setWatchListSymbol(filteredArray);
		} else {
			watchListSymbol(filteredArray);
		}
    };
	
	
	const filterModalArray = (searchValue) => {
        const filteredArray = firstwatchModalListSymbol.filter(item =>
            item.name.toLowerCase().includes(searchValue.toLowerCase())
        );
        setWatchModalListSymbol(filteredArray);
    };

    const handleInputChange = (event) => {
        const value = event.target.value;
        setSearchTerm(value);
        filterArray(value);
    };
	
	const handleModalInputChange = (event) => {
        const value = event.target.value;
        setSearchModalTerm(value);
        filterModalArray(value);
    };

	const getCSSClasses = (symbol) => {
		const currentData = socketData[symbol] || {};
		const previousData = previousValues[symbol] || {};
	
		let askClass = "small-btn buy-btn";
		let bidClass = "small-btn sell-btn";
	
		if (currentData.ask > previousData.ask) {
			askClass += " status-green";
		} else if (currentData.ask < previousData.ask) {
			askClass += " status-red";
		}
	
		if (currentData.bid > previousData.bid) {
			bidClass += " status-green";
		} else if (currentData.bid < previousData.bid) {
			bidClass += " status-red";
		}
	
		return { askClass, bidClass };
	};

	const formatSymbolName = (symbolName) => {
		if(symbolName.length == 6){
			return symbolName.slice(0, 3) + '/' + symbolName.slice(3);
		} else {
			return symbolName
		}
	};

	const getSymbolImage = (symbolImages) => {
		if (symbolImages.length == 2) {
			  try {
				return (
					<span className='twobox-img'>
																				
					<img src={symbolImages[0]}  className='twb-one' />
					<img src={symbolImages[1]}  className='twb-two' />
				</span>
				);
				
			  } catch (error) {
				return ''; // Return an empty string if the image is not found
			  }
		  
		} else if(symbolImages.length == 1){
			try {
				return (
				  <span className='icons'>
					<img src={symbolImages[0]} />
				  </span>
				);
			  } catch (error) {
				return ''; // Return an empty string if the image is not found
			  }
		} else {
		  return '';
		}
	  };
	  
	


	const [isDropdownOpen2, setIsDropdownOpen2] = useState(false);

	const toggleDropdown2 = () => {
	  setIsDropdownOpen2(!isDropdownOpen2);
	};
  
	// const closeDropdown2 = () => {
	//   setIsDropdownOpen2(false);
	// };

	
	return (
		<>
			<div className={`spiner-loader ${isActiveone ? '' : 'deactive'}`}>
				<div className="spinner">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><circle fill="#0376BC" stroke="#0376BC" strokeWidth="10" r="15" cx="40" cy="100"><animate attributeName="opacity" calcMode="spline" dur="2.3" values="1;0;1;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.4"></animate></circle><circle fill="#0376BC" stroke="#0376BC" strokeWidth="10" r="15" cx="100" cy="100"><animate attributeName="opacity" calcMode="spline" dur="2.3" values="1;0;1;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.2"></animate></circle><circle fill="#0376BC" stroke="#0376BC" strokeWidth="10" r="15" cx="160" cy="100"><animate attributeName="opacity" calcMode="spline" dur="2.3" values="1;0;1;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="0"></animate></circle></svg>
				</div>
			</div>
			{/* --- Trading terminal header start --- */}
			<div className="tradingview-header">


				<div className='tvh-left-side'>

					<div className="ch-left trading-sidebar mobile">

						<div className='tds-heder'>
							<div className={`menu-icon-bx ${isActive ? 'active' : ''}`} onClick={toggleMenu}>
								<span></span>
								<span></span>
								<span></span>
							</div>

							{/* <div className='tds-terminal mobile'>
								terminal
							</div> */}
						</div>


						<div className={`account-menu-bx ${isActive ? 'active' : ''}`}>

							<div className='amb-link' onClick={openModal02}>
								<Icons.ListSvg />
								<span>Watchlist</span>
							</div>
							<div className='amb-link' onClick={openModal01}>
								<Icons.BriefcaseSvg />
								<span>Portfolio</span>
							</div>
							<div className='amb-link'>
								<Icons.ChatBubbleOvalLeftEllipsisSvg />
								<span>Support</span>
							</div>

							<div className="link-separator"></div>

							<div className='amb-link bottom-fix' onClick={handleLogout}>
								<Icons.LogoutSvg />
								<span>LogOut</span>
							</div>
						</div>

						<div className={`overlay ${isActive ? 'active' : ''}`} onClick={toggleMenu}></div>
					</div>





					<div className='tvh-logo-section'>
						{/* --- Header logo start --- */}
						<Link to="/dashboard" className="tvh-logo desktop" target="_blank">
							<img src={Img.logo_light} alt="" />
						</Link>
						<Link to="/dashboard" className="tvh-logo mobile" target="_blank">
							<img src={Img.favicon_light} alt="" />
						</Link>
						{/* --- Header logo end --- */}
					</div>

					{/*--tvg tab start--*/}
					<div className='tvg-tab-section desktop'>
						<div className="tabs">
							<div className="tab-list">
								{(topSelectedSymbol && topSelectedSymbol.length > 0) &&
									topSelectedSymbol.map((topSymbol,index)=>(
										<button className={`tab ${(activeTab01 == topSymbol.symbol.name) ? 'active' : ''}`} onClick={() => handleTabClick01(topSymbol.symbol.name,topSymbol.symbol_id)}>
											
											{/* <span className='icons'>
												<img src={require('../../img/trading/symbol_icons/'+topSymbol.symbol.name+'.png')} alt={topSymbol.symbol.name} />
											</span> */}
											{getSymbolImage(topSymbol.images)}
											<span className='tvg-contry'>{formatSymbolName(topSymbol.symbol.name)}</span>
											{(topSelectedSymbol && topSelectedSymbol.length > 1) && 
												<div className='tvg-cross icons' onClick={() => handleRemoveTopSymbol(topSymbol.symbol.name,topSymbol.symbol_id)}>
													<Icons.XMarkSvg />
												</div>
											}
											<span className='icons'>
												<Icons.EllipsisHorizontalSvg />
											</span>
										</button>
									))
								}
								<button
									className={`tab tab-plus add-tab`}
									onClick={() => handleTabClick01('tab006','0')}
								>
									<div className='signal icons'>
									<Icons.PlusSvg />
									</div>
								</button>
								{/* Dropdown Menu */}
								 {isMenuOpen && (
									<>
									<div className="overlay" onClick={closeDropdown2}></div>
									<div className="dropdown-menu tab-dropdown">
										<div className="sidebar-content">
											<div className='row row-gap-2'>

												<div className='tvb-search'>
													<div className="search-bar">
														<div className="icon">
															<Icons.SearchSvg />
														</div>
														<input
															type="text"
															value={searchModalTerm}
                											onChange={handleModalInputChange}
															className="search-input"
															placeholder="Search..."
														/>
													</div>
												</div>

												<div className='custom-input-dropdown'>
													<div className="input-dropdown">
														<div className="dropdown-container" onClick={toggleDropdown1}>
															<input
																type="text"
																value={selectedValue1}
																placeholder="Select an option"
																readOnly
															/>
															<span className={`down-arrow ${isOpen1 ? 'open' : ''}`}><Icons.DownSvg /></span>
														</div>
														{isOpen1 && (
															<div className="dropdown-menu csid-menu">
																{options1.map((option, index) => (
																	<div
																		key={index}
																		className={"dropdown-item "+(selectedValue1==option ? "active" : "")}
																		
																		onClick={() => handleOptionClick1(option)}
																	>
																		{option}
																	</div>
																))}
															</div>
														)}
													</div>
												</div>


												<div className='watchlist-table'>
													<div className="wt-wrapper">
														<table className="fixed-column-table">
															<tbody>

																{(watchModalListSymbol && watchModalListSymbol.length > 0) &&
																	watchModalListSymbol.map((watchListModalData, index) => {
																		return(<tr key={index}>
																			<td className="sticky-col" onClick={()=>addTopSymbol(watchListModalData.name,watchListModalData.id)}>
																				{/* <span className='twobox-img'>
																				
																					<img src={Img.flag1} alt="eurousd"  className='twb-one' />
																					<img src={Img.flag2} alt="eurousd"  className='twb-two' />
																				</span> */}
																				{/* <span className='icons'>
																					<img src={Img.usdhkd} alt="flag2" />
																				</span> */}
																				{getSymbolImage(watchListModalData.images)}
																				{formatSymbolName(watchListModalData.name)}</td>
																			<td>
																				<div className='small-btn sell-btn '>
																					{watchListModalData.description}
																				</div>
																			</td>
																			<td>
																				<div className={`rateing icons ${watchListModalData.is_fav == '1' ? 'active' : ''}`} onClick={()=>addFavSymbol(watchListModalData.id)}>
																					<Icons.StarSvg />
																				</div>	
																			</td>
																		</tr>);
																	})
																}
															</tbody>
														</table>
													</div>
												</div>
											</div>
										</div>
									</div>
									</>
								)}
							</div>

						</div>
					</div>
					{/*--tvg tab End--*/}

				</div>





				{/* --- Header right start --- */}
				<div className="tvh-right">

					<div className='tvh-balance' onClick={handleTradingvalueShow}>
						<span className='status-green-label'>{account && account.mt5_type}</span>
						{/* <span className='tvh-default'>Default</span> */}
						<div className='tvhb-popup' >
							{account && account.formatted_available_balance} <span className='tvhb-curancy'>{account && account.currency} </span> <span className='icons pe-0'><Icons.DownSvg /></span>
						</div>
					</div>

					<div className={`cr-drop-item ${isDropActive ? 'active' : ''}`} onMouseLeave={handleMouseLeave}>
						<div className="ch-user-bx" onClick={handleItemClick2} onMouseEnter={handleMouseEnter}>
							{/* <div className="cr-icons-link"><Icons.UserSvg></div> */}

							<div className="cub-img-bx">
								<img src={user?.image || Img.user} alt="" />
							</div>

							{/* <div className="cub-text-bx">User Name <Icons.DownSvg/></div> */}
						</div>
						<div className={`cr-drop-bx ${userLoggedIn} ${isDropActive ? 'active' : ''}`} onClick={handleItemClick}>
							<div className="cr-drop-inner">
								<div className="cdi-main w-186px">
									<Link to="/settings" className="cdi-user-data">
										<div className="cud-name">{user?.first_name} {user?.last_name}</div>
										<div className="cud-other">{user?.email}</div>
									</Link>
									<NavLink to="/dashboard" className="cdi-main-item"><Icons.DashboardSvg /> Dashboard</NavLink>
									<NavLink to="/notifications" className="cdi-main-item"><Icons.NotificationsSvg /> Notifications <div className="cmi-num">99</div></NavLink>
									<div className="cdi-main-item" onClick={handleLogout}><Icons.LogoutSvg /> Log Out</div>
								</div>
							</div>
						</div>
					</div>

					<div className='tvh-panel mobile' onClick={openModal01}>
						<div className='icons'><Icons.RectangleStackSvg /></div>
					</div>
				</div>
				{/* --- Header right end --- */}

			</div>
			{/* --- Trading terminal header end --- */}


			{/* --- Trading terminal body start --- */}

			{/*--tvg tab start--*/}
			<div className='tvg-tab-section mobile'>
				<div className="tabs">
					<div className="tab-list">
						{(topSelectedSymbol && topSelectedSymbol.length > 0) && topSelectedSymbol.map((topSymbol,index)=>(
							<button className={`tab ${(activeTab01 == topSymbol.symbol.name) ? 'active' : ''}`} onClick={() => handleTabClick01(topSymbol.symbol.name,topSymbol.symbol_id)}>
								{getSymbolImage(topSymbol.images)}

								<span className='tvg-contry'>{formatSymbolName(topSymbol.symbol.name)}</span>
								{(topSelectedSymbol && topSelectedSymbol.length > 1) && 
									<div className='tvg-cross icons' onClick={() => handleRemoveTopSymbol(topSymbol.symbol.name,topSymbol.symbol_id)}>
										<Icons.XMarkSvg />
									</div>
								}

							</button>
						))
						}

						<button
							className={`tab`}
							onClick={openModal03}
						>
							<div className='signal icons'>
								<Icons.PlusSvg />
							</div>
						</button>


					</div>

				</div>
			</div>
			{/*--tvg tab End--*/}

			<div className="tradingview-body">

				{/* --- */}
				<div className="tvb-left">

					<div className="sidebar-container" onMouseMove={handleMouseMove} onMouseUp={handleMouseUp} >
						<div className={`sidebar ${isSidebarOpen ? 'open' : 'closed'}`} style={{ width: isSidebarOpen ? '350px' : '24px' }}
						>
							<div className="icon-btn side-icon icons" onClick={toggleSidebar}><Icons.RightSvg /></div>
							<div className="sidebar-content">
								<div className='row row-gap-2'>
									<div className='tvb-header mb-2'>
										<div className='tvb-left-side'>
											Instruments
										</div>
										<div className='tvb-right-side'>
											<div className='tvb-btn'>
												{/* <div className="icon-btn"><Icons.EllipsisVerticalSvg /></div> */}
												{/* <div className="icon-btn" ><Icons.QrCodeSvg /></div> */}
												<div className="icon-btn text-2" onClick={toggleSidebar}><Icons.ArrowsPointingOutSvg /></div>
											</div>
										</div>
									</div>
									<div className='tvb-search'>
										<div className="search-bar">
											<div className="icon">
												<Icons.SearchSvg />
											</div>
											<input
												type="text"
												className="search-input"
												value={searchTerm}
                								onChange={handleInputChange}
												placeholder="Search..."
											/>
										</div>
									</div>

									<div className='custom-input-dropdown'>
										<div className="input-dropdown">
											<div className="dropdown-container" onClick={toggleDropdown}>
												<input
													type="text"
													value={selectedValue}
													placeholder="Select an option"
													readOnly
												/>
												<span className={`down-arrow ${isOpen ? 'open' : ''}`}><Icons.DownSvg /></span>
											</div>
											{isOpen && (
												<div className="dropdown-menu">
													{options.map((option, index) => (
														<div
															key={index}
															className="dropdown-item"
															onClick={() => handleOptionClick(option)}
														>
															{option}
														</div>
													))}
												</div>
											)}
										</div>
									</div>

									<div className='watchlist-table'>
										<div className="wt-wrapper">
											<table className="fixed-column-table">
												<thead>
													<tr>
														<th className="sticky-col">Symbol</th>
														{/* <th>Description</th> */}
														<th>Bid</th>
														<th>Ask</th>
														{/* <th>1D change</th> */}
														{/* <th>P/L, USD</th> */}
														{/* <th>1D change</th> */}
														{/* <th></th> */}
														<th></th>
														{/* Add more headers as needed */}
													</tr>
												</thead>
												<tbody>
												{(watchListSymbol && watchListSymbol.length > 0 && !isLoading) &&
													watchListSymbol.map((watchListData, index) => {
														const socketItem = socketData[watchListData.name] || {};
														const ask = socketItem.ask || '-';
														const bid = socketItem.bid || '-';

														// Get CSS classes based on current and previous values
														const { askClass, bidClass } = getCSSClasses(watchListData.name);
														return (<tr key={index}>	
															<td className="sticky-col" onClick={()=>addTopSymbol(watchListData.name,watchListData.id)}>
																{getSymbolImage(watchListData.images)}

																{formatSymbolName(watchListData.name)}</td>
																{/* <div className='signal icons status-green'>
																	<Icons.ArrowDownSvg />
																</div> */}
															{/* <td>
																
																{watchListData.description}
															</td> */}
															<td>
																<div className={bidClass}>
																	{bid}
																</div>
															</td>
															<td>
																<div className={askClass}>
																	{ask}
																</div>
															</td>
															{/* <td>
																<div className='return icons status-green-label'>
																	<Icons.ArrowDownSvg />
																	<span className='statusgreen'>0.25%</span>
																</div>
															</td> */}
															{/* <td>
																<div className='pl-usd'>-</div>
															</td>

															<td>
																<div className='pl-usd'>-</div>
															</td> */}

															<td>
																<div className={`rateing icons ${watchListData.is_fav == '1' ? 'active' : ''}`} onClick={()=>addFavSymbol(watchListData.id)}>
																	<Icons.StarSvg />
																</div>
															</td>


														</tr>);
})
												}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
							{/* <div className="resizer" onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} /> */}
						</div>

					</div>

				</div>
				{/* --- */}


				{/* --- */}
				<div className="tvb-right">

					{/* --- */}
					<div className="tvg-right-flex">

						{/* ---start tab section */}
						<div className="tvg-r-left">


							{/* ---End tab section */}

							{/* <div className="tab-content graph">
								{activeTab === 'tab1' && <div>Content for Tab 1</div>}
								{activeTab === 'tab2' && <div>Content for Tab 2</div>}
								{activeTab === 'tab3' && <div>Content for Tab 3</div>}
							</div>					 */}

							<div className={`tradingview-widget-container ${isContentVisible ? 'tradingview-widget-container-visible' : 'tradingview-widget-container-hidden'}`} ref={container}>
								<div className="tradingview-widget-container__widget"></div>
								{/* <div className="tradingview-widget-copyright"><a href="https://in.tradingview.com/" rel="noopener nofollow" target="_blank"><span className="blue-text">Track all markets on TradingView</span></a></div> */}
							</div>
							<div className='tvg-tab-section desktop'>
								<div className="tabs tab-style-two">
									<div className='tvg-tab-inner'>
										<div className="tab-list">
											<button
												className={`tab ${activeTab === 'tab1' ? 'active' : ''}`}
												onClick={() => handleTabClick('tab1')}
											>
												Open
												{/* {badgeCounts.tab1 > 0 && <span className="badge">{badgeCounts.tab1}</span>} */}
												{/* {trades && trades.length > 0 && <span className="badge">{trades.length}</span>} */}
											</button>
											{/* <button
												className={`tab ${activeTab === 'tab2' ? 'active' : ''}`}
												onClick={() => handleTabClick('tab2')}
											>
												GBP/USD
												{badgeCounts.tab2 > 0 && <span className="badge">{badgeCounts.tab2}</span>}
											</button> */}
											<button
												className={`tab ${activeTab === 'tab3' ? 'active' : ''}`}
												onClick={() => handleTabClick('tab3')}
											>
												Close
												{closeTrades && closeTrades.length > 0 && <span className="badge">{closeTrades.length}</span>}
											</button>


										</div>
										<div className='tab-icon'>
											<div className="icon-btn icons" onClick={toggleContentVisibility}>
												{isContentVisible ? <Icons.ArrowsPointingInSvg /> : <Icons.ArrowsPointingOutSvg />}
											</div>
											{/* <div className="icon-btn icons"><Icons.ArrowsPointingOutSvg /></div> */}
											{/* <div className="icon-btn"><Icons.QrCodeSvg /></div>
											<div className="icon-btn"><Icons.EllipsisVerticalSvg /></div> */}

										</div>
									</div>
									{isContentVisible && (
										<div className="tab-content mt-0">
											{activeTab === 'tab1' && trades && trades.length > 0 && <div>
												<div className='watchlist-table tvg-data-table'>
													<div className="wt-wrapper">
														<table className="fixed-column-table">
															<thead>
																<tr>
																	<th>Symbol</th>
																	<th>Type</th>
																	<th>Volume, lot</th>
																	<th>Open price</th>
																	<th>Current price</th>
																	<th>T/P</th>
																	<th>S/L</th>
																	<th>Order</th>
																	<th>Open time</th>
																	<th>Swap, USD</th>
																	<th className='sticky-col last-col'>P/L, USD</th>
																	{/* <th></th> */}
																	{/* Add more headers as needed */}
																</tr>
															</thead>
															<tbody>
																{trades && trades.length > 0 && !isLoading &&
																	trades.map((trade, index) => (
																		<tr key={index}>
																			<td>
																				{getSymbolImage(trade.images)}
																				{trade.symbol ? formatSymbolName(trade.symbol.name) : ''}</td>
																			<td>
																				<div className='signal icons'>
																					<span className={'status'+(trade.side == 'buy' ? ' status-green' : ' status-red')}></span>
																					{trade.side ? trade.side : ''}
																				</div>
																			</td>
																			<td>
																				<div className='small-btn sell-btn'>
																					{trade.lots ? trade.lots : ''}
																				</div>
																			</td>
																			<td>
																				<div className='small-btn buy-btn'>
																					{trade.open_price ? trade.open_price : ''}
																				</div>
																			</td>
																			<td>
																				<div className='small-btn buy-btn'>
																					{socketData[trade.symbol.name] ? socketData[trade.symbol.name].ask : '-'}
																				</div>
																			</td>
																			<td>
																				<div className='small-btn buy-btn'>
																					{
																						trade.take_profit === 0
																						&&
																						<div className="add-link" onClick={() => handleShow(trade,'tp')}>
																									Add
																								</div>

																					}
																					{
																						trade.take_profit > 0
																						&&
																						<div className='add-link small-btn sell-btn' onClick={() => handleShow(trade,'tp')}>{trade.take_profit}</div>
																					}

																				</div>
																			</td>
																			<td>
																				<div className='small-btn buy-btn'>
																					{
																						trade.stop_loss === 0
																						&&

																						<div className="add-link" onClick={() => handleShow(trade,'sl')}>
																									Add
																								</div>

																					}
																					{
																						trade.stop_loss > 0
																						&&
																						<div className='add-link small-btn sell-btn' onClick={() => handleShow(trade,'sl')}>{trade.stop_loss}</div>
																					}
																				</div>
																			</td>
																			<td>
																				<div className='small-btn buy-btn'>
																					{trade.trade_id ? trade.trade_id : ''}
																				</div>
																			</td>
																			<td>
																				<div className='small-btn buy-btn'>
																					{trade.open_date_formattad ? trade.open_date_formattad : ''}
																				</div>
																			</td>
																			<td>
																				<div className='return'>
																					{trade.swap ? trade.swap : '-'}
																				</div>
																			</td>
																			{/* <td>
																				<div className='small-btn buy-btn'>0</div>
																			</td> */}

																			<td className='sticky-col last-col'>
																				<div className='small-btn buy-btn'>0</div>
																				<div className='icons delet' onClick={() => closeTrade(trade.id)}>
																					<Icons.XCircleSvg />
																				</div>
																			</td>
																		</tr>
																	))
																}


															</tbody>
														</table>
													</div>
												</div>
											</div>}
											{activeTab === 'tab1' && (!trades || trades.length == 0) && <div>
												<EmptyBox className='empty-div-bx' eh="No Data Found" esh="" />
											</div>}

											{activeTab === 'tab2' && <div>
												<EmptyBox className='empty-div-bx' eh="No Data Found" esh="" />
											</div>}
											{activeTab === 'tab3' && closeTrades && closeTrades.length > 0 && <div>
												<div className='watchlist-table tvg-data-table'>
													<div className="wt-wrapper">
														<table className="fixed-column-table">
															<thead>
																<tr>
																	<th>Symbol</th>
																	<th>Type</th>
																	<th>Volume, lot</th>
																	<th>Open price</th>
																	<th>Close price</th>
																	<th>T/P</th>
																	<th>S/L</th>
																	<th>Order</th>
																	<th>Open time</th>
																	<th>Swap, USD</th>
																	<th>P/L, USD</th>

																	{/* Add more headers as needed */}
																</tr>
															</thead>
															<tbody>
																{closeTrades && closeTrades.length > 0 && !isLoading &&
																	closeTrades.map((trade, index) => (
																		<tr key={index}>
																			<td>
																				{getSymbolImage(trade.images)}
																				{trade.symbol ? formatSymbolName(trade.symbol.name) : ''}</td>
																			<td>
																				<div className='signal icons'>
																					<span className={'status'+(trade.side == 'buy' ? ' status-green' : ' status-red')}></span>
																					{trade.side ? trade.side : ''}
																				</div>
																			</td>
																			<td>
																				<div className='small-btn sell-btn'>
																					{trade.lots ? trade.lots : ''}
																				</div>
																			</td>
																			<td>
																				<div className='small-btn buy-btn'>
																					{trade.open_price ? trade.open_price : ''}
																				</div>
																			</td>
																			<td>
																				<div className='small-btn buy-btn'>
																					{trade.close_price ? trade.close_price : '-'}
																				</div>
																			</td>
																			<td>
																				<div className='small-btn buy-btn'>
																					{
																						trade.take_profit && trade.take_profit > 0
																						&&
																						<div className='small-btn sell-btn'>{trade.take_profit}</div>
																					}

																				</div>
																			</td>
																			<td>
																				<div className='small-btn buy-btn'>
																					{
																						trade.stop_loss && trade.stop_loss > 0
																						&&
																						<div className='small-btn sell-btn'>
																							{trade.stop_loss}</div>
																					}
																				</div>
																			</td>
																			<td>
																				<div className='small-btn buy-btn'>
																					{trade.trade_id ? trade.trade_id : ''}
																				</div>
																			</td>
																			<td>
																				<div className='small-btn buy-btn'>
																					{trade.open_date_formattad ? trade.open_date_formattad : ''}
																				</div>
																			</td>
																			<td>
																				<div className='return'>
																					{trade.swap ? trade.swap : '-'}
																				</div>
																			</td>
																			<td>
																				{trade.profit > 0 &&
																					<div className='pl-usd status-green-label'>+{trade.profit}</div>
																				}
																				{trade.loss > 0 &&
																					<div className='pl-usd status-red-label'>-{trade.loss}</div>
																				}
																				{trade.profit === 0 && trade.loss && trade.loss === 0 &&
																					<div className='small-btn buy-btn'>0</div>
																				}
																			</td>

																		</tr>
																	))
																}
															</tbody>
														</table>
													</div>
												</div>
											</div>}
											{activeTab === 'tab3' && (!closeTrades || closeTrades.length == 0) && <div>
												<EmptyBox className='empty-div-bx' eh="No Data Found" esh="" />
											</div>}
										</div>
									)}
								</div>
							</div>
						</div>
						{/* --- */}


						{/* ---- */}
						<div className="tvg-r-right common-scrollbar-hide desktop">

							{/*-- market close information start--*/}
							{(!socketData[symbolTitle]) && 

								<div className='marketcloseinfo'>
									<div className='mtc-info'>
										<div className='mci-status icons'><Icons.NoSymbolSvg />Market is closed</div>	
										{/* <div className='mci-detail'>It will open on Jul 29, 5:00 AM.</div>	 */}
									</div>	
								</div>
							}
							{/*-- market close information End--*/}

							{/* current-panel-name */}
							<div className="current-panel-name">
								{/* <img src={Img.gbpusd} alt='' /> */}
								{formatSymbolName(symbolTitle)}
							</div>


							{/* buy-sell-btns */}
							<div className="buy-sell-btns">
								<div className={`${side == 'sell' ? 'bsb-selected' : ''} bsb-item sell-btn`} onClick={() => handleSideClick('sell')}>
									<div className="bsb-label">Sell</div>
									<div className="bsb-pricepips">
										<span>{socketData[symbolTitle] ? socketData[symbolTitle].bid : ''}</span>
										{/* <span>{socketData[symbolTitle] ? getFirstTwoAfterDecimal(socketData[symbolTitle].ask) : ''}</span>
										<span>{socketData[symbolTitle] ? getThirdAfterDecimal(socketData[symbolTitle].ask) : ''}</span> */}
									</div>
								</div>
								<div className={`${side == 'buy' ? 'bsb-selected' : ''} bsb-item buy-btn`} onClick={() => handleSideClick('buy')}>
									<div className="bsb-label">Buy</div>
									<div className="bsb-pricepips">
										<span>{socketData[symbolTitle] ? socketData[symbolTitle].ask : ''}</span>
										{/* <span>{socketData[symbolTitle] ? getFirstTwoAfterDecimal(socketData[symbolTitle].bid) : ''}</span>
										<span>{socketData[symbolTitle] ? getThirdAfterDecimal(socketData[symbolTitle].bid) : ''}</span> */}
									</div>
								</div>
								<div className="pips-spread-bx">45:100</div>
							</div>


							{/* buy-sell-percent */}
							<div className="buy-sell-percent">
								<div className="bs-percent sell-percent">54%</div>
								<div className="bs-percent-bar">
									<div className="bs-percent-inner" style={{ width: "54%" }}></div>
								</div>
								<div className="bs-percent buy-percent">46%</div>
							</div>


							{/* PanelCounter */}
							<PanelCounter
								isShow={true}
								className=" "
								placeholder="Not set"
								Label="lots"
								inputValue={lots}
								// dropdownItems={["Volume in lots"]}
								isDrop={false}
								error={false}
								errorMeg="lots is required"
								onValueChange={handleLotChange}
							/>


							{/* Panel Counter Pending */}
							<div className="other-panel-item">
								<div className="panel-switche"><CommonSwitche isToggle={showPending} onToggle={setShowPending} />Pending</div>
								<PanelCounter
									isShow={showPending}
									className="mt-1"
									placeholder="Not set"
									Label={showPending && socketData[symbolTitle] && (socketData[symbolTitle].ask < pending) ? "STOP" : "LIMIT"}
									inputValue={pending}
									tags={[]}
									isDrop={false}
									error={false}
									errorMeg="limit is required"
									onValueChange={handlePendingChange}
								/>
							</div>


							{/* Panel Counter take profit */}
							<div className="other-panel-item">
								<div className="panel-switche"><CommonSwitche isToggle={showTakeProfit} onToggle={setShowTakeProfit} />Take Profit</div>
								<PanelCounter
									isShow={showTakeProfit}
									className="mt-1"
									placeholder="Not set"
									Label="Price"
									inputValue={tp}
									dropdownItems={[]}
									tags={[]}
									isDrop={false}
									error={((showTakeProfit && (showPending ? pending : socketData[symbolTitle])) && (showPending ? pending : socketData[symbolTitle].ask) > tp) ? true : false}
									errorMeg={"Min " + (showTakeProfit && (showPending ? pending : socketData[symbolTitle]?.ask))}
									onValueChange={handleTpChange}
								/>
							</div>


							{/* Panel Counter stop loss */}
							<div className="other-panel-item">
								<div className="panel-switche"><CommonSwitche isToggle={showStopLoss} onToggle={setShowStopLoss} />Stop Loss</div>
								<PanelCounter
									isShow={showStopLoss}
									className="mt-1"
									placeholder="Not set"
									Label="Price"
									inputValue={sl}
									dropdownItems={[]}
									tags={[]}
									isDrop={false}
									error={((showStopLoss && (showPending ? pending : socketData[symbolTitle])) && (showPending ? pending : socketData[symbolTitle].bid) > tp) ? true : false}
									errorMeg={"Max " + (showStopLoss && (showPending ? pending : socketData[symbolTitle]?.bid))}
									onValueChange={handleSlChange}
								/>
							</div>


							{/* buy-sell-confirmation */}
							<div className="buy-sell-confirmation">
								{(side == null && !isLoading) && <div className="bs-choose-btn">Please choose Buy / Sell</div>}
								{(side && side == 'sell' && !isLoading) && <div className="bs-confirmation-btn sell-confirmation" onClick={handleSubmit}>Confirm Sell {lots} lots</div>}
								{(side && side == 'buy' && !isLoading) && <div className="bs-confirmation-btn buy-confirmation" onClick={handleSubmit}>Confirm Buy {lots} lots</div>
								}
								{(side && (side == 'sell' || side == 'buy') && !isLoading) && <div className="bs-cancel-btn" onClick={handleCancelClick}>Cancel</div>}
								{isLoading && side == 'sell' && <div className="bs-confirmation-btn sell-confirmation">
									Please wait...
								</div>
								}
								{isLoading && side == 'buy' && <div className="bs-confirmation-btn buy-confirmation">
									Please wait....
								</div>
								}

							</div>


							{/* buy-sell-details */}
							<div className="buy-sell-details">
								<div className="bs-detail-item">
									<div>Lots:</div>
									<div>00.8</div>
								</div>
								<div className="bs-detail-item">
									<div>Currency:</div>
									<div>801997 USD</div>
								</div>
								<div className="bs-detail-item">
									<div>Units:</div>
									<div>80000 EUR</div>
								</div>
								<div className="bs-detail-item">
									<div>Margin:</div>
									<div>4.35 USD</div>
								</div>
								<div className="bs-detail-item">
									<div>Pip Value:</div>
									<div>0.80 USD</div>
								</div>
							</div>



						</div>

						<div className="tvg-r-right common-scrollbar-hide mobile">

							{/* current-panel-name */}
							<div className="current-panel-name">
								{/* <img src={Img.unitedkingdom} alt='' /> */}
								{formatSymbolName(symbolTitle)}
							</div>


							{/* buy-sell-btns */}
							<div className="buy-sell-btns">
								<div className={`${side == 'sell' ? 'bsb-selected' : ''} bsb-item sell-btn`} onClick={() => openModal('sell')}>
									<div className="bsb-label">Sell</div>
									<div className="bsb-pricepips">
										<span>{socketData[symbolTitle] ? socketData[symbolTitle].ask : ''}</span>
										{/* <span>30</span>
										<span>7</span> */}
									</div>
								</div>
								<div className={`${side == 'buy' ? 'bsb-selected' : ''} bsb-item buy-btn`} onClick={() => openModal('buy')}>
									<div className="bsb-label">Buy</div>
									<div className="bsb-pricepips">
										<span>{socketData[symbolTitle] ? socketData[symbolTitle].bid : ''}</span>
										{/* <span>30</span>
										<span>8</span> */}
									</div>
								</div>
								<div className="pips-spread-bx">45:100</div>
							</div>

							{/* buy-sell-percent */}
							<div className="buy-sell-percent">
								<div className="bs-percent sell-percent">54%</div>
								<div className="bs-percent-bar">
									<div className="bs-percent-inner" style={{ width: "54%" }}></div>
								</div>
								<div className="bs-percent buy-percent">46%</div>
							</div>
						</div>
						{/* ---- */}

					</div>
					{/* --- */}

				</div>
				{/* ---  */}

			</div>
			{/* --- Trading terminal body end --- */}


			{/* buy-sell-popup */}
			<div className='buy-sell-popup'>
				{isModalOpen && (
					<div className="modal-backdrop">
						<div className="modal-content">
							<div className='modal-header'>
								<button className="close-button" onClick={closeModal}>
									&times;
								</button>

								<div className='bsp-header'>
									<div className='bsp-left'>
										{/* <span className='icons'>
											<img src={Img.gbpusd} alt="gbpusd" />
										</span> */}
										{formatSymbolName(symbolTitle)}
									</div>

									<div className='bsp-right'>
										<span>{account && account.formatted_available_balance}</span>
										<span>{account && account.currency}</span>
										{/* <span className='status-green-label tatus-red-label'>USD</span> */}
										<span className='status-green-label'>{account && account.mt5_type}</span>
									</div>

								</div>

							</div>
							<div className='bs-detail'>
								<div className="buy-sell-btns">
									<div className={`${side == 'sell' ? 'bsb-selected' : ''} bsb-item sell-btn`} onClick={() => handleSideClick('sell')}>
										<div className="bsb-label">Sell</div>
										<div className="bsb-pricepips">
											<span>{socketData[symbolTitle] ? socketData[symbolTitle].bid : ''}</span>
											{/* <span>30</span>
											<span>7</span> */}
										</div>
									</div>
									<div className={`${side == 'buy' ? 'bsb-selected' : ''} bsb-item buy-btn`} onClick={() => handleSideClick('buy')}>
										<div className="bsb-label">Buy</div>
										<div className="bsb-pricepips">
											<span>{socketData[symbolTitle] ? socketData[symbolTitle].ask : ''}</span>
											{/* <span>30</span>
											<span>8</span> */}
										</div>
									</div>
									<div className="pips-spread-bx">45:100</div>
								</div>


								{/* buy-sell-percent */}
								<div className="buy-sell-percent">
									<div className="bs-percent sell-percent">54%</div>
									<div className="bs-percent-bar">
										<div className="bs-percent-inner" style={{ width: "54%" }}></div>
									</div>
									<div className="bs-percent buy-percent">46%</div>
								</div>


								{/* PanelCounter */}
								<PanelCounter
									isShow={true}
									className=" "
									placeholder="Not set"
									Label="lots"
									inputValue={lots}
									dropdownItems={["Volume in lots"]}
									isDrop={true}
									error={false}
									errorMeg="lots is required"
									onValueChange={handleLotChange}
								/>

								{/* buy-sell-details */}
								<div className="buy-sell-details">
									<div className="bs-detail-item">
										<div>Lots:</div>
										<div>00.8</div>
									</div>
									<div className="bs-detail-item">
										<div>Currency:</div>
										<div>801997 USD</div>
									</div>
									<div className="bs-detail-item">
										<div>Units:</div>
										<div>80000 EUR</div>
									</div>
									<div className="bs-detail-item">
										<div>Margin:</div>
										<div>4.35 USD</div>
									</div>
									<div className="bs-detail-item">
										<div>Pip Value:</div>
										<div>0.80 USD</div>
									</div>
								</div>

								{/* Panel Counter Pending */}
								<div className="other-panel-item">
									<div className="panel-switche"><CommonSwitche isToggle={showPending} onToggle={setShowPending} />Pending</div>
									<PanelCounter
										isShow={showPending}
										className="mt-1"
										placeholder="Not set"
										Label={showPending && socketData[symbolTitle] && (socketData[symbolTitle].ask < pending) ? "STOP" : "LIMIT"}
										inputValue={pending}
										tags={[]}
										isDrop={false}
										error={false}
										errorMeg="limit is required"
										onValueChange={handlePendingChange}
									/>
								</div>


								{/* Panel Counter take profit */}
								<div className="other-panel-item">
									<div className="panel-switche"><CommonSwitche isToggle={showTakeProfit} onToggle={setShowTakeProfit} />Take Profit</div>
									<PanelCounter
										isShow={showTakeProfit}
										className="mt-1"
										placeholder="Not set"
										Label="price"
										inputValue={tp}
										dropdownItems={[]}
										tags={[]}
										isDrop={true}
										error={(showTakeProfit && socketData[symbolTitle] && socketData[symbolTitle].ask > tp) ? true : false}
										errorMeg={"Min "+(showTakeProfit && socketData[symbolTitle] ? socketData[symbolTitle].ask : "")}
										onValueChange={handleTpChange}
									/>
								</div>


								{/* Panel Counter stop loss */}
								<div className="other-panel-item">
									<div className="panel-switche"><CommonSwitche isToggle={showStopLoss} onToggle={setShowStopLoss} />Stop Loss</div>
									<PanelCounter
										isShow={showStopLoss}
										className="mt-1"
										placeholder="Not set"
										Label="price"
										dropdownItems={[]}
										tags={[]}
										inputValue={sl}
										isDrop={true}
										error={(showStopLoss && socketData[symbolTitle] && socketData[symbolTitle].bid < sl) ? true : false}
										errorMeg={"Max "+(showStopLoss && socketData[symbolTitle] ? socketData[symbolTitle].bid : "")}
										onValueChange={handleSlChange}
									/>
								</div>


								{/* buy-sell-confirmation */}
								<div className="buy-sell-confirmation">
									{(side == null && !isLoading) && <div className="bs-choose-btn">Please choose Buy / Sell</div>}
									{(side && side == 'sell' && !isLoading) && <div className="bs-confirmation-btn sell-confirmation" onClick={handleSubmit}>Confirm Sell {lots} lots</div>}
									{(side && side == 'buy' && !isLoading) && <div className="bs-confirmation-btn buy-confirmation" onClick={handleSubmit}>Confirm Buy {lots} lots</div>
									}
									{(side && (side == 'sell' || side == 'buy') && !isLoading) && <div className="bs-cancel-btn" onClick={handleCancelClick}>Cancel</div>}
									{isLoading && side == 'sell' && <div className="bs-confirmation-btn sell-confirmation">
										Please wait...
									</div>
									}
									{isLoading && side == 'buy' && <div className="bs-confirmation-btn buy-confirmation">
										Please wait....
									</div>
									}
								</div>


							</div>
						</div>
					</div>
				)}
			</div>

			{/* --- buy-sell detail modal --- */}
			<div className='custome-popup buy-sell-popup buy-sell-detail'>
				{isModalOpen01 && (
					<div className="modal-backdrop">
						<div className="modal-content">
							<div className='modal-header'>
								<button className="close-button" onClick={closeModal01}>
									&times;
								</button>

								<div className='tvg-tab-section'>
									<div className="tabs tab-style-two">
										<div className='tvg-tab-inner'>
											<div className="tab-list">
												<button
													className={`tab ${activeTab === 'tab1' ? 'active' : ''}`}
													onClick={() => handleTabClick('tab1')}
												>
													Open
													{/* {badgeCounts.tab1 > 0 && <span className="badge">{badgeCounts.tab1}</span>} */}
												</button>
												<button
													className={`tab ${activeTab === 'tab3' ? 'active' : ''}`}
													onClick={() => handleTabClick('tab3')}
												>
													Close
													{/* {badgeCounts.tab3 > 0 && <span className="badge">{badgeCounts.tab3}</span>} */}
												</button>


											</div>
											{/* <div className='tab-icon'>
											<div className="icon-btn icons"><Icons.ArrowsPointingOutSvg /></div>
											<div className="icon-btn"><Icons.QrCodeSvg /></div>
											<div className="icon-btn"><Icons.EllipsisVerticalSvg /></div>
										</div> */}
										</div>
									</div>
								</div>
							</div>
							<div className='tvg-tab-section'>
								<div className="tabs tab-style-two">
									{/* <div className='tvg-tab-inner'>
										<div className="tab-list">
											<button
												className={`tab ${activeTab === 'tab1' ? 'active' : ''}`}
												onClick={() => handleTabClick('tab1')}
											>
												Open
												{badgeCounts.tab1 > 0 && <span className="badge">{badgeCounts.tab1}</span>}
											</button>
											<button
												className={`tab ${activeTab === 'tab2' ? 'active' : ''}`}
												onClick={() => handleTabClick('tab2')}
											>
												GBP/USD
												{badgeCounts.tab2 > 0 && <span className="badge">{badgeCounts.tab2}</span>}
											</button>
											<button
												className={`tab ${activeTab === 'tab3' ? 'active' : ''}`}
												onClick={() => handleTabClick('tab3')}
											>
												Close
												{badgeCounts.tab3 > 0 && <span className="badge">{badgeCounts.tab3}</span>}
											</button>


										</div>
										<div className='tab-icon'>

											<div className="icon-btn icons"><Icons.ArrowsPointingOutSvg /></div>
											<div className="icon-btn"><Icons.QrCodeSvg /></div>
											<div className="icon-btn"><Icons.EllipsisVerticalSvg /></div>

										</div>
									</div> */}

									<div className="tab-content mt-0">
										{activeTab === 'tab1' && trades && trades.length > 0 && <div>
											<div className='watchlist-table tvg-data-table'>
												<div className="wt-wrapper">
													<table className="fixed-column-table">
														<thead>
															<tr>
																<th>Symbol</th>
																<th>Type</th>
																<th>Volume, lot</th>
																<th>Open price</th>
																<th>Current price</th>
																<th>T/P</th>
																<th>S/L</th>
																<th>Order</th>
																<th>Open time</th>
																<th>Swap, USD</th>
																<th>P/L, USD</th>
																<th></th>
																{/* Add more headers as needed */}
															</tr>
														</thead>
														<tbody>
															{trades && trades.length > 0 && !isLoading &&
																trades.map((trade, index) => (
																	<tr key={index}>
																		<td>
																			{/* <span className='icons pe-2'> */}
																				{/* <Icons.FlagSvg /> */}
																				{/* <img src={Img.gbpusd} alt="gbpusd" />
																			</span> */}
																			{getSymbolImage(trade.images)}
																			{trade.symbol ? formatSymbolName(trade.symbol.name) : ''}</td>
																		<td>
																			<div className='signal icons'>
																				<span className={'status'+(trade.side == 'buy' ? ' status-green' : ' status-red')}></span>
																				{trade.side ? trade.side : ''}
																			</div>
																		</td>
																		<td>
																			<div className='small-btn sell-btn'>
																				{trade.lots ? trade.lots : ''}
																			</div>
																		</td>
																		<td>
																			<div className='small-btn buy-btn'>
																				{trade.open_price ? trade.open_price : ''}
																			</div>
																		</td>
																		<td>
																			<div className='small-btn buy-btn'>
																			{socketData[trade.symbol.name] ? socketData[trade.symbol.name].ask : '-'}
																			</div>
																		</td>
																		<td>
																			<div className='small-btn buy-btn'>
																				{
																					trade.take_profit === 0
																					&&
																					<div className="add-link" onClick={() => handleShow(trade,'tp')}>
																								Add
																							</div>

																				}
																				{
																					trade.take_profit > 0
																					&&
																					<div className='add-link small-btn sell-btn' onClick={() => handleShow(trade,'tp')}>{trade.take_profit}</div>
																				}

																			</div>
																		</td>
																		<td>
																			<div className='small-btn buy-btn'>
																				{
																					trade.stop_loss === 0
																					&&

																					<div className="add-link" onClick={() => handleShow(trade,'sl')}>
																								Add
																							</div>

																				}
																				{
																					trade.stop_loss > 0
																					&&
																					<div className='small-btn sell-btn add-link' onClick={() => handleShow(trade,'sl')}>{trade.stop_loss}</div>
																				}
																			</div>
																		</td>
																		<td>
																			<div className='small-btn buy-btn'>
																				{trade.trade_id ? trade.trade_id : ''}
																			</div>
																		</td>
																		<td>
																			<div className='small-btn buy-btn'>
																				{trade.open_date_formattad ? trade.open_date_formattad : ''}
																			</div>
																		</td>
																		<td>
																			<div className='return'>
																				{trade.swap ? trade.swap : '-'}
																			</div>
																		</td>
																		<td>
																			<div className='small-btn buy-btn'>0</div>
																		</td>

																		<td>
																			<div className='icons delet' onClick={() => closeTrade(trade.id)}>
																				<Icons.XCircleSvg />
																			</div>
																		</td>
																	</tr>
																))
															}
														</tbody>
													</table>
												</div>
											</div>
										</div>}
										{activeTab === 'tab1' && (!trades || trades.length == 0) && <div>
											<EmptyBox className='empty-div-bx' eh="No Data Found" esh="" />
										</div>}

										{activeTab === 'tab2' && <div>
											<EmptyBox className='empty-div-bx' eh="No Data Found" esh="" />
										</div>}
										{activeTab === 'tab3' && closeTrades && closeTrades.length > 0 && <div>
											<div className='watchlist-table tvg-data-table'>
												<div className="wt-wrapper">
													<table className="fixed-column-table">
														<thead>
															<tr>
																<th>Symbol</th>
																<th>Type</th>
																<th>Volume, lot</th>
																<th>Open price</th>
																<th>Close price</th>
																<th>T/P</th>
																<th>S/L</th>
																<th>Order</th>
																<th>Open time</th>
																<th>Swap, USD</th>
																<th>P/L, USD</th>

																{/* Add more headers as needed */}
															</tr>
														</thead>
														<tbody>
															{closeTrades && closeTrades.length > 0 && !isLoading &&
																closeTrades.map((trade, index) => (
																	<tr key={index}>
																		<td className='normal-col'>
																			{getSymbolImage(trade.images)}
																			{trade.symbol ? formatSymbolName(trade.symbol.name) : ''}</td>
																		<td>
																			<div className='signal icons'>
																				<span className={'status'+(trade.side == 'buy' ? ' status-green' : ' status-red')}></span>
																				{trade.side ? trade.side : ''}
																			</div>
																		</td>
																		<td>
																			<div className='small-btn sell-btn'>
																				{trade.lots ? trade.lots : ''}
																			</div>
																		</td>
																		<td>
																			<div className='small-btn buy-btn'>
																				{trade.open_price ? trade.open_price : ''}
																			</div>
																		</td>
																		<td>
																			<div className='small-btn buy-btn'>
																				{trade.close_price ? trade.close_price : '-'}
																			</div>
																		</td>
																		<td>
																			<div className='small-btn buy-btn'>
																				{
																					trade.take_profit && trade.take_profit > 0
																					&&
																					<div className='small-btn sell-btn'>{trade.take_profit}</div>
																				}

																			</div>
																		</td>
																		<td>
																			<div className='small-btn buy-btn'>
																				{
																					trade.stop_loss && trade.stop_loss > 0
																					&&
																					<div className='small-btn sell-btn'>
																						{trade.stop_loss}</div>
																				}
																			</div>
																		</td>
																		<td>
																			<div className='small-btn buy-btn'>
																				{trade.trade_id ? trade.trade_id : ''}
																			</div>
																		</td>
																		<td>
																			<div className='small-btn buy-btn'>
																				{trade.open_date_formattad ? trade.open_date_formattad : ''}
																			</div>
																		</td>
																		<td>
																			<div className='return'>
																				{trade.swap ? trade.swap : '-'}
																			</div>
																		</td>
																		<td>
																			{trade.profit > 0 &&
																				<div className='pl-usd status-green-label'>+{trade.profit}</div>
																			}
																			{trade.loss > 0 &&
																				<div className='pl-usd status-red-label'>-{trade.loss}</div>
																			}
																			{trade.profit === 0 && trade.loss && trade.loss === 0 &&
																				<div className='small-btn buy-btn'>0</div>
																			}
																		</td>




																	</tr>
																))
															}
														</tbody>
													</table>
												</div>
											</div>
										</div>}
										{activeTab === 'tab3' && (!closeTrades || closeTrades.length == 0) && <div>
											<EmptyBox className='empty-div-bx' eh="No Data Found" esh="" />
										</div>}
									</div>
								</div>
							</div>
						</div>
					</div>

				)}
			</div>

			{/* --- plustab modal --- */}
			<div className='custome-popup buy-sell-popup watchlist-popup tab-w-popup'>
				{isModalOpen03 && (
					<div className="modal-backdrop">
						<div className="modal-content p-0">
							<div className='modal-header'>
								<button className="close-button" onClick={closeModal03}>
									&times;
								</button>
								<div className='tvb-header watchlist-header'>
									<div className='tvb-left-side'>
										Watchlist
									</div>
									<div className='tvb-right-side'>
										<div className='tvb-btn'>
											{/* <div className="icon-btn"><Icons.EllipsisVerticalSvg /></div> */}
											{/* <div className="icon-btn" ><Icons.QrCodeSvg /></div> */}
											{/* <div className="icon-btn text-2 icons" onClick={toggleSidebar}><Icons.ArrowsPointingOutSvg /></div> */}
										</div>
									</div>
								</div>

							</div>

							<div className="sidebar-content px-3 pt-3">
								<div className='wcp-filter'>
									<div className='row mb-3'>
										<div className='col-6'>
											<div className='tvb-search'>
												<div className="search-bar">
													<div className="icon">
														<Icons.SearchSvg />
													</div>
													<input
														type="text"
														className="search-input"
														value={searchModalTerm}
                										onChange={handleModalInputChange}
														placeholder="Search..."
													/>
												</div>
											</div>
										</div>
										<div className='col-6'>
											<div className='custom-input-dropdown'>
												<div className="input-dropdown">
													<div className="dropdown-container" onClick={toggleDropdown1}>
														<input
															type="text"
															value={selectedValue1}
															placeholder="Select an option"
															readOnly
														/>
														<span className={`down-arrow ${isOpen1 ? 'open' : ''}`}><Icons.DownSvg /></span>
													</div>
													{isOpen1 && (
														<div className="dropdown-menu">
															{options.map((option, index) => (
																<div
																	key={index}
																	className={"dropdown-item "+(selectedValue1 == option ? "active" : "")}
																	onClick={() => handleOptionClick1(option)}
																>
																	{option}
																</div>
															))}
														</div>
													)}
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className='watchlist-table'>
									<div className="wt-wrapper">
										<table className="fixed-column-table">

											<tbody>
											{(watchModalListSymbol && watchModalListSymbol.length > 0) &&
												watchModalListSymbol.map((watchListModalData, index) => {

													return(<tr key={index}>
														<td className="sticky-col" onClick={()=>addTopSymbol(watchListModalData.name,watchListModalData.id)}>
															{/* <span className='icons'> */}
																{/* <Icons.FlagSvg /> */}
																{/* <img src={Img.xauusd} alt="flag1" />
															</span> */}
															{getSymbolImage(watchListModalData.images)}
															{formatSymbolName(watchListModalData.name)}</td>

														<td>
															<div className='small-btn sell-btn '>
															{watchListModalData.description}
															</div>
														</td>

														<td>
															<div className={`rateing icons ${watchListModalData.is_fav == '1' ? 'active' : ''}`} onClick={()=>addFavSymbol(watchListModalData.id)}>
																<Icons.StarSvg />
															</div>
														</td>
													</tr>);
												})
											}
												


											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>

				)}
			</div>

			{/* --- plustab modal --- */}
			<div className='custome-popup buy-sell-popup watchlist-popup tab-w-popup'>
				{isModalOpen03 && (
					<div className="modal-backdrop">
						<div className="modal-content p-0">
							<div className='modal-header'>
								<button className="close-button" onClick={closeModal03}>
									&times;
								</button>
								<div className='tvb-header watchlist-header'>
									<div className='tvb-left-side'>
										Watchlist
									</div>
									<div className='tvb-right-side'>
										<div className='tvb-btn'>
											{/* <div className="icon-btn"><Icons.EllipsisVerticalSvg /></div> */}
											{/* <div className="icon-btn" ><Icons.QrCodeSvg /></div> */}
											{/* <div className="icon-btn text-2 icons" onClick={toggleSidebar}><Icons.ArrowsPointingOutSvg /></div> */}
										</div>
									</div>
								</div>

							</div>

							<div className="sidebar-content px-3 pt-3">
								<div className='wcp-filter'>
									<div className='row mb-3'>
										<div className='col-6'>
											<div className='tvb-search'>
												<div className="search-bar">
													<div className="icon">
														<Icons.SearchSvg />
													</div>
													<input
														type="text"
														className="search-input"
														placeholder="Search..."
													/>
												</div>
											</div>
										</div>
										<div className='col-6'>
											<div className='custom-input-dropdown'>
												<div className="input-dropdown">
													<div className="dropdown-container" onClick={toggleDropdown1}>
														<input
															type="text"
															value={selectedValue1}
															placeholder="Select an option"
															readOnly
														/>
														<span className={`down-arrow ${isOpen1 ? 'open' : ''}`}><Icons.DownSvg /></span>
													</div>
													{isOpen1 && (
														<div className="dropdown-menu">
															{options.map((option, index) => (
																<div
																	key={index}
																	className="dropdown-item"
																	onClick={() => handleOptionClick1(option)}
																>
																	{option}
																</div>
															))}
														</div>
													)}
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className='watchlist-table'>
									<div className="wt-wrapper">
										<table className="fixed-column-table">

											<tbody>
												<tr>
													<td className="sticky-col">
														<span className='twobox-img'>
															<img src={Img.flag1} alt="eurousd"  className='twb-one' />
															<img src={Img.flag2} alt="eurousd"  className='twb-two' />
														</span>
														XAU/USD</td>

													<td>
														<div className='small-btn sell-btn '>
															US Dollar vs New Israeli Shekel
														</div>
													</td>

													<td>
														<div className='rateing icons'>
															<Icons.StarSvg />
														</div>
													</td>
												</tr>
												<tr>
													<td className="sticky-col">
														<span className='twobox-img'>
															<img src={Img.flag1} alt="eurousd"  className='twb-one' />
															<img src={Img.flag2} alt="eurousd"  className='twb-two' />
														</span>
														XAU/USD</td>

													<td>
														<div className='small-btn sell-btn '>
															US Dollar vs New Israeli Shekel
														</div>
													</td>

													<td>
														<div className='rateing icons'>
															<Icons.StarSvg />
														</div>
													</td>
												</tr>

												<tr>
													<td className="sticky-col">
														<span className='twobox-img'>
															<img src={Img.flag1} alt="eurousd"  className='twb-one' />
															<img src={Img.flag2} alt="eurousd"  className='twb-two' />
														</span>
														XAU/USD</td>

													<td>
														<div className='small-btn sell-btn '>
															US Dollar vs New Israeli Shekel
														</div>
													</td>

													<td>
														<div className='rateing icons'>
															<Icons.StarSvg />
														</div>
													</td>
												</tr>

												<tr>
													<td className="sticky-col">
														<span className='twobox-img'>
															<img src={Img.flag1} alt="eurousd"  className='twb-one' />
															<img src={Img.flag2} alt="eurousd"  className='twb-two' />
														</span>
														XAU/USD</td>

													<td>
														<div className='small-btn sell-btn '>
															US Dollar vs New Israeli Shekel
														</div>
													</td>

													<td>
														<div className='rateing icons'>
															<Icons.StarSvg />
														</div>
													</td>
												</tr>

												<tr>
													<td className="sticky-col">
														<span className='onebox-img'>
															<img src={Img.flag1} alt="eurousd"  className='twb-one' />
															
														</span>
														XAU/USD</td>

													<td>
														<div className='small-btn sell-btn '>
															US Dollar vs New Israeli Shekel
														</div>
													</td>


													<td>
														<div className='rateing icons'>
															<Icons.StarSvg />
														</div>
													</td>
												</tr>

												<tr>
													<td className="sticky-col">
														<span className='twobox-img'>
															<img src={Img.flag1} alt="eurousd"  className='twb-one' />
															<img src={Img.flag2} alt="eurousd"  className='twb-two' />
														</span>
														XAU/USD</td>

													<td>
														<div className='small-btn sell-btn '>
															US Dollar vs New Israeli Shekel
														</div>
													</td>


													<td>
														<div className='rateing icons'>
															<Icons.StarSvg />
														</div>
													</td>
												</tr>


											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>

				)}
			</div>

			{/* --- watchlist modal --- */}
			<div className='custome-popup buy-sell-popup watchlist-popup'>
				{isModalOpen02 && (
					<div className="modal-backdrop">
						<div className="modal-content p-0">
							<div className='modal-header'>
								<button className="close-button" onClick={closeModal02}>
									&times;
								</button>
								<div className='tvb-header watchlist-header'>
									<div className='tvb-left-side'>
										Watchlist
									</div>
									<div className='tvb-right-side'>
										<div className='tvb-btn'>
											{/* <div className="icon-btn"><Icons.EllipsisVerticalSvg /></div> */}
											{/* <div className="icon-btn" ><Icons.QrCodeSvg /></div> */}
											<div className="icon-btn text-2 icons" onClick={toggleSidebar}><Icons.ArrowsPointingOutSvg /></div>
										</div>
									</div>
								</div>

							</div>

							<div className="sidebar-content px-3 pt-3">
								<div className='wcp-filter'>
									<div className='row mb-3'>
										<div className='col-6'>
											<div className='tvb-search'>
												<div className="search-bar">
													<div className="icon">
														<Icons.SearchSvg />
													</div>
													<input
														type="text"
														value={searchTerm}
                										onChange={handleInputChange}
														className="search-input"
														placeholder="Search..."
													/>
												</div>
											</div>
										</div>
										<div className='col-6'>
											<div className='custom-input-dropdown'>
												<div className="input-dropdown">
													<div className="dropdown-container" onClick={toggleDropdown}>
														<input
															type="text"
															value={selectedValue}
															placeholder="Select an option"
															readOnly
														/>
														<span className={`down-arrow ${isOpen ? 'open' : ''}`}><Icons.DownSvg /></span>
													</div>
													{isOpen && (
														<div className="dropdown-menu">
															{options.map((option, index) => (
																<div
																	key={index}
																	className="dropdown-item"
																	onClick={() => handleOptionClick(option)}
																>
																	{option}
																</div>
															))}
														</div>
													)}
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className='watchlist-table'>
									<div className="wt-wrapper">
										<table className="fixed-column-table">
											<thead>
												<tr>
													<th className="sticky-col">Symbol</th>
													{/* <th>Description</th> */}
													<th>Bid</th>
													<th>Ask</th>
													{/* <th>1D change</th> */}
													{/* <th>P/L, USD</th> */}
													{/* <th>1D change</th> */}
													{/* <th></th> */}
													<th></th>
													{/* Add more headers as needed */}
												</tr>
											</thead>
											<tbody>
											{(watchListSymbol && watchListSymbol.length > 0 && !isLoading) &&
												watchListSymbol.map((watchListData, index) => {
													const socketItem = socketData[watchListData.name] || {};
													const ask = socketItem.ask || '-';
													const bid = socketItem.bid || '-';
													// Get CSS classes based on current and previous values
													const { askClass, bidClass } = getCSSClasses(watchListData.name);
													return(<tr key={index}>
													<td className="sticky-col" onClick={()=>addTopSymbol(watchListData.name,watchListData.id)}>
														{getSymbolImage(watchListData.images)}

														{formatSymbolName(watchListData.name)}
													</td>
													{/* <div className='signal icons status-green'>
															<Icons.ArrowDownSvg />
														</div> */}
													{/* <td>
														{watchListData.description}
													</td> */}
													{/* <td>
														<div className='small-btn sell-btn status-red'>
															{watchListData.min}
														</div>
													</td>
													<td>
														<div className='small-btn buy-btn status-green'>
															{watchListData.max}
														</div>
													</td> */}
													<td>
														<div className={bidClass}>
															{bid}
														</div>
													</td>
													<td>
														<div className={askClass}>
															{ask}
														</div>
													</td>
													{/* <td>
														<div className='return icons status-green-label'>
															<Icons.ArrowDownSvg />
															<span className='statusgreen'>0.25%</span>
														</div>
													</td>
													<td>
														<div className='pl-usd'>-</div>
													</td>

													<td>
														<div className='pl-usd'>-</div>
													</td> */}

													<td>
														<div className={`rateing icons ${watchListData.is_fav == '1' ? 'active' : ''}`} onClick={()=>addFavSymbol(watchListData.id)}>
															<Icons.StarSvg />
														</div>
													</td>
												</tr>);
											})
												}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>

			{/* --- trading Info Modal --- */}
			<Modal
				className="zoom custom-content trading-info"
				centered
				show={isTradingvalue}
				onHide={handleTradingvalueClose}
			>
				<div className="custom-modal-header">
					<div className="cmh-lable">Wallet Details</div>
					<span className="close-icon" onClick={handleTradingvalueClose}>
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
							<path
								fill="none"
								stroke="currentColor"
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="32"
								d="M368 368L144 144M368 144L144 368"
							></path>
						</svg>
					</span>
				</div>

				<div className="custom-modal-body">
					<div className="ac-info-item">
						<div className="ai-inner">
							<div className="ai-i-info">Balance</div>
							<div className="ai-i-data">{account && account.formatted_available_balance} {account && account.currency}</div>
						</div>

						<div className="ai-inner">
							<div className="ai-i-info">Equity</div>
							<div className="ai-i-data">{account && account.equity} {account && account.currency}</div>

						</div>

						<div className="ai-inner">
							<div className="ai-i-info">Margin</div>
							<div className="ai-i-data">{account && account.margin ? (`${account.margin} ${account.currency}`) : ("-")}</div>

						</div>

						<div className="ai-inner">
							<div className="ai-i-info">Free Margin</div>
							<div className="ai-i-data">{account && account.freemargin ? (`${account.freemargin} ${account.currency}`) : ("-")}</div>

						</div>

						<div className="ai-inner">
							<div className="ai-i-info">Margin level</div>
							<div className="ai-i-data">{account && account.margin_level ? (`${account.margin_level} ${account.currency}`) : ("-")}</div>

						</div>
					</div>
					<div className="bs-choose-btn">
						
						<Link to="/mt5">Manage Accounts</Link>
					</div>
				</div>
			</Modal>
			<Modal
				className="zoom custom-content trading-info no-trading-account"
				centered
				show={showModel}
			>
				<div className='common-box-heading'>Do Not Have Any Account</div>
				<div className="custom-modal-body">
					<div className="bs-choose-btn"><Link to="/mt5">Manage Accounts</Link></div>
				</div>
			</Modal>


			{/*--Tablepopup modal End*/}	
			<Modal show={show} onHide={handleClose} className='addtrade-modal' centered>
				<Modal.Header className='trade-title' closeButton>

					<div className='trade-main'>
						<div className='trd-left'>
							<div className='trd-title'>
								<span className='twobox-img'>
									{/* <img src={Img.flag1} alt="eurousd" className='twb-one' />
									<img src={Img.flag2} alt="eurousd" className='twb-two' /> */}
								</span>{formatSymbolName(selectedSymbol)} <span className='trd-value'>{selectedLots} lots</span>
							</div>
							<div className='trd-value pt-1 ps-4'>
								<span className={selectedSide == 'sell' ? "status-red-label" : "status-green-label"}>{selectedSide}</span> at {selectedOpenPrice}
							</div>
						</div>
						<div className='trd-right'>
							{/* <div className='trd-value'><span className='status-green-label'>+ 1024.00 USD</span></div> */}
							<div className='trd-value pt-1 ps-3'>
								{socketData[selectedSymbol] ? socketData[selectedSymbol].bid : selectedClosePrice}
							</div>
						</div>
					</div>

				</Modal.Header>
				<Modal.Body>


					<div className="tab-navigation">
						<div
							className={`tab-item ${activeTabone === 'tabone' ? 'active' : ''}`}
							onClick={() => handleTabClickone('tabone')}
						>
							Modify
						</div>
						<div
							className={`tab-item ${activeTabone === 'tabtwo' ? 'active' : ''}`}
							onClick={() => handleTabClickone('tabtwo')}
						>
							Partial close
						</div>
					</div>

					<div className="tab-content">
						{activeTabone === 'tabone' && (
							<div className="tab-panel">
								<div className='panel-counter-section'>
									<div className='pcs-left'>
										<div className='label'>Take Profit</div>
										<PanelCounter
											isShow={true}
											className="mt-0"
											placeholder="Not set"
											Label="Price"
											inputValue={selectedTp}
											// dropdownItems={["Volume in lots", "Volume in currency", "Volume in units"]}
											isDrop={false}
											error={(selectedTp!='' && show && activeTabone === 'tabone' && socketData[selectedSymbol] && socketData[selectedSymbol].ask > selectedTp) ? true : false}
											errorMeg={"Min "+(selectedTp!='' && show && activeTabone === 'tabone' && socketData[selectedSymbol] ? socketData[selectedSymbol].ask : "")}
											onValueChange={handleSelectedTpChange}
										/>
										{/* <div className='panel-message pt-1'><span className='status-red-label'>Min 1.11467</span></div> */}
									</div>
									<div className='pcs-right'>
										<div className='label'>Stop Loss</div>
										<PanelCounter
											isShow={true}
											className="mt-0"
											placeholder="Not set"
											Label="Price"
											inputValue={selectedSl}
											// dropdownItems={["Volume in lots", "Volume in currency", "Volume in units"]}
											isDrop={false}
											error={(selectedSl!='' && show && activeTabone === 'tabone' && socketData[selectedSymbol] && socketData[selectedSymbol].bid < selectedSl) ? true : false}
											errorMeg={"Max "+(selectedSl!='' && show && activeTabone === 'tabone' ? socketData[selectedSymbol].bid : "")}
											onValueChange={handleSelectedSlChange}
										/>

										{/* <div className='trad-panel-message'>
											<div className='trad-pips panel-message'><span>+229.8 pips</span></div>
											<div className='trad-currancy panel-message'><span>+114.90 USD</span></div>
											<div className='trad-percantage panel-message'><span>+32.36 %</span></div>
										</div> */}
									</div>
								</div>

								<div className="buy-sell-confirmation modify-order buy-confirmation" onClick={updatePosition}>
									<div className="bs-choose-btn">Modify order</div>
								</div>
							</div>
						)}
						{activeTabone === 'tabtwo' && (
							<div className="tab-panel">

								<div className='pcs-left'>
									<div className='label'>Volume to close</div>
									<PanelCounter
										isShow={true}
										className="mt-0"
										placeholder="Not set"
										Label="Lots"
										inputValue={selectedLots}
										// dropdownItems={["Volume in lots", "Volume in currency", "Volume in units"]}
										isDrop={false}
										error={false}
										errorMeg="lots is required"
										onValueChange={handleSelectedLotChange}
									/>
									<div className='panel-message pt-1'><span className='status-red-label'>0.01 - {selectedLots}</span></div>
								</div>


								<div className="buy-sell-confirmation modify-order buy-confirmation" onClick={closeTrade}>
									<div className="bs-choose-btn">Close order</div>
								</div>
							</div>

						)}
					</div>


					{/* <div className='panel-counter-section'>
						<div className='pcs-left'>
						<PanelCounter
								isShow={true}
								className="mt-0"
								placeholder="Not set"
								Label="lots"
								dropdownItems={["Volume in lots", "Volume in currency", "Volume in units"]}
								isDrop={true}
								error={false}
								errorMeg="lots is required"
								onValueChange={handleLotChange}
							/>
						</div>
						<div className='pcs-right'>
						<PanelCounter
								isShow={true}
								className="mt-0"
								placeholder="Not set"
								Label="lots"
								dropdownItems={["Volume in lots", "Volume in currency", "Volume in units"]}
								isDrop={true}
								error={false}
								errorMeg="lots is required"
								onValueChange={handleLotChange}
							/>
						</div>
					</div> */}

				</Modal.Body>
			</Modal>
			{/*--Tablepopup modal End*/}

			{requestSubmitSuccess && <FlashMessage type="success" isvisible={true} message={requestSubmitSuccessMsg} />}
			{requestSubmitError && <FlashMessage type="error" isvisible={true} message={requestSubmitErrorMsg} />}
		</>
	);
}

//export default memo(TradingViewWidget);