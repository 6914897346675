import React, { useState, useEffect } from 'react';

// Icons
import Icons from '../../components/icons';

export default function SelectDropItem({ label, className, heading, options, onChange = null ,setHeadingAsText = false }) {
    const [isCommonDropActive, setIsCommonDropActive] = useState(false);
    const [selected, setSelected] = useState(heading);
    const handleCommonLeave = () => {
        setIsCommonDropActive(false);
    };

    const handleCommonToggle = () => {
        setIsCommonDropActive(!isCommonDropActive);
    }

    const handleOptionChange = (name,value) => {
        onChange(value)
        setSelected(name)
        setIsCommonDropActive(false);
    }

    return (
        <>

            {( label && <div className="sgr-outer-label">{label}</div> )}
            <div className={`common-drop-item ${className} ${isCommonDropActive ? 'active' : ''}`} onMouseLeave={handleCommonLeave}>
                <div className="selected-item si-48h" onClick={handleCommonToggle}>
                    <div className="si-data-bx">
                        <div className="si-d-heading">{setHeadingAsText ? heading : selected}</div>
                    </div>
                    <div className="si-down-icon"><Icons.DownSvg /></div>
                </div>
                <div className="common-drop-bx">
                    <div className="common-drop-inner">
                        {options && options.length > 0 && options.map((option, index) => (
                            <div key={index} className={`selecte-option-item ${option.name == heading ? 'selected' : ''}`}  onClick={()=>handleOptionChange(option.name,option.id)} >
                                <div className="soi-data-bx">
                                    <div className="soi-d-heading">{option.name ? option.name : ''}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}
