import React, { useState, useEffect, useRef } from 'react'
import {
    useAuth,
    getLoggedInUserData,
    getToken,
  } from "../context/AuthContext.js";
  import useApi from '../utility/apiCall';
  import API_URL from '../config/config';
  
  // Design Files
  import AnalyticsLayout from "../containers/PageAnalytics/AnalyticsLayout";
  import AccountDropItem from "./PageFunds/AccountDropItem";
  import FilterDropItem from "../components/Filters/FilterDropItem";
  
  // AccountDropItem demo img
  import walletIcon from "../img/icons/paymentmethod/wallet.png";
  import mt5 from "../img/icons/paymentmethod/mt5.webp";
  import EmptyBox from "../components/EmptyBox";
import Icons from '../components/icons';
import { Link } from 'react-router-dom';

export default function SummaryData() {
    const { apiCall } = useApi();
    const { state, dispatch } = useAuth();
    const token = state.token || "";
    const isInitialMount = useRef(true);
    const [isLoading, setIsLoading] = useState(false);
    const [walletOption, setWalletOption] = useState([]);
    const [selectedHeading, setSelectedHeading] = useState("");
    const [selectedId, setSelectedId] = useState(null);
    const [type, setType] = useState("netProfit");
    const [selectedFilter, setSelectedFilter] = useState("Last 7 Days");
    const [boxData, setBoxData] = useState(null);
    const [dataset, setDataset] = useState([]);
    const [chartFilter, setChartFilter] = useState("netProfit");
    const [chartLoader, setChartLoader] = useState(false);
    const [selectedWallet, setSelectedWallet] = useState(null);
    const optionss = [
        { img: mt5, heading: "#12587456215", selected: true },
        { img: walletIcon, heading: "#58755255455", selected: false },
      ];
    const lastDaysFilter = [
        "Last 7 Days",
        "Last 30 Days",
        "Last 90 Days",
        "Last 365 Days",
        "All"
      ];

      const DemoOnChange = (newSelectedFilter) => {
        setSelectedFilter(newSelectedFilter);
      };
      const getUserWallet = async () => {
        try {
          setIsLoading(true);
          const response = await apiCall(API_URL + "get-user-wallets-trade", {
            user_id: state.userData.id,
            token: token,
          });
          if (response.data.success == "0") {
            const wallets = response.data.data;
            setWalletOption(wallets);
            if (wallets.length > 0) {
              setSelectedHeading(wallets[0].account_number);
              setSelectedId(wallets[0].id);
              setSelectedWallet(wallets[0]);
            }
          }
          setIsLoading(false);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      useEffect(() => {
        getUserWallet();
      }, []);
      useEffect(() => {
        if (selectedId || selectedFilter) {
        //   setType("netProfit");
        //   setChartFilter("netProfit");
          getBoxData();
        //   getChartData();
        }
      }, [selectedId, selectedFilter]); 
    
      const getBoxData = async () => {
        try {
          setIsLoading(true);
          const response = await apiCall(API_URL + "get-analytics-data", {
            user_id: state.userData.id,
            user_wallet_id: selectedId,
            token: token,
            filter: selectedFilter,
            chartFilter:chartFilter
          });
          if (response.data.success == "0") {
            setBoxData(response.data.data);
            // if(chartFilter === "equity"){
            //   setDataset(response.data.data.equity_data);
            // }else{
            //   setDataset(response.data.data.trades);
            // }
          }
          setIsLoading(false);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      const handleSelectionChange = (selectedWalletId) => {
        const selectedWallet = walletOption.find(
          (wallet) => wallet.id === selectedWalletId
        );
        if (selectedWallet) {
          setSelectedHeading(selectedWallet.account_number);
          setSelectedId(selectedWalletId);
          setSelectedWallet(selectedWallet);
        } else {
          console.error("Wallet not found with the provided ID");
        }
      };
    return (
        <div className="common-box">
            <div class="common-box-heading"><Icons.PresentationChartBarSvg />Summary</div>
            <Link to="/analytics-summary" className="common-box-link">View Summary<Icons.RightSvg/></Link>
            <div className="row row-gap-4">

                {/* <div className="col-12">
                    <div className="d-flex flex-column flex-sm-row align-items-sm-end gap-3">
                        <FilterDropItem
                            label={selectedFilter}
                            groupName="status"
                            options={lastDaysFilter}
                            onChange={DemoOnChange}
                            selected={selectedFilter}
                        />
                        <FilterDropItem
                            label={selectedFilter}
                            groupName="status"
                            options={lastDaysFilter}
                            onChange={DemoOnChange}
                            selected={selectedFilter}
                        />
                    </div>
                </div> */}
                 {walletOption && walletOption.length > 0 && (
                    <div className="col-12">
                        <div className="d-flex flex-column flex-sm-row align-items-sm-end gap-3">
                        <div>
                            <AccountDropItem
                            className="mb-0"
                            label="Account"
                            img={selectedWallet ? selectedWallet : walletIcon}
                            selected={selectedWallet ? selectedWallet : walletIcon}
                            heading={selectedHeading}
                            options={walletOption}
                            onChange={handleSelectionChange}
                            />
                        </div>

                        <FilterDropItem
                            label={selectedFilter}
                            groupName="status"
                            options={lastDaysFilter}
                            onChange={DemoOnChange}
                            selected={selectedFilter}
                        />
                        </div>
                    </div>
                )}

                {!isLoading && (
                    <>
                        <div className="col-xl-3 col-md-6 col-12">
                            <div className="summary-data-box sdb-bg-black">
                                {/* <div className="sdb-item sdb-top-item">Net profit <div>0.00 USD</div></div>
                                <div className="sdb-item">Profit <div>0.00 USD</div></div>
                                <div className="sdb-item">Loss <div>0.00 USD</div></div> */}
                                <div className="sdb-item sdb-top-item">
                                    Net profit <div>{boxData ? boxData.net_profit.toFixed(2) : 0} USD</div>
                                </div>
                                <div className="sdb-item">
                                    Profit <div>{boxData ? boxData.total_profit.toFixed(2) : 0} USD</div>
                                </div>
                                <div className="sdb-item">
                                    Loss <div>{boxData ? boxData.total_loss.toFixed(2) : 0} USD</div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-3 col-md-6 col-12">
                            <div className="summary-data-box sdb-bg-black">
                                {/* <div className="sdb-item sdb-top-item">Closed trades <div>0.00 USD</div></div>
                                <div className="sdb-item">Profitable <div>0.00 USD</div></div>
                                <div className="sdb-item">Unprofitable <div>0.00 USD</div></div> */}
                                <div className="sdb-item sdb-top-item">
                                    Closed trades{" "}
                                    <div>{boxData ? boxData.net_close_profit.toFixed(2) : 0}</div>
                                </div>
                                <div className="sdb-item">
                                    Profitable{" "}
                                    <div>{boxData ? boxData.total_close_profit.toFixed(2) : 0}</div>
                                </div>
                                <div className="sdb-item">
                                    Unprofitable{" "}
                                    <div>{boxData ? boxData.total_close_loss.toFixed(2) : 0}</div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-3 col-md-6 col-12">
                            <div className="summary-data-box sdb-bg-black">
                                {/* <div className="sdb-item sdb-top-item">Trading volume <div>0.00 USD</div></div>
                                <div className="sdb-item">Lifetime <div>0.00 USD</div></div> */}
                                <div className="sdb-item sdb-top-item">
                                    Trading volume <div>{boxData ? boxData.trading_volume.toFixed(2) : 0}</div>
                                </div>
                                <div className="sdb-item">
                                    Lifetime <div>{boxData ? boxData.trading_volume.toFixed(2) : 0}</div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-3 col-md-6 col-12">
                            <div className="summary-data-box sdb-bg-black">
                                {/* <div className="sdb-item sdb-top-item">Equity <div>0.00 USD</div></div>
                                <div className="sdb-item">Current <div>0.00 USD</div></div> */}
                                <div className="sdb-item sdb-top-item">
                                    Equity <div>{boxData ? boxData.equity.toFixed(2) : 0} USD</div>
                                </div>
                                <div className="sdb-item">
                                    Current <div>{boxData ? boxData.equity.toFixed(2) : 0} USD</div>
                                </div>
                            </div>
                        </div>
                    </>
                )}

                {/*  */}
                {/* <div className="col-xl-3 col-md-6 col-12"><div className="skeletant-bx skeletant-design sh-137"></div></div>
                <div className="col-xl-3 col-md-6 col-12"><div className="skeletant-bx skeletant-design sh-137"></div></div>
                <div className="col-xl-3 col-md-6 col-12"><div className="skeletant-bx skeletant-design sh-137"></div></div>
                <div className="col-xl-3 col-md-6 col-12"><div className="skeletant-bx skeletant-design sh-137"></div></div> */}
                {/*  */}

            </div>
        </div>
    )
}
