import React from 'react'

// Design Files
import CommonLayoutHeader from './CommonLayoutHeader'
import CommonSideMenu from './CommonSideMenu'
import { useMenu } from '../../context/MenuContext';

export default function CommonLayout({ children, className, hide }) {

  const { isActive } = useMenu();

  if (hide) {
    return <>{children}</>; // Only render children if hide is true
  }

  return (
    <section className={`account-main-section maxwidth1920 ${isActive ? 'active' : ''}`}>
      {/* --- Account Left --- */}
      <CommonSideMenu />
      {/* --- Account Left --- */}

      {/* --- Account Right --- */}
      <div className={`account-content-area ${className}`}>
        <CommonLayoutHeader />
        {children}
      </div>
      {/* --- Account Right --- */}
    </section>
  )
}
