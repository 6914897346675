import React from 'react'
import { Link } from 'react-router-dom';

// Icon Files
import Icons from '../components/icons';

// Design Fils
import TransactionSortItem from './TransactionSortItem';
import EmptyBox from '../components/EmptyBox';

export default function TransactionSort({ className, heading, transactions=[] }) {
    //console.log(transactions)

    return (
        <div className={`common-box ${className}`}>
            <div className="common-box-heading"><Icons.TransactionsSvg/>{heading}</div>
            <Link to="" className="common-box-link">See History<Icons.RightSvg/></Link>

            <div className="row row-gap-3">
            {transactions.length > 0 &&
                transactions.map((transaction , index) => (
                <TransactionSortItem key={index} transaction={transaction}/>
                ))
            }

            {transactions.length == 0 && <EmptyBox 
                className="empty-div-bx" 
                eh="No Transactions found" 
                esh="Data is not available"
            /> 
            }
            </div>
        
        </div>
    )
}
