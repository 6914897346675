import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import API_URL from "../../config/config";
import useApi from "../../utility/apiCall";
import {
  useAuth,
  getLoggedInUserData,
  getToken,
} from "../../context/AuthContext";

import { Helmet } from "react-helmet-async";

// Design Files
import PageHeader from "./PageHeader";
import PageBackBtn from "./PageBackBtn";
import PageSocialLogin from "./PageSocialLogin";
import FlashMessage from "../../components/FlashMessage";

// Images Common File
import * as Img from "../../components/Img";

const hidepassSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
      />
    </svg>
  );
};

const viewpassSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
      />
    </svg>
  );
};

export default function PageLogin() {
  const { apiCall } = useApi();
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [twoFactor, setTwoFactor] = useState(false);
  const { dispatch, state } = useAuth();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // View passwords code
  const navigate = useNavigate();
  const [showPasswords, setShowPasswords] = useState([false, false, false]);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [loginSuccsess, setLoginSuccsess] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [loginErrorMsg, setLoginErrorMsg] = useState(false);
  const [otpError, setOtpError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userIdOtp, setUserIdOtp] = useState();
  const [timer, setTimer] = useState(30);
  const [sentOtp, setSentOtp] = useState(null);

  useEffect(() => {
    const loggedInUser = getLoggedInUserData();
    const isUserLoggedIn = !!loggedInUser;

    if (isUserLoggedIn) {
      setUserLoggedIn(true);
      navigate("/dashboard");
    }
    if (twoFactor) {
      setSteps("step2");
    }
  }, [state.userData]);

  const userSignIn = async () => {
    setLoginSuccsess(false);
    setLoginError(false);
    if (email && password) {
      setIsLoading(true);
      try {
        const response = await apiCall(
          API_URL + "login",
          {
            email: email,
            password: password,
            device_type:3
          },
          {},
          navigate
        );
        if (response.data.success == "0") {
          
          localStorage.setItem("userData", JSON.stringify(response.data.data));
          localStorage.setItem("token", response.data.data.token);
          dispatch({
            type: "LOGIN",
            payload: {
              user: response.data.data,
              token: response.data.data.token,
            },
          });
          setLoginSuccsess(true);
          setIsLoggedIn(true);
          setTimeout(() => {
            navigate("/dashboard");
          }, 1000);
        } else if (response.data.success == "2") {
          setLoginError(true);
          setLoginErrorMsg(response.data.data.error)
          setIsLoading(false);
        } else if (response.data.success == "3") {
          setTwoFactor(true);
          setSteps("step2");
          setUserIdOtp(response.data.data.id);
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } else {
      if (!email) {
        setEmailError(true);
      }
      if (!password) {
        setPasswordError(true);
      }
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent default form submission
      userSignIn(); // Call the sign-in function
    }
  };
  const handleOtpPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent default form submission
      twoFactorCheck(); // Call the sign-in function
    }
  };
  const twoFactorCheck = async () => {
    try {
      setIsLoading(true);
      const response = await apiCall(API_URL + "auth-verify-otp", {
        user_id: userIdOtp,
        otp: otp,
      });
      if (response.data.success == "0") {
        localStorage.setItem("userData", JSON.stringify(response.data.data));
        localStorage.setItem("token", response.data.data.token);
        dispatch({
          type: "LOGIN",
          payload: {
            user: response.data.data,
            token: response.data.data.token,
          },
        });
        setLoginSuccsess(true);
        setIsLoggedIn(true);
        setTimeout(() => {
          navigate("/dashboard");
        }, 1000);
      } else if (response.data.success == "2") {
        console.log(response.data.success);
        setOtpError(true);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const togglePasswordVisibility = (index) => {
    const updatedShowPasswords = [...showPasswords];
    updatedShowPasswords[index] = !updatedShowPasswords[index];
    setShowPasswords(updatedShowPasswords);
  };
  const handleResend = async () => {
    try {
      setIsLoading(true);
      const response = await apiCall(API_URL + "resend-auth-verify-otp", {
        email:email,
        password:password,
      });
      if (response.data.success == "3") {
        setSentOtp(true)
        setTimer(30)
      } else if (response.data.success == "2") {
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const [steps, setSteps] = useState("step1");
  const handlesetStep = (stepId) => {
    setSteps(stepId);
  };

  useEffect(() => {
    if (steps === "step2") {
      //setTimer(30); // Reset the timer to 30 when step2 starts
      const countdown = setTimeout(() => {
        if (timer > 0) {
          setTimer(timer - 1);
        } else {
          clearTimeout(countdown);
        }
      }, 1000);
    }
  }, [steps, timer]);

  //
  const [otp, setOtp] = useState(["", "", "", ""]);
  const inputRefs = [useRef(), useRef(), useRef(), useRef()];

  const handleChange = (e, index) => {
    const value = e.target.value;

    if (!isNaN(value) && value.length <= 1) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (index < otp.length - 1 && value !== "") {
        inputRefs[index + 1].current.focus();
      }
    } else if (value === "" && index > 0) {
      // If backspace is pressed and the input field is empty, move focus to the previous input
      inputRefs[index - 1].current.focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      e.preventDefault();
      if (index > 0) {
        // Move focus to the previous input
        inputRefs[index - 1].current.focus();
      }
      // Clear the current input
      const newOtp = [...otp];
      newOtp[index] = "";
      setOtp(newOtp);
    }
  };

  return (
    <>
      <Helmet>
        <title>Login | Winpro Fx</title>
      </Helmet>

      <PageHeader />

      {steps === "step2" ? (
        <PageBackBtn to={null} onClick={() => handlesetStep("step1")} />
      ) : null}

      <section className="lgh-wraper">
        <div className="container-lg cl-custome">
          {/* --- Step 1 start --- */}
          <div
            className={`signinup-screen ${steps === "step1" ? "" : "d-none"} `}
            id="step1"
          >
            {/* <Link to="/register" className="signinup-link" >Not with us? Sign up now!</Link> */}

            <div className="signinup-header">
              <div className="screen-hed">Sign In to Secure CRM</div>
              {/* <div className="screen-pera">to access your account</div> */}
            </div>

            <form className="signinup-width" onKeyPress={handleKeyPress}>
              <div className="row">
                <div className="col-12">
                  {/* error-group */}
                  <div
                    className={`signinup-group ${
                      emailError ? "error-group" : ""
                    }`}
                  >
                    <input
                      value={email}
                      type="text"
                      onChange={(e) => {
                        setEmailError(false);
                        setEmail(e.target.value);
                      }}
                      placeholder="Enter Email or Phone Number"
                    />
                    {/* <div className="group__label">Email</div> */}
                    {/* <div className="group__line"></div> */}
                    {emailError && (
                      <span className="form-error-msg">Email is required</span>
                    )}
                  </div>
                </div>

                {[0].map((index) => (
                  <div className={`col-12`} key={index}>
                    {/* error-group */}
                    <div
                      className={`signinup-group  sgr-icon ${
                        passwordError ? "error-group" : ""
                      }`}
                    >
                      <input
                        type={showPasswords[index] ? "text" : "password"}
                        value={password}
                        onChange={(e) => {
                          setPasswordError(false);
                          setPassword(e.target.value);
                        }}
                        placeholder="Password"
                      />
                      {/* <div className="group__label">Password</div> */}
                      {/* <div className="group__line"></div> */}
                      <span
                        className={`group_right_icon ${
                          showPasswords[index] ? "active" : ""
                        }`}
                        onClick={() => togglePasswordVisibility(index)}
                      >
                        {showPasswords[index] ? viewpassSvg() : hidepassSvg()}
                      </span>
                      {passwordError && (
                        <span className="form-error-msg">
                          Password is required
                        </span>
                      )}
                    </div>
                  </div>
                ))}

                <div className="col-12">
                  <div className="signinup-link-outer-bx">
                    <div className="signinup-link"> </div>
                    {/* <div className="signinup-link" >Scan to login</div> */}
                    <Link to="/restore" className="signinup-link-two">
                      Forgot Password?
                    </Link>
                  </div>
                </div>

                <div className="col-12">
                  {!isLoading && (
                    <button
                      type="button"
                      onClick={userSignIn}
                      className="common-submit-btn"
                    >
                      Sign in
                    </button>
                  )}

                  {/* <div onClick={() => handlesetStep('step2')} className="common-submit-btn">Continue</div> */}

                  {isLoading && (
                    <div className="lsb-loader-btn">
                      <img src={Img.loading} alt="" />
                      Please wait ....
                    </div>
                  )}
                </div>
                {loginSuccsess && (
                  <FlashMessage
                    type="success"
                    isvisible={true}
                    message="Login successfull"
                  />
                )}
                {loginError && (
                  <FlashMessage
                    type="error"
                    isvisible={true}
                    message={loginErrorMsg}
                  />
                )}
                <div className="col-12">
                  <div className="signinup-link-outer-bx slob-fdc mb-0">
                    If you don’t have an account you can
                    <Link to="/register" className="signinup-link-two">
                      Register Here!
                    </Link>
                  </div>
                </div>
              </div>
            </form>

            {/* <PageSocialLogin /> */}
          </div>
          {/* --- Step 1 end --- */}

          {/* --- Step 2 start --- */}
          <div
            className={`signinup-screen ${steps === "step2" ? "" : "d-none"} `}
            id="step2"
          >
            <div className="signinup-header">
              <div className="screen-hed">Enter your Security code</div>
              <div className="screen-pera">
                We texted your code to{" "}
                <span
                  className="sp-otp-link"
                  onClick={() => handlesetStep("step1")}
                >
                  {email}
                </span>
              </div>
            </div>

            <form className="signinup-width" onKeyPress={handleOtpPress}>
              <div className="row">
                <div className="col-12">
                  <div className="signinup-group otp-group">
                    {otp.map((digit, index) => (
                      <>
                        <input
                          key={index}
                          inputMode="numeric"
                          type="text"
                          maxLength="1"
                          placeholder=" "
                          value={digit}
                          onChange={(e) => handleChange(e, index)}
                          onKeyDown={(e) => handleKeyDown(e, index)}
                          ref={inputRefs[index]}
                        />
                      </>
                    ))}
                  </div>
                </div>

                <div className="col-12">
                  <div className="otp-resend-bx">
                    <div>Have Not Received Code</div>
                    {timer > 0 ? (
                      <div>
                        Resend In <span>{timer}s</span>
                      </div>
                    ) : (
                      <div><div className="reotp-btn" onClick={handleResend}>Resend</div></div>
                    )}
                    </div>
                </div>

                <div className="col-12">
                  {!isLoading && (
                    <div
                      className="common-submit-btn"
                      onClick={() => twoFactorCheck()}
                    >
                      Continue
                    </div>
                  )}
                  {isLoading && (
                    <div className="lsb-loader-btn">
                      <img src={Img.loading} alt="" />
                      Please wait ....
                    </div>
                  )}
                </div>
                {otpError && (
                  <FlashMessage
                    type="error"
                    isvisible={true}
                    message="Invalid OTP or expired"
                  />
                )}
                {sentOtp && (
                  <FlashMessage
                    type="success"
                    isvisible={true}
                    message="OTP sent Successfull to your regester email"
                  />
                )}
                <div className="col-12 BackBtnIsShow">
                  <div className="signinup-link-outer-bx slob-fdc fdc-two mb-0">
                    Back to
                    <div
                      onClick={() => handlesetStep("step1")}
                      className="signinup-link-two"
                    >
                      Previous
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          {/* --- Step 2 end --- */}
        </div>
      </section>
    </>
  );
}
