import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useAuth, getLoggedInUserData, getToken } from '../../context/AuthContext';
import useApi from '../../utility/apiCall';
import API_URL from '../../config/config';

// Icon Files
import Icons from '../../components/icons';


export default function WalletBalances() {
    const { apiCall } = useApi();
    const [user, setUser] = useState('')
    const { state } = useAuth();
    const token = state.token || '';
    const { dispatch } = useAuth();
    const [total, setTotalBal] = useState('0.00');
    const [currency, setCurrency] = useState('');

    const [hideBalances, setHideBalances] = useState(false);
    const isInitialMount = useRef(true);

    const toggleHideBalances = () => {
        setHideBalances(!hideBalances);
    };

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
            const loggedInUser = getLoggedInUserData();
            const isUserLoggedIn = !!loggedInUser;
            if (isUserLoggedIn) {
                if (loggedInUser.id && token) {
                    fetchTotalBalance();
                    fetchUserSteps();
                }
            }
        }
    }, [state.userData]); 

    const fetchTotalBalance = async () => {
        try {
            const response = await apiCall(API_URL +'get-user-wallet-total-balance', {
                user_id: state?.userData?.id,
                token: token,
            });
            if (response.data.success == '0') {
                setTotalBal(response.data.data.total_balance);
                setCurrency(response.data.data.currency);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchUserSteps = async () => {
        try {
          const response = await apiCall(
            API_URL + "get-user-profile",
            {
              user_id: state?.userData?.id,
              token: state?.token,
            },
            {}
          );
          if (response.data.success == "0") {
            // console.log('response.data.data',);
            // setVerifiedCaption(response.data.data.verified);
            localStorage.setItem("userData", JSON.stringify(response.data.data));
            localStorage.setItem("token", token);
            dispatch({
              type: "LOGIN",
              payload: { user: response.data.data, token: token },
            });
          }
        } catch (error) {
          console.error("An error occurred");
        }
      };

    return (
        <div className="common-box">
            <div className="wallet-balances-bx">
                <div className="wbb-inner-bx">
                    <div className="wbb-i-label">Estimated Total</div>
                    {hideBalances ? (
                    <div className="wbb-i-balances">*******<span>{currency}</span></div>
                    ) : (
                    <div className="wbb-i-balances">{total}<span>{currency}</span></div>
                    )}
                    <div className="wbb-i-hide-btn" onClick={toggleHideBalances}>
                        {hideBalances ? (
                        <>
                            <Icons.ViewPassSvg /> Show Balance
                        </>
                        ) : (
                        <>
                            <Icons.HidePassSvg /> Hide Balance
                        </>
                        )}
                    </div>
                </div>
                <div className="wallet-current-btns d-flex flex-wrap gap-2">
                    <Link to="/deposit" className="common-btn-item cbi-small cbi-fill"><span><Icons.DepositSvg/>Deposit</span></Link>
                    {(state?.userData?.verified) &&
                        <>
                            <Link to="/withdraw" className="common-btn-item cbi-small cbi-fill"><span><Icons.WithdrawSvg/>Withdraw</span></Link>
                            <Link to="/transfer" className="common-btn-item cbi-small cbi-fill"><span><Icons.TransactionsSvg/>Transfer</span></Link>
                        </>
                    }
                    {!(state?.userData?.verified) &&
                        <>
                            <Link to="/verifications" className="common-btn-item cbi-small cbi-fill cbi-disable"><span><Icons.WithdrawSvg/>Withdraw</span></Link>
                            <Link to="/verifications" className="common-btn-item cbi-small cbi-fill cbi-disable"><span><Icons.TransactionsSvg/>Transfer</span></Link>
                        </>
                    }
                    {/* <Link to="/transfer" className="common-btn-item cbi-small cbi-fill"><span><Icons.TransactionsSvg/>Internal Transfer</span></Link> */}
                </div>
            </div>
        </div>
    )
}
