import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import {
  useAuth,
  getLoggedInUserData,
  getToken,
} from "../../context/AuthContext";
import useApi from "../../utility/apiCall";
import API_URL from "../../config/config";
//import FlashMessage from '../../components/FlashMessage';

import { Modal } from "react-bootstrap";

// Icons Files
import Icons from "../../components/icons";

// Design Files
// import CommonLayout from "../PageCommonLayout/CommonLayout";
import Breadcrumb from "../../components/Breadcrumb";
import HistoryList from "../HistoryList";
import Input from "../../components/CommonInput/Input";
import FilterDropItem from "../../components/Filters/FilterDropItem";
// import FilterRadioInput from '../../components/Filters/FilterRadioInput';
import DatePickerComponent from "../../components/CommonInput/DatePickerComponent";
import MT5AccountChart from "./MT5AccountChart";
import EmptyBox from "../../components/EmptyBox";

export default function PageMt5History() {
  const { account } = useParams();
  const { apiCall } = useApi();
  const { state, dispatch } = useAuth();
  const token = state.token || "";
  const [transactions, setTransactions] = useState(null);
  const isInitialMount = useRef(true);
  const [transferTypeFilter, setTransferTypeFilter] = useState(null);
  const [statusFilter, setStatusFilter] = useState(null);
  const [displayTransferTypeFilter, setDisplayTransferTypeFilter] =
    useState("All Transactions");
  const [displayStatusFilter, setDisplayStatusFilter] = useState("All side");
  const [perPage, setPerPage] = useState(100);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(null);
  const [isChartLoading, setIsChartLoading] = useState(null);
  const [selectDate, setSelectDateDate] = useState(null);
  const [selectFromDate, setSelectFromDateDate] = useState(null);
  const [selectToDate, setSelectToDateDate] = useState(null);
  const [trades, setTrades] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [type, setType] = useState("pending");
  const [exportType,setExportType] = useState("pending");
  const [activeStep, setActiveStep] = useState("pending");
  const [orderBy, setOrderBy] = useState(null);
  const [orderColumn, setOrderColumn] = useState("asc");
  const [chartFilter, setChartFilter] = useState("all");

  const handleSelectDateChange = (date) => {
    setSelectDateDate(date);
  };
  const handleSelectFromDateChange = (date) => {
    setSelectFromDateDate(date);
  };
  const handleSelectToDateChange = (date) => {
    setSelectToDateDate(date);
  };

  useEffect(() => {
    const fetchData = async () => {
      const loggedInUser = getLoggedInUserData();
      const isUserLoggedIn = !!loggedInUser;

      if (isUserLoggedIn) {
        if (loggedInUser.id && token) {
          try {
            // const transactions = await getMt5History();
            // const Trades = await getTradeHistory(type);
          } catch (error) {
            console.error("Failed to fetch wallet group data:", error);
          }
        }
      }
    };

    if (isInitialMount.current) {
      isInitialMount.current = false;
      fetchData();
    }
    const chartData = getChartData();
    const account = getAccountInfo();
  }, [token]);

  const getChartData = async () => {
    try {
      setIsChartLoading(true);
      const response = await apiCall(API_URL + "get-chart-data", {
        user_id: state.userData.id,
        user_wallet_id: account,
        token: token,
        page: page,
        per_page: perPage,
        start_date: selectFromDate,
        end_date: selectToDate,
        chart_filter: chartFilter,
      });
      if (response.data.success == "0") {
        setChartData(response.data.data.profitData);
      }
      setIsChartLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsChartLoading(false);
    }
  };
  const handleChartFilter = (filterType) => {
    setChartFilter(filterType);
  };
  useEffect(() => {
    if (chartFilter && chartFilter != "" && chartFilter != "custom") {
      getChartData();
    }
  }, [chartFilter]);
  useEffect(() => {
    if (
      chartFilter &&
      chartFilter === "custom" &&
      selectFromDate 
    ) {
      getChartData();
    }
  }, [selectFromDate, selectToDate]);

  const [accountData,setAccountData] = useState(null);
  const [walletLoading,setWalletLoading] = useState(false);
  const [historyStep, setHistoryStep] = useState("step1");
  const getAccountInfo = async () => {
    try {
      setWalletLoading(true);
      const response = await apiCall(API_URL + "get-wallet-details", {
        user_id: state.userData.id,
        wallet_id: account,
        token: token,
      });
      if (response.data.success == "0") {
        setAccountData(response.data.data);
      }
      setWalletLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setWalletLoading(false);
    }
  };
  // const getMt5History = async () => {
  //   // Logic to get user wallet group-wise data
  //   try {
  //     setIsLoading(true);
  //     const response = await apiCall(API_URL + "get-mt5-trade-history", {
  //       user_id: state.userData.id,
  //       user_wallet_id: account,
  //       token: token,
  //       page: page,
  //       per_page: perPage,
  //       transfer_type: transferTypeFilter,
  //       transaction_status: statusFilter,
  //     });
  //     if (response.data.success === "0") {
  //       setTransactions(response.data.data.data);
  //       setTotalPages(response.data.data.data.last_page);
  //     }
  //     setIsLoading(false);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  // useEffect(() => {
  //   if(transferTypeFilter !== ''){
  //     if(transferTypeFilter !== null){
  //       setDisplayTransferTypeFilter(toTitleCase(transferTypeFilter));
  //     }
  //     getMt5History();
  //   }
  // }, [transferTypeFilter]);

  // useEffect(() => {
  //   if(statusFilter !== ''){
  //     if(statusFilter !== null){
  //       setDisplayStatusFilter(toTitleCase(statusFilter));
  //     }
  //     getMt5History();
  //   }
  // }, [statusFilter]);

  useEffect(() => {
    if (parseInt(page) > 0) {
      if(historyStep == "step2"){
        getTradeHistory(type);
      }
    }
  }, [page]);

  

  const handleStatusChange = (status) => {
    if (status != "All Side") {
      setStatusFilter(status.replace(" ", "_").toLowerCase());
    } else {
      setStatusFilter(null);
    }
    setDisplayStatusFilter(status);
  };

  useEffect(() => {
    setPage(1);
  }, [perPage]);
  useEffect(() => {
    if (historyStep == "step2" && orderBy) {
      getTradeHistory(type);
    }
  }, [orderColumn, orderBy]);
  useEffect(() => {
    if (historyStep == "step2" &&  selectDate) {
      getTradeHistory(type);
    }
  }, [selectDate]);

  useEffect(() => {
    if (historyStep == "step2" && statusFilter != "") {
      getTradeHistory(type);
    }
  }, [statusFilter]);

  const handlePerPageChange = (perPage) => {
    setPerPage(parseInt(perPage));
  };

  const changePrevPage = () => {
    if (page > 1) {
      setPage(page - 1);
    } else {
      setPage(1);
    }
  };
  const changeNextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const toTitleCase = (str) => {
    if (str === "" || str === null) return "";
    return str
      .replace(/_/g, " ") // Replace underscores with spaces
      .split(" ") // Split the string into words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter of each word
      .join(" "); // Join the words back into a single string
  };

  // ---
  
  const handleHistoryStep = (stepId) => {
    if (stepId === "step1") {
      setChartFilter("all");
      getChartData();
    }
    if (stepId === "step2") getTradeHistory(type);
    setHistoryStep(stepId);
  };

  const getTradeHistory = async (type) => {
    try {
      setIsLoading(true);
      const response = await apiCall(API_URL + "get-mt5-trade", {
        user_id: state.userData.id,
        user_wallet_id: account,
        token: token,
        page: page,
        per_page: perPage,
        type: type,
        order_by: orderBy,
        order_column: orderColumn,
        date: selectDate,
        status: statusFilter,
      });
      if (response.data.success == "0") {
        setTrades(response.data.data.data);
        setTotalPages(response.data.data.last_page);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };
  const getTradeHistoryExport = async () => {
    try {
        setIsLoading(true);
        
        const response = await apiCall(
            API_URL + "get-mt5-trade-export",
            {
                user_id: state.userData.id,
                user_wallet_id: account,
                token: token,
                type: type,
                order_by: orderBy,
                order_column: orderColumn,
                date: selectDate,
                status: statusFilter,
                nopaginate: '1'  // Request without pagination
              },{ responseType: 'blob' }
        );

        // Create a URL for the file and trigger download
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'trade_history_export.xlsx');
          document.body.appendChild(link);
          link.click();
          link.remove();

          setIsLoading(false);
      } catch (error) {
          console.error("Error fetching data:", error);
          setIsLoading(false);
      }
  };
  // ---
  const handleOrderStep = async (type) => {
    try {    
      if (type === "pending") {
        setType(type);
        setExportType(type);
        setActiveStep("pending");
        
      }
      if (type === "open") {
        setType(type);
        setExportType(type);
        setActiveStep("open");
        
      }
      if (type === "close") {
        setType(type);
        setExportType(type);
        setActiveStep("deal");
        setOrderBy(null);
        
      }
      setOrderBy(null)
      setOrderColumn('asc')
      setSelectDateDate(null)
      setStatusFilter(null)
      const trades = await getTradeHistory(type);
    } catch (error) {
      console.error("Failed to fetch wallet group data:", error);
    }
  };
  useEffect(() => {
  }, [orderBy]);

  useEffect(() => {
  }, [orderColumn]);

  useEffect(() => {
  }, [selectDate]);

  useEffect(() => {
  }, [statusFilter]);
  // ---
  const StatusOptions = ["All side", "Buy", "Sell"];
  // ---

  // ---
  const [isAccountInfo, setIsAccountInfo] = useState(false);

  const handleAccountInfoClose = () => {
    setIsAccountInfo(false);
  };

  const handleAccountInfoShow = () => {
    setIsAccountInfo(true);
  };
  // ---

  // ---

  const [goPage, setGoPage] = useState(null);
  const handleonChange = (page) => {
    if (parseInt(page) === 0 || page === "") {
      page = 1;
    }
    if (page < parseInt(totalPages)) {
      setPage(page);
      setGoPage(page);
    } else {
      setPage(totalPages);
      setGoPage(totalPages);
    }
  };

  const handleOrderByChange = (column) => {
    const newOrder =
      orderBy === column && orderColumn === "asc" ? "desc" : "asc";
    setOrderColumn(newOrder);
    setOrderBy(column);
  };
  // ---

  return (
    <>
      <>
        <Breadcrumb
          backBtn="bmb-back-btn"
          backBtnLink="/mt5"
          breadcrumbIcon="Mt5Svg"
          breadcrumbHeading="MT5 Trade History"
          middleLink="/mt5"
          middleHeading="MT5"
        />

        <div className="container-lg pt-3 pb-5 px-lg-4">
          <div className="row row-gap-4">
            {/* --- */}
            <div className="col-12">
              <div className="common-box">
                <div className="mt5-account-bx">
                  {accountData  && (
                    <>
                    <div className="mt5-account-inner">
                      <div className="mt5-tags-bx">
                        <div className="mt5-tag-item mt5-current">{accountData && accountData.mt5_type}</div>
                        <div className="mt5-tag-item">MT5</div>
                        {
                          accountData && (accountData.account_number != accountData.code) && 
                          <div className="mt5-tag-item">{accountData.code}</div>
                        }
                        
                      </div>
                      <div className="mt5-account-id">{accountData && (accountData.account_number != accountData.code) && accountData.code}  {accountData && accountData.account_number}</div>
                      <div className="mt5-account-balances">
                      {accountData && accountData.formatted_balance}<span>{accountData && accountData.currency}</span>
                      </div>
                    </div>
                    
                    <div className="mt5-account-btns d-flex flex-wrap gap-2">
                      <div
                        onClick={handleAccountInfoShow}
                        className="common-btn-item cbi-small cbi-fill"
                      >
                        <span>Account Info</span>
                      </div>
                      <Link
                        to="/transfer"
                        className="common-btn-item cbi-small cbi-fill"
                      >
                        <span>
                          <Icons.TransactionsSvg />
                          Transfer
                        </span>
                      </Link>
                    </div>
                  </>
                  )}
                </div>
                {walletLoading && !accountData && (
                  <div className="skeletant-bx skeletant-design sh-95"></div>
                )}
              </div>
            </div>
            {/* --- */}

            <div className="col-12">
              <div
                className={`common-box ${
                  historyStep === "step1" ? "pb-0" : ""
                }`}
              >
                {/* --- */}
                <div className="common-tabs-bx">
                  <div
                    className={`ctb-item ${
                      historyStep === "step1" ? "active" : ""
                    }`}
                    onClick={() => handleHistoryStep("step1")}
                  >
                    Balance Statistics
                  </div>
                  <div
                    className={`ctb-item ${
                      historyStep === "step2" ? "active" : ""
                    }`}
                    onClick={() => handleHistoryStep("step2")}
                  >
                    Deals History
                  </div>
                </div>
                {/* --- */}

                <div
                  className={`${historyStep === "step1" ? "" : "d-none"} `}
                  id="step1"
                >
                  {/* --- Filter Section Start --- */}
                  <div className="filter-bx mt5-top-filter mt-5-chart-filter mt-4">
                    <div className="filter-left">
                      <div className="common-tabs-bx ctb-style-2">
                        <div
                          className={`ctb-item ${
                            chartFilter === "all" ? "active" : ""
                          }`}
                          onClick={() => handleChartFilter("all")}
                        >
                          All
                        </div>
                        <div
                          className={`ctb-item ${
                            chartFilter === "1day" ? "active" : ""
                          }`}
                          onClick={() => handleChartFilter("1day")}
                        >
                          1 Day
                        </div>
                        <div
                          className={`ctb-item ${
                            chartFilter === "1week" ? "active" : ""
                          }`}
                          onClick={() => handleChartFilter("1week")}
                        >
                          1 Week
                        </div>
                        <div
                          className={`ctb-item ${
                            chartFilter === "1month" ? "active" : ""
                          }`}
                          onClick={() => handleChartFilter("1month")}
                        >
                          1 Month
                        </div>
                        <div
                          className={`ctb-item ${
                            chartFilter === "1year" ? "active" : ""
                          }`}
                          onClick={() => handleChartFilter("1year")}
                        >
                          1 Year
                        </div>
                        <div
                          className={`ctb-item ${
                            chartFilter === "custom" ? "active" : ""
                          }`}
                          onClick={() => handleChartFilter("custom")}
                        >
                          Custom
                        </div>
                      </div>
                    </div>
                    <div className="filter-right d-flex column-gap-2">
                      {chartFilter && chartFilter === "custom" && (
                        <>
                          <DatePickerComponent
                            label="From Date"
                            onChange={handleSelectFromDateChange}
                            value={selectFromDate}
                          />
                          <DatePickerComponent
                            label="To Date"
                            onChange={handleSelectToDateChange}
                            value={selectToDate}
                          />
                        </>
                      )}
                    </div>
                    {/* <div className="input-note-2 mt-0">
                      <Icons.CalendarDaysSvg /> 07 Jul 2014 – 16 Jul 2014
                    </div> */}
                  </div>
                  {/* --- Filter Section Start --- */}
                  {chartData && chartData != "" && !isChartLoading && (
                    <MT5AccountChart
                      chartData={chartData}
                      filterValue={chartFilter}
                      startDate={selectFromDate}
                      endDate={selectToDate}
                    />
                  )}
                  {isChartLoading && (
                    <>
                      <div className="skeletant-bx skeletant-design sh-47 mb-2 mt-3"></div>
                      <div className="skeletant-bx skeletant-design sh-47 mb-2"></div>
                      <div className="skeletant-bx skeletant-design sh-47 mb-2"></div>
                      <div className="skeletant-bx skeletant-design sh-47"></div>
                      <div className="pb-3"></div>
                    </>
                  )}
                  {chartData && chartData.length == 0 && !isChartLoading && (
                    <EmptyBox
                      className="empty-div-bx"
                      eh="No data found"
                      esh="Try adjusting filters to get results."
                    />
                  )}
                </div>

                <div
                  className={`${historyStep === "step2" ? "" : "d-none"} `}
                  id="step2"
                >
                  {/* --- Filter Section Start --- */}
                  <div className="filter-bx mt5-top-filter mt-4 mb-4">
                    <div className="filter-left">
                      <div className="common-tabs-bx ctb-style-2">
                        <div
                          className={`ctb-item ${
                            activeStep === "pending" ? "active" : ""
                          }`}
                          onClick={() => handleOrderStep("pending")}
                        >
                          Pending Orders
                        </div>
                        <div
                          className={`ctb-item ${
                            activeStep === "open" ? "active" : ""
                          }`}
                          onClick={() => handleOrderStep("open")}
                        >
                          Open Positions
                        </div>
                        <div
                          className={`ctb-item  ${
                            activeStep === "deal" ? "active" : ""
                          }`}
                          onClick={() => handleOrderStep("close")}
                        >
                          Closed Positions
                        </div>
                      </div>
                    </div>
                    <div className="filter-right d-flex align-items-center column-gap-2">
                      <DatePickerComponent
                        label="Select Date"
                        onChange={handleSelectDateChange}
                        value={selectDate}
                      />
                      <FilterDropItem
                        label={displayStatusFilter}
                        groupName="status"
                        options={StatusOptions}
                        onChange={handleStatusChange}
                        selected={displayStatusFilter}
                      />
                    </div>
                  </div>
                  {/* --- Filter Section Start --- */}

                  {/* --- History List table start --- */}
                  {trades && trades.length > 0 && (<div className="common-btn-item cbi-fill cbi-small mb-3 ms-auto" onClick={getTradeHistoryExport}><span><Icons.ArrowexportSvg />Export</span></div>)}
                  <HistoryList
                    trades={trades}
                    orderBy={handleOrderByChange}
                    orderColumn={orderColumn}
                    loading={isLoading}
                  />
                  {/* --- History List table end --- */}

                  {/* --- Filter Section Start --- */}
                  {trades && trades.length > 0 && (
                    <div className="filter-bx trades-bottom-filter mt-4">
                      <div className="filter-left">
                        <div className="d-flex column-gap-2 align-items-center justify-content-center column-gap-2">
                          <div className="signinup-group-style d-flex column-gap-2 align-items-center justify-content-center">
                            <div className="sgr-outer-label mb-0">Go to</div>
                            <Input
                              className="gopageinput mb-0"
                              type="number"
                              value={goPage ? goPage : ""}
                              placeholder="Page"
                              onChange={handleonChange}
                            />
                          </div>

                          {/* <div className="sgr-outer-label mb-0">Rows per page</div>
                              <FilterDropItem className="fdih-36 fdb-view-bl" label={perPage} groupName="view" options={RowsOptions} onChange={handlePerPageChange}/> */}
                        </div>
                      </div>

                      <div className="filter-right">
                        <div className="pagination-bx">
                          <div
                            className="pagination-btn pb-left"
                            onClick={changePrevPage}
                          >
                            Prev
                          </div>
                          <div className="pagination-text">
                            <div>{page}</div> <span>/</span>{" "}
                            <div>{totalPages}</div>
                          </div>
                          <div
                            className="pagination-btn pb-right"
                            onClick={changeNextPage}
                          >
                            Next
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* --- Filter Section End --- */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>

      {/* --- Account Info Modal --- */}
      <Modal
        className="zoom custom-content"
        centered
        show={isAccountInfo}
        onHide={handleAccountInfoClose}
      >
        <div className="custom-modal-header">
          <div className="cmh-lable">Account Information</div>

          <span className="close-icon" onClick={handleAccountInfoClose}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="32"
                d="M368 368L144 144M368 144L144 368"
              ></path>
            </svg>
          </span>
        </div>

        <div className="custom-modal-body">
          <div className="ac-info-item">
            <div className="ai-inner">
              <div className="ai-i-info">Account</div>
              <div className="ai-i-data">{accountData && accountData.account_number}</div>
            </div>

            <div className="ai-inner">
              <div className="ai-i-info">Nickname</div>
              <div className="ai-i-data">{accountData && accountData.code}</div>
            </div>

            <div className="ai-inner">
              <div className="ai-i-info">Product</div>
              <div className="ai-i-data">{accountData && accountData.account_type.name}</div>
            </div>

            <div className="ai-inner">
              <div className="ai-i-info">Type</div>
              <div className="ai-i-data">{accountData && accountData.product.name}</div>
            </div>

            <div className="ai-inner">
              <div className="ai-i-info">Real funds</div>
              <div className="ai-i-data">{accountData && accountData.formatted_balance}</div>
            </div>

            <div className="ai-inner">
              <div className="ai-i-info">Leverage</div>
              <div className="ai-i-data">{accountData && accountData.leverage.name}</div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
