import React, { useState, useEffect, useRef } from 'react';
import { useAuth, getLoggedInUserData, getToken } from '../../context/AuthContext';
import useApi from '../../utility/apiCall';
import API_URL from '../../config/config';

// Icon Files
import Icons from '../../components/icons';

// Design File
import EmptyBox from '../../components/EmptyBox';

export default function DeviceManagement() {
    const { apiCall } = useApi();
    const [user, setUser] = useState('')
    const { state } = useAuth();
    const token = state.token || '';
    const { dispatch } = useAuth();
    const [devices, setDevices] = useState([]);
    const isInitialMount = useRef(true);

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
            const loggedInUser = getLoggedInUserData();
            const isUserLoggedIn = !!loggedInUser;
            if (isUserLoggedIn) {
                if (loggedInUser.id && token) {
                    getProfileDevices();
                }
            }
        }
    }, [state.userData]);

    const getProfileDevices = async () => {
        try {
            const response = await apiCall(API_URL +'get-profile-devices', {
                user_id: state?.userData?.id,
                token: token,
                page: 1,
            });
            if (response.data.success == '0') {
                setDevices(response.data.data || []);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    return (
        <div className="common-box">
            <div className='device-managemeny d-flex flex-wrap gap-2 justify-content-between align-items-center'>
                <div className="common-box-heading mb-0"><Icons.ListSvg />Device Management</div>
                {/* <div className="common-btn-item cbi-fill"><span><Icons.LogoutSvg />Logout Other Device</span></div>   */}
            </div>

            {devices.length != 0 &&
                <div className="table-responsive">
                    <table className="custom-table">
                        <thead>
                            <tr>
                                <th>Device</th>
                                <th>IP</th>
                                <th>Location</th>
                                <th>Date</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                        {devices.map((device, index) => (
                            <tr key={index}>
                                <td className="td-wrap">
                                    <div className="td-device-name">{device.device_id}</div>
                                </td>
                                <td>{device.ip_address}</td>
                                <td>{device.location}</td>
                                <td>{device.created_dt_formatted}</td>
                                <td>
                                    <div className="td-status">
                                        <div className="td-active-icon"></div>
                                        Active
                                    </div>
                                </td>
                                {/* <td>
                                    <div className="td-status td-remove">
                                        <Icons.XMarkSvg/>
                                        Remove
                                    </div>
                                </td> */}
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            }

            {devices.length === 0 &&
                <div className="row row-gap-3">
                    <EmptyBox 
                        className="empty-div-bx" 
                        eh="Device Not Found"
                    />
                </div>
            }

            {/* <div className="row row-gap-3"> */}

                {/* 
                {devices.map((device, index) => (
                    <div className="col-12" key={index}>
                        <div className="device-management-item">
                            <div className="dm-inner">
                                <div className="dm-i-info">Device</div>
                                <div className="dm-i-data">{device.device_id}</div>
                            </div>
                            <div className="dm-inner">
                                <div className="dm-i-info">IP</div>
                                <div className="dm-i-data">{device.ip_address}</div>
                            </div>
                            <div className="dm-inner">
                                <div className="dm-i-info">Location</div>
                                <div className="dm-i-data">{device.location}</div>
                            </div>
                            <div className="dm-inner">
                                <div className="dm-i-info">Date</div>
                                <div className="dm-i-data">{device.created_dt_formatted}</div>
                            </div>
                            <div className="dm-inner dm-inner-btn">
                                <div className="dm-i-info">Status</div>
                                <div className="dm-i-data dm-i-delete">
                                    <Icons.XMarkSvg/> Remove
                                </div>
                            </div>
                        </div>
                    </div>
                ))} 
                */}

                {/* <div className="col-12">
                    <div className="device-management-item">
                        <div className="dm-inner">
                            <div className="dm-i-info">Device</div>
                            <div className="dm-i-data">Chrome 123.0.0 (Windows 10)</div>
                        </div>
                        <div className="dm-inner">
                            <div className="dm-i-info">IP</div>
                            <div className="dm-i-data">106.213.149.9</div>
                        </div>
                        <div className="dm-inner">
                            <div className="dm-i-info">Location</div>
                            <div className="dm-i-data">Ahmedabad, India</div>
                        </div>
                        <div className="dm-inner">
                            <div className="dm-i-info">Date</div>
                            <div className="dm-i-data">18:20 13.04.24</div>
                        </div>
                        <div className="dm-inner dm-inner-btn">
                            <div className="dm-i-info">Status</div>
                            <div className="dm-i-data dm-i-active"><div className="dm-i-actiev-icon"></div> Active</div>
                        </div>
                    </div>
                </div> */}

                {/* <div className="col-12">
                    <div className="device-management-item">
                        <div className="dm-inner">
                            <div className="dm-i-info">Device</div>
                            <div className="dm-i-data">Chrome 123.0.0 (Windows 10)</div>
                        </div>
                        <div className="dm-inner">
                            <div className="dm-i-info">IP</div>
                            <div className="dm-i-data">106.213.149.9</div>
                        </div>
                        <div className="dm-inner">
                            <div className="dm-i-info">Location</div>
                            <div className="dm-i-data">Ahmedabad, India</div>
                        </div>
                        <div className="dm-inner">
                            <div className="dm-i-info">Date</div>
                            <div className="dm-i-data">18:20 13.04.24</div>
                        </div>
                        <div className="dm-inner dm-inner-btn">
                            <div className="dm-i-info">Action</div>
                            <div className="dm-i-data dm-i-delete">
                                <Icons.XMarkSvg/> Remove
                            </div>
                        </div>
                    </div>
                </div> */}

                {/* {devices.length === 0 &&
                    <EmptyBox 
                        className="empty-div-bx" 
                        eh="Device not found"
                    />
                } */}
               
            {/* </div> */}
        </div>
    )
}
