import React, { useState } from 'react';

export default function CommonSwitche({ isToggle,onToggle }) {
    const [isActive, setIsActive] = useState(false);
    
    const toggleActive = () => {
        setIsActive(!isActive);
        if (onToggle) onToggle(!isActive); // Call the onToggle function with the new state
    };

    // console.log(isToggle);

    return (
        <div className={`signinup-group-switche ${isToggle ? 'active' : ''}`} onClick={toggleActive}>
            <div className="sgs-inner"></div>
        </div>
    );
}
