import React, { useState } from 'react'

// Icon Files
import Icons from '../components/icons';
import TransactionDetailModal from './TransactionDetailModal';

export default function TransactionSortItem({transaction}) {

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  }

  return (
    <>
      <div className="col-12">
        <div className="transaction-item transaction-sort-item" onClick={handleShow}>
          <div className="ti-inner ti-i-pl">
            <div className="ti-i-icon"><Icons.TransactionsSvg /></div>
            <div className="ti-i-info">{transaction.created_at_formattad}</div>
            <div className="ti-i-data">{transaction.formatted_amount} USD</div>
          </div>
        
          <div className="ti-inner">
            <div className="ti-i-info">Status</div>
            <div className={`ti-i-data tit-${transaction.transaction_status}`}>{transaction.display_transaction_status}</div>
          </div>
        </div>
      </div>

      {/* <div className="col-12 d-none" >
        <div className="transaction-item transaction-sort-item" onClick={handleShow}>
          <div className="ti-inner ti-i-pl">
            <div className="ti-i-icon tit-color2"><Icons.PlusSvg /></div>
            <div className="ti-i-info">17.05.24 15:07</div>
            <div className="ti-i-data">17.92 USD</div>
          </div>
        
          <div className="ti-inner">
            <div className="ti-i-info">Status</div>
            <div className="ti-i-data tit-color2">Done</div>
          </div>
        </div>
      </div>

      <div className="col-12">
        <div className="transaction-item transaction-sort-item" onClick={handleShow}>
          <div className="ti-inner ti-i-pl">
            <div className="ti-i-icon tit-color1"><Icons.MinusSvg /></div>
            <div className="ti-i-info">17.05.24 15:07</div>
            <div className="ti-i-data">17.92 USD</div>
          </div>
        
          <div className="ti-inner">
            <div className="ti-i-info">Status</div>
            <div className="ti-i-data tit-color2">Done</div>
          </div>
        </div>
      </div>

      <div className="col-12">
        <div className="transaction-item transaction-sort-item" onClick={handleShow}>
          <div className="ti-inner ti-i-pl">
            <div className="ti-i-icon"><Icons.TransactionsSvg /></div>
            <div className="ti-i-info">17.05.24 15:07</div>
            <div className="ti-i-data">17.92 USD</div>
          </div>
        
          <div className="ti-inner">
            <div className="ti-i-info">Status</div>
            <div className="ti-i-data tit-color3">Pending</div>
          </div>
        </div>
      </div>

      <div className="col-12">
        <div className="transaction-item transaction-sort-item" onClick={handleShow}>
          <div className="ti-inner ti-i-pl">
            <div className="ti-i-icon tit-color1"><Icons.XMarkSvg /></div>
            <div className="ti-i-info">17.05.24 15:07</div>
            <div className="ti-i-data">17.92 USD</div>
          </div>
        
          <div className="ti-inner">
            <div className="ti-i-info">Status</div>
            <div className="ti-i-data tit-color1">Rejected</div>
          </div>
        </div>
      </div> */}

      <TransactionDetailModal show={show} handleClose={handleClose} />
    </>
  )
}
