import React from 'react'

// Icons Files
import Icons from '../../components/icons'

export default function PaymentMethodCard({ imgSrc, label, isUnavailable = false, processingTime, fee, limits, onClick }) {
  return (
    <div className="col-sm-6">
      <div className={`payment-method-card ${isUnavailable ? 'unavailable' : ''}`} onClick={onClick}>
        <div className="pmc-inner">
          <div className="pmc-heading-bx">
            <img className="pmc-img" src={imgSrc} alt={label} />
            <div className="pmc-label">{label}</div>
          </div>
          {isUnavailable && (
            <div className="pmc-unavailable"><Icons.SecuritySvg /> Unavailable</div>
          )}
        </div>
        <div className="pmc-info">
          <div className="pmc-i-item">Processing time {processingTime}</div>
          <div className="pmc-i-item">Fee {fee}</div>
          <div className="pmc-i-item">{limits}</div>
        </div>
      </div>
    </div>
  )
}
