import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useAuth  } from './context/AuthContext';



const RequireAuth = ({ allowedRoles }) => {
    const { state } = useAuth();
   
    const location = useLocation();
    return (
        (
        	((state?.isLoggedIn==true) && state?.userData && (Object.keys(state?.userData).length > 0)) ? (((allowedRoles?.includes(state?.isLoggedIn) == true))
                                    ? <Outlet />
                                    : <Navigate to="/" state={{ from: location }} replace />)
                        : <Navigate to="/" state={{ from: location }} replace />

        )
    );
}

export default RequireAuth;
