import React from 'react'
import { Link } from 'react-router-dom'

export default function CheckInput({ className, name, id, label, labelNote, linkBr, linkURL, linkText, value = null, onChange, checked = false }) {

  return (
    <div className={`signinup-group-checkmark ${className}`}>
      <input className="d-none" type="checkbox" name={name} id={id} value={value} onChange={onChange} checked={checked}/>
      <label className="checkmark-label" htmlFor={id}>
        <span className="checkmark-icon">
          <div className="cmi-inner"></div>
        </span>
        {label}  {linkBr && <br className='d-block d-sm-none'/> } {linkURL && linkText && <Link to={linkURL}>{linkText}</Link>}
        {labelNote && <div className="input-note">{labelNote}</div>}
      </label>
    </div>
  )
}
