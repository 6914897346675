import React, { useState, useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useAuth, getLoggedInUserData } from "../../context/AuthContext";
import useApi from "../../utility/apiCall";
import API_URL from "../../config/config";

// Audio & Menu Context File
import { useMenu } from "../../context/MenuContext";

// Images Common File
import * as Img from "../../components/Img";

// Icon Files
import Icons from "../../components/icons";

// Design file
// import GoogleTranslate from '../../components/GoogleTranslate';

export default function CommonLayoutHeader() {
  const [user, setUser] = useState("");
  const { state, dispatch } = useAuth();
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { apiCall } = useApi();
  const token = state.token || "";
  const [count, setCount] = useState(null);
  // --- ---
  const { isActive, toggleMenu } = useMenu();
  // --- ---

  // --- ---
  const navigate = useNavigate();
  // --- ---


  // --- ---
  const [isDropActive, setIsDropActive] = useState(false);

  const handleMouseEnter = () => {
    setIsDropActive(true);
  };

  const handleMouseLeave = () => {
    setIsDropActive(false);
  };

  const handleItemClick2 = () => {
    setIsDropActive(true);
  };

  const handleItemClick = () => {
    setIsDropActive(false);
  };


  // --- ---
  const [isMenuDropActive, setIsMenuDropActive] = useState(false);

  const handleMenuMouseEnter = () => {
    setIsMenuDropActive(true);
  };

  const handleMenuMouseLeave = () => {
    setIsMenuDropActive(false);
  };

  const handleMenuItemClick2 = () => {
    setIsMenuDropActive(true);
  };

  const handleMenuItemClick = () => {
    setIsMenuDropActive(false);
  };


  // --- ---
  // const [isGTDropActive, setIsGTDropActive] = useState(false);

  // const handleGTMouseEnter = () => {
  //   setIsGTDropActive(true);
  // };

  // const handleGTMouseLeave = () => {
  //   setIsGTDropActive(false);
  // };

  // const handleGTItemClick2 = () => {
  //   setIsGTDropActive(true);
  // };

  // const handleGTItemClick = () => {
  //   setIsGTDropActive(false);
  // };


  // --- ---
  const handleLogout = () => {
    dispatch({ type: "LOGOUT" });
    navigate("/");
  };


  useEffect(() => {
    const loggedInUser = getLoggedInUserData();
    const isUserLoggedIn = !!loggedInUser;
    if (isUserLoggedIn) {
      setUserLoggedIn(true);
      setUser(loggedInUser);
      getNotificationCount();
    } else {
      navigate("/");
    }
  }, [state.userData, navigate]);

  const playNotificationSound = () => {
    const audio = new Audio("http://localhost:8000/sounds/clickAudio.mp3"); // Adjust the path if necessary
    console.log(audio);
    audio.play();
  };

  const getNotificationCount = async () => {
    try {
      setIsLoading(true);
      const response = await apiCall(API_URL + "get-notification-count", {
        user_id: state.userData.id,
        token: token,
      });
      if (response.data.success == "0") {
        // console.log(response.data.data)
        
        if (count && count !== response.data.data) {
          playNotificationSound();
        }
        setCount(response.data.data);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  return (
    <>
      <header className={`custom-header`}>
        <div className="ch-inner">
          <div className="ch-left">
            <div
              className={` menu-icon-bx ${isActive ? "active" : ""} `}
              onClick={toggleMenu}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>

          <Link to="/dashboard" className="ch-logo">
            <img src={Img.logo_light} alt="" />
          </Link>

          <div className="ch-right">

            {/* */}
            {/* <div className="cr-icons-link"><Icons.LightSvg/></div> */}

            {/*  */}
            {/* <div className={`cr-drop-item cdi-product-drop ${isGTDropActive ? "active" : ""}`} onMouseLeave={handleGTMouseLeave}>
              
              <div className="cr-icons-link" onClick={handleGTItemClick2} onMouseEnter={handleGTMouseEnter}><Icons.LanguageSvg/></div>
              
              <div className={`cr-drop-bx ${ isGTDropActive ? "active" : ""}`}>
                <div className="cr-drop-inner">
                  <div className="GT-main-bx">
                      <div className="GT-lable">Language</div>
                      <span className="close-icon" onClick={handleGTItemClick}>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                              <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M368 368L144 144M368 144L144 368"></path>
                          </svg>
                      </span>
                      <GoogleTranslate onOptionSelect={handleGTItemClick} />
                  </div>
                </div>
              </div>
            </div> */}

            {/*  */}
            {/* <NavLink to="/wallet" className="cr-icons-link">
              <Icons.EwalletsSvg />
            </NavLink> */}

            {/*  */}
            <NavLink to="/notifications" className="cr-icons-link cr-il-noti">
              <Icons.NotificationsSvg />
              {count > 0 && (
                <>
                  {" "}
                  <div className="cr-num">{count}</div>
                </>
              )}
            </NavLink>

            {/*  */}
            <div className={`cr-drop-item cdi-product-drop ${isMenuDropActive ? "active" : ""}`} onMouseLeave={handleMenuMouseLeave}>
              
              <div className="cr-icons-link" onClick={handleMenuItemClick2} onMouseEnter={handleMenuMouseEnter}><Icons.AppsOutlineSvg /></div>
              
              <div className={`cr-drop-bx ${ isMenuDropActive ? "active" : ""}`} onClick={handleMenuItemClick}>
                <div className="cr-drop-inner">
                  <div className="cdi-main w-186px">
                    <NavLink to="/mt5" className="cdi-main-item"><Icons.Mt5Svg /> Client Desk</NavLink>
                    {/* <NavLink to="/trading" className="cdi-main-item"><Icons.TradeSvg /> Winprofx Terminal</NavLink> */}
                    <NavLink to="https://winprofx.com/" target="_blank" className="cdi-main-item"><Icons.LinkSvg /> Public Website</NavLink>
                    <NavLink to="https://winprofx.com/introducing-broker" target="_blank" className="cdi-main-item"><Icons.UsersSvg /> Become a Partner</NavLink>
                  </div>
                </div>
              </div>
            </div>
            
            {/*  */}
            <div className={`cr-drop-item ${isDropActive ? "active" : ""}`} onMouseLeave={handleMouseLeave}>
              <div className="ch-user-bx" onClick={handleItemClick2} onMouseEnter={handleMouseEnter}>
                {/* <div className="cr-icons-link"><Icons.UserSvg></div> */}

                <div className="cub-img-bx">
                  <img src={user?.image || Img.user} alt="" />
                </div>

                {/* <div className="cub-text-bx">User Name <Icons.DownSvg/></div> */}
              </div>
              <div className={`cr-drop-bx ${userLoggedIn} ${ isDropActive ? "active" : ""}`} onClick={handleItemClick}>
                <div className="cr-drop-inner">
                  <div className="cdi-main w-186px">
                    <Link to="/settings" className="cdi-user-data">
                      <div className="cud-name">
                        {user?.first_name} {user?.last_name}
                      </div>
                      <div className="cud-other">{user?.email}</div>
                    </Link>
                    <NavLink to="/notifications" className="cdi-main-item">
                      <Icons.NotificationsSvg /> Notifications{" "}
                      {count > 0 && (
                        <>
                          <div className="cmi-num">{count}</div>
                        </>
                      )}
                    </NavLink>
                    <div className="cdi-main-item" onClick={handleLogout}>
                      <Icons.LogoutSvg /> Log Out
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </header>
    </>
  );
}
