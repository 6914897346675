import React from 'react'

export default function FilterRadioInput({ id, name, label, onChange, checked }) {
  const handleOptionChange = (option) => {
    onChange(option)
  };
  return (
    <>
      <input type="radio" id={id} name={name} checked={checked} onChange={() => handleOptionChange(label)}/>
      <label htmlFor={id}><span className="check-icon"></span>{label}</label> 
    </>
  )
}
