import React, { useState } from 'react'

// Icon Files
import Icons from '../icons';

export default function Input({heading, className, type, sgl, placeholder, value, onChange}) {

    // --- ---
    //const [value, setValue] = useState('');
    const [isShow, setIsShow] = useState(false);

    const handleChange = (event) => {
        onChange(event.target.value);
        const newvalue = event.target.value;
        //setValue(newvalue);
        //setIsShow(newvalue.trim() !== '');
    };

    const handleClear = () => {
        //setValue('');
        setIsShow(false);
    };
    // --- ---

    return (
        <>
            {(heading && <div className="sgr-outer-label">{heading}</div> )}
            <div className={`signinup-group ${className}`}>
                <input type={type} placeholder={placeholder} value={value} onChange={handleChange} />
                
                {(sgl && <div className='group_left_icon'>{sgl}</div> )}

                {isShow ? (
                    <span className="group_right_icon active sgri-red" onClick={handleClear}>
                        <Icons.XMarkSvg />
                    </span>
                ) : (
                    <></>
                )}
            </div>
        </>
    )
}
