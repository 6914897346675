import React from 'react'
import { Link } from 'react-router-dom'

export default function BannerImg({src}) {
  return (
    <Link to="" className="banner-img-bx">
        <img src={src} alt=""/>
    </Link>
  )
}
