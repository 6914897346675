import React, { useState, useEffect, useRef } from 'react'
import { useAuth, getLoggedInUserData, getToken } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import useApi from '../../utility/apiCall';
import API_URL from '../../config/config'; 
import FlashMessage from '../../components/FlashMessage'; 
import * as Img from '../../components/Img'
// Imges & Icons Files
import accesscard from "../../img/icons/access-card.png"

// Design Files
// import CommonLayout from '../PageCommonLayout/CommonLayout';
import Breadcrumb from '../../components/Breadcrumb';

// Common Inputs Files
import CheckInput from '../../components/CommonInput/CheckInput';
import RadioInput from '../../components/CommonInput/RadioInput';
import SelectDropItem from '../../components/CommonInput/SelectDropItem';
import Input from '../../components/CommonInput/Input';
import Textarea from '../../components/CommonInput/Textarea';


export default function PageIBRequest() {
    const formRef = useRef(null);
    const navigate = useNavigate();
    const { apiCall } = useApi();
    const { state, dispatch } = useAuth();
    const token = state.token || '';
    const isInitialMount = useRef(true);
    const [countries, setCountries] = useState([]);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [showSendBtn, setShowSendBtn] = useState(false);
    const [requestSubmitSuccess, setRequestSubmitSuccsess] = useState(false);
    const [requestSubmitError, setRequestSubmitError] = useState(false);
    const [requestSubmitErrorMsg, setRequestSubmitErrorMsg] = useState(false);

    //fields for request
    const [agree, setAgree] = useState(false);
    const [isExperienced, setIsExperienced ]= useState(null);
    const [previousBroker, setPreviousBroker ]= useState(null);
    const [platforms, setPlatforms] = useState([]);
    const [additionalServices, setAdditionalServices] = useState([]);
    const [socialLink, setSocialLink] = useState(null);
    const [websiteLink, setWebsiteLink] = useState(null);
    const [messagngerLink, setMessagngerLink] = useState(null);
    const [forumnLink, setForumnLink] = useState(null);
    const [otherLink, setOtherLink] = useState(null);
    const [country, setCountry] = useState(null);
    const [numberOfClients, setNumberOfClients] = useState(null);
    const [additionalOther, setAdditionalOther ] = useState(null);
    const [language, setLanguage ] = useState(null);
    const [phone, setPhone ] = useState(null);
    const [email, setEmail ] = useState(null);

    const LanguageOptions = [
        { name: "Select Language", id:null, selected: false },
        { name: "English", id:"english", selected: false },
        { name: "Indonesian",id:"indonesian", selected: false },
        { name: "Malaysian",id:"malaysian", selected: false },
        { name: "Hindi", id:"hindi", selected: false },
        { name: "French",id:"french", selected: false },
    ];

    const CountryOption = [
        { name: "Germany", selected: false },
        { name: "Egypt", selected: false },
        { name: "Fiji", selected: false },
        { name: "India", selected: false },
        { name: "Japan", selected: false },
        { name: "Kuwait", selected: false },
        { name: "Mexico", selected: false },
        { name: "New Zealand", selected: false },
        { name: "Singapore", selected: false },
        { name: "Sri Lanka", selected: false },
    ];

    useEffect(() => {
        const fetchCountriesData = async () => {
          try {
            const response = await apiCall(API_URL +'get-countries', {
              nopaginate: "1",
            });
            if (response.data.success == '0') {
              setCountries(response.data.data);
            }
          } catch (error) {
            console.error('Error fetching data:', error);
          }
      
        };
        if (isInitialMount.current) {
          isInitialMount.current = false;
          getIbRequestStatus();
          fetchCountriesData();
        }
       
    }, []);

    const getIbRequestStatus = async () => {
        try {
          const response = await apiCall(API_URL +'get-ib-request-status', {
            user_id: state.userData.id,
            token: token,
          });
          if (response.data.success == '0') {
            if(response.data.data.status === 'pending'){
                setRequestSubmitError(true);
                setRequestSubmitErrorMsg('You have already applied for IB.');
                setTimeout(() => {
                    navigate('/ib-dashboard');
                }, 2000);
            }
            if(response.data.data.status === 'approved'){
                setRequestSubmitError(true);
                setRequestSubmitErrorMsg('You are already IB.');
                setTimeout(() => {
                    navigate('/ib-dashboard');
                }, 2000);
            }
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    
    };

    const validateFields = () => {
        const newErrors = {};
        // if (!agree) {
        //     newErrors.agree = 'Please agree with IB agreement';
        // } 
        if (isExperienced == null) {
            newErrors.is_expereience = 'Please select expereience';
        }
        if (country == null) {
            newErrors.country = 'Please select country';
        }
        if (numberOfClients == null) {
            newErrors.no_of_clients = 'Please enter number of clients';
        } 
        if (language == null) {
            newErrors.language = 'Please select language';
        } 
        if (email !== null && email !== '') {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if(!emailRegex.test(email)){
                newErrors.email = 'Please enter valid email';
            }
        } 
        if (phone !== null && phone !== '') {
            const phoneRegex = /^\+?[1-9]\d{8,14}$/; // E.164 international phone number format
            if(!phoneRegex.test(phone)){
                newErrors.phone = 'Please enter valid phone number';
            }
        } 
        if(platforms.includes('website')){
            if(!websiteLink){
                newErrors.website_link = 'Please enter website link';
            }
        }
        if(platforms.includes('social_media')){
            if(!socialLink){
                newErrors.social_link = 'Please enter social links';
            }
        }
        if(platforms.includes('messangers')){
            if(!messagngerLink){
                newErrors.messanger_link = 'Please enter messanger links';
            }
        }
        if(platforms.includes('forumns')){
            if(!forumnLink){
                newErrors.forumn_link = 'Please enter forumns links';
            }
        }
        if(platforms.includes('others')){
            if(!otherLink){
                newErrors.other_link = 'Please enter other links';
            }
        }
        //  if (!previousBroker) {
        //     newErrors.previous_broker = 'Please enter previous broker name';
        //  } 
        return newErrors;
    };
      // ---
    const handleSubmit = async() => {
        const valErrors = validateFields();
        if (Object.keys(valErrors).length === 0) {
          // Create a FormData object
          const formData = new FormData();
          formData.append('user_id',state.userData?.id);
          formData.append('token',state?.token);
          formData.append('agree',agree);
          formData.append('is_expereince',isExperienced);
          formData.append('previous_broker',previousBroker);
          formData.append('platforms',platforms);
          formData.append('website_link',websiteLink);
          formData.append('social_link',socialLink);
          formData.append('messanger_link',messagngerLink);
          formData.append('forumn_link',forumnLink);
          formData.append('other_link',otherLink);
          formData.append('countries',country);
          formData.append('additional_services',additionalServices);
          formData.append('other_additional_services',additionalOther);
          formData.append('language',language);
          formData.append('phone',phone);
          formData.append('email',email);
          formData.append('number_of_clients',numberOfClients);
          
          
          // Append each file to FormData
          // selectedDocumentTypes.forEach((value, index) => {
          //   if(selectedFiles[index].length > 0){
          //     formData.append(`document_type_id[]`, value);
          //   }
          // });
          // selectedFiles.forEach((object, index) => {
          //   object.forEach((file, idx) => {
          //     formData.append(`documents[]`, file);
          //   });
          // });
    
          try {
            // Send FormData using Axios
            setIsLoading(true);
            setRequestSubmitSuccsess(false)
            setRequestSubmitError(false)
            apiCall(API_URL + 'send-ib-request', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            })
            .then(response => {
                if(response.data.success == '0'){
                  setAgree(false);
                  setIsExperienced(null);
                  setPreviousBroker(null); 
                  setPlatforms([]); 
                  setWebsiteLink(null);
                  setForumnLink(null);
                  setMessagngerLink(null);
                  setOtherLink(null);
                  setSocialLink(null);
                  setCountry(null);
                  setNumberOfClients(null);
                  setAdditionalServices([]);
                  setAdditionalOther(null);
                  setLanguage(null);
                  setEmail(null);
                  setPhone(null);
                  setRequestSubmitSuccsess(true);
                setTimeout(() => {
                    navigate('/ib-dashboard');
                  }, 2000); // 3-second delay

                } else {
                  setRequestSubmitError(true);
                  setRequestSubmitErrorMsg(response.data.data.error);
                }
                setIsLoading(false);
            })
            .catch(error => {
              setIsLoading(false);
              setRequestSubmitError(true)
              setRequestSubmitErrorMsg(error.response.data.message)
              //console.error('Error uploading profile picture:', error);
            });
          } catch (error) {
            //console.error('Error uploading files:', error);
          }
        } else {
          // Set errors to state
          setErrors(valErrors);
          scrollToRef();
        }
    };

    const onChangeDemo = () => {

    }

    const  handleAgreeChange = (event) =>{
        var agree = event.target.checked;
        if(agree){
            setErrors((prev) => ({ ...prev, agree: '' })); 
        }
        setAgree(agree)
    }
    const handleExperiencedChange = (event) => {
        if(event.target.value !== null){
            setErrors((prev) => ({ ...prev, is_expereience: '' })); 
        }
        setIsExperienced(event.target.value);
    };

    const handleBrokerChange = (broker) => {
        setPreviousBroker(broker)
        //setErrors((prev) => ({ ...prev, previous_broker: '' }));
    }

    const handlePlatformChange = (event) => {
        const { value, checked } = event.target;
        if (checked) {
          setPlatforms([...platforms, value]);
        } else {
          setPlatforms(platforms.filter((option) => option !== value));
          //setLinks({ ...links, [value]: '' });
        }
    };
    const handleServicesChange = (event) => {
        setAdditionalServices()
        const { value, checked } = event.target;
        if (checked) {
            setAdditionalServices([...additionalServices, value]);
        } else {
          setAdditionalServices(additionalServices.filter((option) => option !== value));
        }
    }

    const handleWebsiteLinkChange = (value) => {
        //const { name, value } = event.target;
        setErrors((prev) => ({ ...prev, website_link: '' })); 
        setWebsiteLink(value);
    };
    const handleSocialLinkChange = (value) => {
        //const { name, value } = event.target;
        setErrors((prev) => ({ ...prev, social_link: '' })); 
        setSocialLink(value);
    };
    const handleMessangerLinkChange = (value) => {
        //const { name, value } = event.target;
        setErrors((prev) => ({ ...prev, messanger_link: '' })); 
        setMessagngerLink(value);
    };
    const handleForumnLinkChange = (value) => {
        //const { name, value } = event.target;
        setErrors((prev) => ({ ...prev, forumn_link: '' })); 
        setForumnLink(value);
    };
    const handleOtherLinkChange = (value) => {
        //const { name, value } = event.target;
        setErrors((prev) => ({ ...prev, other_link: '' })); 
        setOtherLink(value);
    };
    


    const handleCountryChange = (country) => {
        setCountry(country)
        setErrors((prev) => ({ ...prev, country: '' }));
    }
    const handleNumberOfClientChange = (clients) => {
        setNumberOfClients(clients)
        setErrors((prev) => ({ ...prev, no_of_clients: '' }));
    }
    const handleAdditionalOther = (value) => {
        setAdditionalOther(value);
    }
    const handleLanguageChange = (language) =>{
        setLanguage(language);
        setErrors((prev) => ({ ...prev, language: '' }));
    }   
    const handleEmailChange = (email) =>{
        setEmail(email)
    }
    const handlePhoneChange = (phone) =>{
        setPhone(phone)
    }

    const scrollToRef = () => {
        formRef.current.scrollIntoView({ behavior: 'smooth' });
    };

  return (
    <>
        <Breadcrumb breadcrumbIcon="UserSvg" breadcrumbHeading="IB Request" />

        <div className="container-lg pt-3 pb-5 px-lg-4" ref={formRef}>
            <div className="row row-gap-4">
                <div className="col-xl-8 col-lg-10 col-12 mx-auto">

                    <div className="common-box" >


                        <div className="common-box-heading cbh-big mb-2">Application for the Introducing Broker status</div>
                        <div className="common-box-sub-heading">Make sure your details are correct—after applying, we will reach you to discuss your experience. We will also answer all the questions you might have.</div>
                        
                        {/* Q.1 */}
                        {/* <div className="d-flex flex-column flex-wrap row-gap-4 row-gap-2 mt-3">
                            <CheckInput 
                                className="mb-0 sgc-check" 
                                name="AgreementCheck" 
                                id="AgreementCheck" 
                                label="I have read and agree with the"
                                linkBr
                                linkText="IB Agreement."
                                linkURL=" "
                                value={agree}
                                onChange={handleAgreeChange}
                                checked={agree ? true : false}
                            />
                            {errors.agree && <span style={{width: "100%", marginTop: "-17px", textAlign: "left"}} className="form-error-msg">{errors.agree}</span>}
                        </div> */}
                        {/* Q.1 */}


                        <div className="my-3 pt-1"></div> 


                        {/* Q.2 */}
                        <div className="common-box-heading mb-2">Have you had any experience as an IB?</div>
                        <div className={`d-flex flex-wrap column-gap-3 row-gap-2 mt-2 ${errors.is_expereience ? "error-group" : ""}`}>
                            <RadioInput 
                                className="mb-1 sgc-radio" 
                                name="experience" 
                                id="experienceYes" 
                                label="Yes"
                                value="yes"
                                checked={isExperienced === "yes"}  
                                onChange={handleExperiencedChange}
                            />
                            <RadioInput 
                                className="mb-1 sgc-radio" 
                                name="experience" 
                                id="experienceno" 
                                label="No"
                                value="no"
                                checked={isExperienced === "no"}  
                                onChange={handleExperiencedChange} 
                            />
                            {errors.is_expereience && <span style={{width: "100%", marginTop: "-5px", textAlign: "left"}} className="form-error-msg">{errors.is_expereience}</span>}
                        </div>
                        {/* Q.2 */}


                        <div className="my-3 pt-1"></div> 


                        {/* Q.3 */}
                        <div className="common-box-heading mb-2">Which brokers have you previously worked with?</div>
                        <div className="row signinup-group-style sg-fem-style mt-2" style={{maxWidth:"500px"}}>
                            <div className="col-12">
                                <Input className="mb-0" type="text" placeholder="Enter broker name" value={previousBroker} onChange={handleBrokerChange} />
                                {/* <span className="form-error-msg mt-2">Please enter broker name</span> */}
                            </div>
                        </div>
                        {/* Q.3 */}


                        <div className="my-3 pt-1"></div> 


                        {/* Q.4 */}
                        <div className="common-box-heading mb-2">What platform will you use to attract new clients?</div>
                        <div className="d-flex flex-column flex-wrap row-gap-3 row-gap-2 mt-2">
                            <CheckInput 
                                className="mb-0 sgc-check" 
                                name="attract1" 
                                id="attract1" 
                                label="Own website or traffic"
                                value="website"
                                checked={platforms.includes('website')}
                                onChange={handlePlatformChange}
                            />
                            <CheckInput 
                                className="mb-0 sgc-check" 
                                name="attract2" 
                                id="attract2" 
                                label="Social media (Facebook, Instagram, and other)"
                                value="social_media"
                                checked={platforms.includes('social_media')}
                                onChange={handlePlatformChange}
                            />
                            <CheckInput 
                                className="mb-0 sgc-check" 
                                name="attract3" 
                                id="attract3" 
                                label="Messengers (WhatsApp, Telegram, and other)"
                                value="messangers"
                                checked={platforms.includes('messangers')}
                                onChange={handlePlatformChange}
                            />
                            <CheckInput 
                                className="mb-0 sgc-check" 
                                name="attract4" 
                                id="attract4" 
                                label="Forums"
                                value="forumns"
                                checked={platforms.includes('forumns')}
                                onChange={handlePlatformChange}
                            />
                            <CheckInput 
                                className="mb-0 sgc-check" 
                                name="attract5" 
                                id="attract5" 
                                label="Word of mouth"
                                value="word_of_mouth"
                                checked={platforms.includes('word_of_mouth')}
                                onChange={handlePlatformChange}
                            />
                            <CheckInput 
                                className="mb-0 sgc-check" 
                                name="attract6" 
                                id="attract6" 
                                label="Own course or seminars"
                                value="seminars"
                                checked={platforms.includes('seminars')}
                                onChange={handlePlatformChange}
                            />
                            <CheckInput 
                                className="mb-0 sgc-check" 
                                name="attract7" 
                                id="attract7" 
                                label="Other"
                                value="others"
                                checked={platforms.includes('others')}
                                onChange={handlePlatformChange}
                            />
                        </div>
                        <div className="row signinup-group-style sg-fem-style mt-3" style={{maxWidth:"500px"}}>
                        {platforms.includes('website') && (
                            <div className="col-12">
                                <Input className={errors.website_link ? "error-group" : ""} name="website" type="text" placeholder="Share a link to your website" onChange={handleWebsiteLinkChange} />
                                {errors.website_link && <span className="form-error-msg">{errors.website_link}</span>}
                            </div>
                        )}
                        {platforms.includes('social_media') && (
                            <div className="col-12">
                                <Input className={errors.social_link ? "error-group" : ""} name="social_media" type="text" placeholder="Specify social media links, comma separated" onChange={handleSocialLinkChange} />
                                {errors.social_link && <span className="form-error-msg">{errors.social_link}</span>}
                            </div>
                        )}
                        {platforms.includes('messangers') && (
                            <div className="col-12">
                                <Input className={errors.messanger_link ? "error-group" : ""} name="messangers" type="text" placeholder="Specify links to messengers, comma separated" onChange={handleMessangerLinkChange} />
                                {errors.messanger_link && <span className="form-error-msg">{errors.messanger_link}</span>}
                            </div>
                        )}
                         {platforms.includes('forumns') && (
                            <div className="col-12">
                                <Textarea className={errors.forumn_link ? "error-group" : ""} name="forumns" placeholder="Name the forums" onChange={handleForumnLinkChange}/>
                                {errors.forumn_link && <span className="form-error-msg">{errors.forumn_link}</span>}
                            </div>
                        )}
                         {platforms.includes('others') && (
                        <div className="col-12">
                            <Input className={`mb-0 ${errors.other_link ? "error-group" : ""}`} name="others" type="text" placeholder="Specify your ways to attract clients" onChange={handleOtherLinkChange} />
                            {errors.other_link && <span className="form-error-msg mt-2">{errors.other_link}</span>}
                        </div>
                         )}
                        </div>
                        {/* Q.4 */}


                        <div className="my-3 pt-1"></div> 


                        {/* Q.5 */}
                        <div className="common-box-heading mb-2">In general, what countries do your clients come from?</div>
                        <div className="row" style={{maxWidth:"500px"}}>
                            <div className="col-12">
                                <SelectDropItem
                                    className={`mb-0 ${errors.country ? "error-group" : "" }`}
                                    heading="Select a country"
                                    options={countries}
                                    onChange={handleCountryChange}
                                />
                                {errors.country && <span className="form-error-msg">{errors.country}</span>}
                            </div>
                        </div>
                        {/* Q.5 */}



                        <div className="my-3 pt-1"></div> 



                        {/* Q.6 */}
                        <div className="common-box-heading mb-2">How many clients are you ready to refer within the next 3 months?</div>
                        <div className="row signinup-group-style sg-fem-style mt-2" style={{maxWidth:"500px"}}>
                            <div className="col-12">
                                <Input className={`mb-0 ${errors.no_of_clients ? "error-group" : "" }`} type="number" placeholder=" " onChange={handleNumberOfClientChange} />
                                {errors.no_of_clients && <span className="form-error-msg mt-2">{errors.no_of_clients}</span>}
                            </div>
                        </div>
                        {/* Q.6 */}


                        <div className="my-3 pt-1"></div> 


                        {/* Q.7 */}
                        <div className="common-box-heading mb-2">What kind of additional services are you going to provide to your clients?</div>
                        <div className="d-flex flex-column flex-wrap row-gap-3 row-gap-2 mt-2">
                            <CheckInput 
                                className="mb-0 sgc-check" 
                                name="services1" 
                                id="services1" 
                                label="Trading education"
                                value="trading_education"
                                checked={additionalServices.includes('trading_education')}
                                onChange={handleServicesChange}
                            />
                            <CheckInput 
                                className="mb-0 sgc-check" 
                                name="services2" 
                                id="services2" 
                                label="Signals"
                                value="signals"
                                checked={additionalServices.includes('signals')}
                                onChange={handleServicesChange}
                            />
                            <CheckInput 
                                className="mb-0 sgc-check" 
                                name="services3" 
                                id="services3" 
                                label="EA"
                                value="ea"
                                checked={additionalServices.includes('ea')}
                                onChange={handleServicesChange}
                            />
                            <CheckInput 
                                className="mb-0 sgc-check" 
                                name="services4" 
                                id="services4" 
                                label="Rebate"
                                value="rebate"
                                checked={additionalServices.includes('rebate')}
                                onChange={handleServicesChange}
                            />
                            <CheckInput 
                                className="mb-0 sgc-check" 
                                name="services5" 
                                id="services5" 
                                label="Account/fund management"
                                value="account"
                                checked={additionalServices.includes('account')}
                                onChange={handleServicesChange}
                            />
                            <CheckInput 
                                className="mb-0 sgc-check" 
                                name="services6" 
                                id="services6" 
                                label="Others"
                                value="others"
                                checked={additionalServices.includes('others')}
                                onChange={handleServicesChange}
                            />
                            <CheckInput 
                                className="mb-0 sgc-check" 
                                name="services7" 
                                id="services7" 
                                label="I do not provide any"
                                value="not_provide"
                                checked={additionalServices.includes('not_provide')}
                                onChange={handleServicesChange}
                            />
                        </div>
                        {additionalServices.includes('others') && (
                        <div className="row signinup-group-style sg-fem-style mt-3" style={{maxWidth:"500px"}}>
                            <div className="col-12">
                                <Input className="mb-0" heading="Other" type="text"  placeholder="Text here..." onChange={handleAdditionalOther} />
                                {/* <span className="form-error-msg mt-2">Please enter service</span> */}
                            </div>
                        </div>
                        )}
                        {/* Q.7 */}


                        <div className="my-3 pt-1"></div> 


                        {/* Q.8 */}
                        <div className="common-box-heading mb-2">What language do you prefer?</div>
                        <div className="row mt-2" style={{maxWidth:"500px"}}>
                            <div className="col-12">
                                <SelectDropItem
                                    className={`mb-0 ${errors.language ? "error-group" : "" }`} 
                                    heading="Select a language"
                                    options={LanguageOptions}
                                    value="not_provide"
                                    checked={language}
                                    onChange={handleLanguageChange}
                                />
                                 {errors.language && <span className="form-error-msg">{errors.language}</span>}
                            </div>
                        </div>
                        {/* Q.8 */}


                        <div className="my-3 pt-1"></div> 


                        {/* Q.9 */}
                        <div className="common-box-heading mb-2">How should we get in touch with you?</div>
                        <div className="row signinup-group-style sg-fem-style mt-2" style={{maxWidth:"500px"}}>
                            <div className="col-12">
                                <Input className={errors.email ? "error-group" : ""} type="email" placeholder="Enter your email Id" onChange={handleEmailChange} />
                                {errors.email && <span className="form-error-msg">{errors.email}</span>}
                            </div>

                            <div className="col-12">
                                <Input className={errors.phone ? "error-group" : ""} type="number" placeholder="Enter your phone number" onChange={handlePhoneChange} />
                                {errors.phone && <span className="form-error-msg">{errors.phone}</span>}
                            </div>
                        </div>
                        {/* Q.9 */}
                        

                    </div>
                    
                    <div className="mt-4">
                    {!isLoading && <div className="common-submit-btn" onClick={handleSubmit}>Become a Partner</div>
                    }

                    {isLoading && <div className="lsb-loader-btn">
                    <img src={Img.loading} alt="" />Please wait ....
                    </div>
                    }
                    {requestSubmitSuccess && <FlashMessage type="success" isvisible={true} message="Ib request submitted successfully" />}
                    {requestSubmitError && <FlashMessage type="error" isvisible={true} message={requestSubmitErrorMsg} />}
                    </div>
                </div>

            </div>
        </div>
      
    </>
  )
}
