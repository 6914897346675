import React from 'react'
import { NavLink } from 'react-router-dom';

// Design Files
import Breadcrumb from '../../components/Breadcrumb';

export default function AnalyticsLayout({children}) {
  return (
    <>
        <Breadcrumb breadcrumbIcon="AnalyticsSvg" breadcrumbHeading="Analytics" />

        <div className="container-lg pt-3 pb-5 px-lg-4">
            <div className="row row-gap-4">

                {/* --- Tab btn start --- */}
                <div className="col-12">
                  <div className="cb-p-bg-none">
                    <div className="common-tabs-bx">
                        <NavLink to="/analytics-summary" className={`ctb-item`}>Summary</NavLink>
                        <NavLink to="/order-history" className={`ctb-item`}>History of order</NavLink>
                        {/* <Link to="/analytics-summary" className={`ctb-item`}>Winprofx benefits</Link> */}
                    </div>
                  </div>
                </div>
                {/* --- Tab btn end --- */}

                {/* Analytics page content start */}
                {children}
                {/* Analytics page content End */}

            </div>
        </div>
    </>
  )
}
