import React from 'react';

// Design File
// import CommonLayout from '../PageCommonLayout/CommonLayout';
import Transfer from './Transfer';


export default function PageTransfer() {

  return (
    <>
      <Transfer />
    </>
  )
}
