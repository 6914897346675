import React from 'react'

export default function RadioInput({ className, name, id, label, labelNote, value, checked=false, onChange }) {

  return (
    <div className={`signinup-group-checkmark ${className}`}>
      <input className="d-none" type="radio" name={name} id={id} checked={checked}  onChange={onChange} value={value}/>
      <label className="checkmark-label" htmlFor={id}>
        <span className="checkmark-icon">
            <div className="cmi-inner"></div>
        </span>
        {label}
        {( labelNote && <div className="input-note">{labelNote}</div>)} 
      </label>
    </div>
  )
}
