import React, { useState } from 'react';
import {Link} from "react-router-dom";
import { Helmet } from "react-helmet-async";

// Select Contry
import ReactFlagsSelect from "react-flags-select";

// Design Files
import PageHeader from './PageHeader';
import PageBackBtn from './PageBackBtn';

// Images Common File
// import * as Img from '../../components/Img';


export default function PageSignUpStep2() {

  const [selected, setSelected] = useState("");

  return (
    <>
      <Helmet>
        <title>Register | Winpro Fx</title>
      </Helmet>

      <PageHeader />
      <PageBackBtn to="/register"/>

      <section className="lgh-wraper">
        <div className="container-lg cl-custome">
          <div className="signinup-screen">
            
            <div className="signinup-header">
              <div className="screen-hed">Select country & city</div>
            </div>

            <form className="signinup-width">
              <div className="row">

                <div className="col-12">
                  {/* error-group */}
                  <div className="signinup-group sg-country-select">
                       <ReactFlagsSelect
                          selectedSize={14}
                          className="menu-flags"
                          selectButtonClassName={`menu-flags-button ${selected ? "active" : " "}`}
                          searchable
                          searchPlaceholder="Search countries"
                          selected={selected}
                          onSelect={(code) => setSelected(code)}
                        />
                      {/* <span className="form-error-msg">You must provide detail</span> */}
                  </div>
                </div>

                <div className="col-12">
                  {/* error-group */}
                  <div className="signinup-group">
                      <input type="text" placeholder="Enter City" />
                      {/* <div className="group__label">Enter City</div> */}
                      {/* <span className="form-error-msg">You must provide detail</span> */}
                  </div>
                </div>

                <div className="col-12">
                  <Link to="/password" className="common-submit-btn">Continue</Link>
                  {/* <button type="button" className="common-submit-btn">Continue</button> */}

                  {/* <div className="lsb-loader-btn">
                      <img src={Img.loading} alt="" />Please wait ....
                  </div> */}
                </div>

                <div className='col-12 BackBtnIsShow'>
                  <div className="signinup-link-outer-bx slob-fdc fdc-two mb-0">
                    Back to 
                    <Link to="/register" className="signinup-link-two">Previous</Link>
                  </div>
                </div>

              </div>

            </form>

          </div>  
        </div>
      </section>     
      
    </>
  );
};
