import React from 'react'
import { Link } from 'react-router-dom'
import Icons from '../components/icons'
import support from "../img/icons/support.png"

export default function RelationshipManager({data=null}) {
  return (
    <div className="common-box">
      <div className="common-box-heading">Relationship manager</div>
      <div className="relationship-manager-bx">
        <div className="rmb-data-bx">
          <div className="rmb-d-name">
            {/* <Icons.UserSvg /> */}
            <img src={support} alt="" />
            {data ? data.name : ''}
          </div>
        </div>
        <div className="d-flex flex-wrap gap-2">
          <Link className="common-btn-item cbi-fill" to={data ? `tel:+${data.phone_prefix}${data.contact_number}` : '#'} ><span><Icons.DevicePhoneMobileSvg/>{data ? '+'+data.phone_prefix+' '+data.contact_number:''}</span></Link>
          <Link 
            className="common-btn-item cbi-fill cbi-whatsapp" 
            to={data ? `https://api.whatsapp.com/send?phone=${data.wp_phone_prefix}${data.wp_number}&text=Hello` : '#'} 
            target="_blank"
          >
            <span>
              <Icons.WhatsappSvg />
              {data ? `+${data.wp_phone_prefix} ${data.wp_number}` : ''}
            </span>
          </Link>
        </div>
      </div>
    </div>
  )
}
