import React, { useState, useEffect, useRef } from 'react';

// Design File
// import CommonLayout from '../PageCommonLayout/CommonLayout';
import DocumentVerification from './DocumentVerification';
import Breadcrumb from '../../components/Breadcrumb';
import VerificationSteps from './VerificationSteps';
import { useLocation } from 'react-router-dom';
import FlashMessage from '../../components/FlashMessage'; 

export default function PageVerification() {
  const location = useLocation();
  const [showSuccessMsg, setShowSuccessMsg] = useState(location.state?.showSuccessMsg || false);
  const [showErrorMsg, setShowErrorMsg] = useState(location.state?.showErrorMsg || false);
  const [showVerificationMsg, setSowVerificationMsg] = useState(location.state?.verificationErrorMsg || false);
  useEffect(() => {
    if (showSuccessMsg || showErrorMsg || showVerificationMsg) {
      setTimeout(() => {
        window.history.replaceState({}, '')
      }, 5000);
    }
  }, []);
  return (
    <>

    <Breadcrumb breadcrumbIcon="VerificationSvg" breadcrumbHeading="Verification" />

    <div className="container-lg pt-3 pb-5 px-lg-4">
      <div className="row row-gap-4">
        
          <VerificationSteps />

          <div className="col-12">
            <DocumentVerification />
          </div>
          {showSuccessMsg && <FlashMessage type="success" isvisible={true} message="Request sent Successfully" />}
          {showErrorMsg && <FlashMessage type="error" isvisible={true} message="Request is already submitted" />}
          {showVerificationMsg && <FlashMessage type="error" isvisible={true} message="Please Verify Your Profile" />}
      </div>
    </div>
    </>
  )
}
