import React from 'react'

// Icon Files
// import Icons from '../components/icons';
// import TransactionDetailModal from './TransactionDetailModal';
 
//export default function TransactionItem() {
  const getSymbolImage = (symbolImages) => {
    // if (symbolImages.length == 2) {
    //     try {
    //       return (
    //         <div className="symbol-icons">
    //           <img src={symbolImages[0]} alt="" />
    //           <img src={symbolImages[1]} alt="" />
    //         </div>
    //       );
        
    //     } catch (error) {
    //       return ''; // Return an empty string if the image is not found
    //     }
      
    // } else if(symbolImages.length == 1){
    //   try {
    //     return (
    //       <div className="symbol-icons si-single">
    //         <img src={symbolImages[0]} alt="" />
    //       </div>
    //     );
    //     } catch (error) {
    //     return ''; // Return an empty string if the image is not found
    //     }
    // } else {
      return '';
    // }
  };
  const HistoryItem = ({ key, id , symbolImages, symbol, side,open_date,close_date,take_profit,stop_loss, lots, price,opentime,open_price,close_price,profit, onClick }) => (
    <>
    <tr onClick={onClick} key={key}>
        <td>{id}</td>
        <td><div className="symbol-bx">
                  {getSymbolImage(symbolImages)}
                  {symbol}
                </div></td>
        <td className={`${
                            side === "buy" ? "td-green" : "td-red"
                          }`}>{side}</td>
        <td>{open_date ? open_date : "-"}</td>
        <td>{close_date ? close_date : "-"}</td>
        <td>{lots}</td>
        <td>{open_price ? open_price.toFixed(5) : "-"}</td>
        <td>{close_price ? close_price.toFixed(5) : "-"}</td>
        <td>{take_profit ? take_profit.toFixed(5) : "-"}</td>
        <td>{stop_loss ? stop_loss.toFixed(5) : "-"}</td>
        <td className={`${
                            profit > 0 ? "td-green" : "td-red"
                          }`}>{profit != '-' ? profit : "-"}</td>
    </tr>
    
    {/* <div className="col-12 d-none">
      <div className="transaction-item" onClick={onClick}>
        <div className="ti-inner ti-i-pl">
          <div className={`ti-i-icon ${statusColor}`}>{icon}</div>
          <div className="ti-i-info">{date}</div>
          <div className="ti-i-data">{id}</div>
        </div>
        <div className="ti-inner">
          <div className="ti-i-info">Type</div>
          <div className="ti-i-data">{transfer_type}</div>
        </div>
        <div className="ti-inner">
          <div className="ti-i-info">Account</div>
          <div className="ti-i-data">{account}</div>
        </div>
        <div className="ti-inner">
          <div className="ti-i-info">Payment Method</div>
          <div className="ti-i-data">{method}</div>
        </div>
        <div className="ti-inner">
          <div className="ti-i-info">Amount</div>
          <div className="ti-i-data tit-color2">{amount}</div>
        </div>
       
        <div className="ti-inner">
          <div className="ti-i-info">Status</div>
          <div className={`ti-i-data ${statusColor}`}>{status}</div>
        </div>
      </div>
    </div> */}
    </>
  );
  
  export default HistoryItem;
