import React from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import EmptyBox from "../../components/EmptyBox";

const ReportChart = ({ dataset, isLoading, type,trade }) => {
    // Convert the object data to an array format with month names
   
    
    const formatDataset = (data, type,trade) => {
        if (type === 'chart') {
            if (data && data.monthly_deposits && typeof data.monthly_deposits === 'object') {
                return Object.entries(data.monthly_deposits).map(([month, amount]) => {
                    const [year, monthNumber] = month.split('-').map(Number);
                    const date = new Date(year, monthNumber - 1);
                    const monthName = date.toLocaleString('default', { month: 'short' });
                    const yearLabel = date.toLocaleString('default', { year: 'numeric' });

                    return {
                        month: `${monthName}`,
                        ReportData: amount !== null ? amount : 0,
                    };
                });
            }
        } else if (type === 'report') {
            if (data && data.monthly_registrations && typeof data.monthly_registrations === 'object') {
                return Object.entries(data.monthly_registrations).map(([month, count]) => {
                    const [year, monthNumber] = month.split('-').map(Number);
                    const date = new Date(year, monthNumber - 1);
                    const monthName = date.toLocaleString('default', { month: 'short' });
                    const yearLabel = date.toLocaleString('default', { year: 'numeric' });

                    return {
                        month: `${monthName}`,
                        ReportData: count !== null ? count : 0,
                    };
                });
            }
        } else if (trade !== "" && trade === 'client') {
            if (data && data.monthly_active_clients && typeof data.monthly_active_clients === 'object') {
                return Object.entries(data.monthly_active_clients).map(([month, count]) => {
                    const [year, monthNumber] = month.split('-').map(Number);
                    const date = new Date(year, monthNumber - 1);
                    const monthName = date.toLocaleString('default', { month: 'short' });
                    const yearLabel = date.toLocaleString('default', { year: 'numeric' });

                    return {
                        month: `${monthName}`,
                        ReportData: count.active_clients !== null ? count.active_clients : 0,
                    };
                });
            }
        }
        else if (trade !== "" && trade === 'lots') {
            if (data && data.monthly_active_clients && typeof data.monthly_active_clients === 'object') {
                return Object.entries(data.monthly_active_clients).map(([month, count]) => {
                    const [year, monthNumber] = month.split('-').map(Number);
                    const date = new Date(year, monthNumber - 1);
                    const monthName = date.toLocaleString('default', { month: 'short' });
                    const yearLabel = date.toLocaleString('default', { year: 'numeric' });

                    return {
                        month: `${monthName}`,
                        ReportData: count.trade_volume !== null ? count.trade_volume : 0,
                    };
                });
            }
        }
        else if (trade !== "" && trade === 'reward') {
            if (data && data.monthly_active_clients && typeof data.monthly_active_clients === 'object') {
                return Object.entries(data.monthly_active_clients).map(([month, count]) => {
                    const [year, monthNumber] = month.split('-').map(Number);
                    const date = new Date(year, monthNumber - 1);
                    const monthName = date.toLocaleString('default', { month: 'short' });
                    const yearLabel = date.toLocaleString('default', { year: 'numeric' });

                    return {
                        month: `${monthName}`,
                        ReportData: count.ib_reward !== null ? count.ib_reward : 0,
                    };
                });
            }
        }

        return [];
    };

    const formattedData = formatDataset(dataset, type,trade);

    const valueFormatter = (value) => {
        if (value >= 1000) {
            return `${value / 1000}k`; // Format as 1k, 2k, etc.
        } else {
            return `${value}`; // Default format for values below 1000
        }
    };

    const tooltipFormatter = (data) => {
        return [
            { name: 'Month', value: data.month },
            { name: type === 'chart' ? 'Deposit Amount' : 'Registrations', value: valueFormatter(data.ReportData) },
        ];
    };

    const chartSetting = {
        height: 370,
        series: [{
            dataKey: 'ReportData',
            color: '#0294B5',
            valueFormatter,
        }],
        xAxis: [{
            scaleType: 'band',
            dataKey: 'month'
        }],
        yAxis: [{
            valueFormatter,
        }],
        tooltip: {
            formatter: tooltipFormatter,
        },
        sx: {
            [`& .${axisClasses.directionX} .${axisClasses.label}`]: {
                transform: 'translateY(0px)',
            },
        },
        grid: {
            vertical: {
                strokeDasharray: '1',
            },
            horizontal: {
                strokeDasharray: '1',
            },
        },
    };

    return (
        <div className='Mycharts MC-style-2' style={{ marginTop: '-40px' }}>
            {isLoading ? (
                <div className="col-12 mx-auto">
                    {/* <div className="skeletant-bx skeletant-design sh-245"></div> */}
                </div>    
            ) : formattedData.length > 0 ? (
                <LineChart
                    dataset={formattedData}
                    {...chartSetting}
                />
            ) : (
                <EmptyBox
                className="empty-div-bx"
                eh="No data found"
                esh="Try adjusting filters to get results."
              />
            )}
        </div>
    );
};

export default ReportChart;
