import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useAuth, getLoggedInUserData, getToken } from '../../context/AuthContext';
import useApi from '../../utility/apiCall';
import API_URL from '../../config/config';

// Icon Files
import Icons from '../../components/icons';

// Design Fils
import TransactionSort from '../TransactionSort';


const WalletDetail = ({ wallet_id }) => {
    const { apiCall } = useApi();
    const [user, setUser] = useState('')
    const { state } = useAuth();
    const token = state.token || '';
    const { dispatch } = useAuth();
    const [wallets, setWallets] = useState([]);
    const [currentWallet, setCurrentWallet] = useState(null);
    const [currentWalletId, setCurrentWalletId] = useState(wallet_id);
    const [transactions, setTransactions] = useState([]);
    const isInitialMount = useRef(true);

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
            const loggedInUser = getLoggedInUserData();
            const isUserLoggedIn = !!loggedInUser;
            if (isUserLoggedIn) {
                setUser(loggedInUser)
                if (loggedInUser.id && token) {
                    fetchWallets();
                }
            }
        }
    }, [state.userData]);

    useEffect(()=>{
        setCurrentWallet(wallets.find(wallet => wallet.id === wallet_id));
    },[wallets])

    useEffect(()=>{
        setCurrentWallet(wallets.find(wallet => wallet.id === currentWalletId));
    },[currentWalletId])

    useEffect(()=>{
        featchWalletDetail();
    },[currentWallet])
  
    const fetchWallets = async () => {
      try {
          const response = await apiCall(API_URL +'get-user-wallets', {
              user_id: state.userData.id,
              token: token,
          });
          if (response.data.success == '0') {
            setWallets(response.data.data)
          }
      } catch (error) {
          console.error('Error fetching data:', error);
      }
    };

    const featchWalletDetail = async () => {
        try {
            const response = await apiCall(API_URL +'get-wallet-details', {
                user_id: state.userData.id,
                token: token,
                wallet_id:currentWalletId
            });
            if (response.data.success == '0') {
                setTransactions(response.data.data.transactions);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
      };

    // --- ---
    const [isWalletDropActive, setIsWalletDropActive] = useState(false);

    const handleWalletEnter = () => {
        setIsWalletDropActive(true);
    };

    const handleWalletLeave = () => {
        setIsWalletDropActive(false);
    };

    const handleWalletOpen = () => {
        setIsWalletDropActive(true);
    }

    const handleWalletClose = () => {
        setIsWalletDropActive(false);
    };

    const changeWallet = (change_wallet_id) => {
        setCurrentWalletId(change_wallet_id);
        setIsWalletDropActive(false);
    }
    // --- ---

  return (
    <>
    <div className="col-xl-8 col-lg-7">
        <div className="mb-4">
            <div className={`wallet-drop-item ${isWalletDropActive ? 'active' : ''}`} onMouseLeave={handleWalletLeave}>

            {currentWallet && 
            <div className="wallet-current-bx" onClick={handleWalletOpen} onMouseEnter={handleWalletEnter}>
                <img className="wcb-img" src={currentWallet.wallet.image_url} alt={currentWallet.wallet.name} />
                <div className="wcb-data-bx">
                <div className="wcb-d-heading">{currentWallet.wallet.name}</div>
                <div className="wcb-d-id" title="Copy ID">ID : {currentWallet.id} <Icons.CopySvg /></div>
                </div>
                <div className="wcb-down-icon"><Icons.DownSvg /></div>
            </div>
            }

            <div className={`wallet-drop-bx ${isWalletDropActive ? 'active' : ''}`}>
                <div className="wallet-drop-inner">
                {wallets.length > 0 &&
                wallets.map((wallet, index) => (
                    <div className="wallet-list-item" onClick={() =>changeWallet(wallet.id)}>
                        <img className="wli-img" src={wallet.wallet.image_url} alt={wallet.wallet.name} />
                        <div className="wli-data-bx">
                            <div className="wli-d-heading">{wallet.wallet.name}</div>
                            <div className="wli-d-id">ID : {wallet.id}</div>
                        </div>
                    </div>
                ))};
                    {/* <div className="wallet-list-item" onClick={handleWalletClose}>
                        <img className="wli-img" src={trade7} alt="" />
                        <div className="wli-data-bx">
                            <div className="wli-d-heading">USD</div>
                            <div className="wli-d-id">ID : 12645</div>
                        </div>
                    </div>

                    <div className="wallet-list-item" onClick={handleWalletClose}>
                        <img className="wli-img" src={trade8} alt="" />
                        <div className="wli-data-bx">
                            <div className="wli-d-heading">Tether</div>
                            <div className="wli-d-id">ID : 12645</div>
                        </div>
                    </div> */}

                </div>
            </div>

            </div>

            <div className="wallet-current-btns mt-4 d-flex flex-wrap gap-2">
            <Link to="/deposit" className="common-btn-item cbi-fill"><span><Icons.DepositSvg/>Deposit</span></Link>
            <Link to="/withdraw" className="common-btn-item cbi-fill"><span><Icons.WithdrawSvg/>Withdraw</span></Link>
            <Link to="/transfer" className="common-btn-item cbi-fill"><span><Icons.TransactionsSvg/>Transfer</span></Link>
            {/* <Link to="" className="common-btn-item cbi-fill"><span><Icons.TransactionsSvg/>Internal Transfer</span></Link> */}
            </div>
        </div>

        <div className="common-box">
            <div className="common-box-heading"><Icons.EwalletsSvg/>Wallet Overview</div>
            <div className="row row-gap-3">
            <div className="col-xl-4 col-lg-12 col-md-4 col-sm-6">
                <div className="wallet-overview-bx">
                    <div className="wob-lable">Balance</div>
                    <div className="wob-data">{currentWallet?.formatted_balance}</div>
                </div>
            </div>
            <div className="col-xl-4 col-lg-12 col-md-4 col-sm-6">
                <div className="wallet-overview-bx">
                    <div className="wob-lable">Available</div>
                    <div className="wob-data">{currentWallet?.formatted_available_balance}</div>
                </div>
            </div>
            <div className="col-xl-4 col-lg-12 col-md-4 col-sm-6">
                <div className="wallet-overview-bx">
                    <div className="wob-lable">Hold</div>
                    <div className="wob-data">{currentWallet?.formatted_on_hold_balance}</div>
                </div>
            </div>
            </div>
        </div>
    </div>
    <div className="col-xl-4 col-lg-5">
        {transactions && 
        <TransactionSort className="common-box-sticky" heading="Transactions" transactions={transactions} />
        }
    </div>
    </>
  )
}

export default WalletDetail;
